import { ErrorHandler, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';

import { NgxUiLoaderModule, NgxUiLoaderHttpModule } from 'ngx-ui-loader';

import { AppComponent } from './app.component';

import { CoreModule } from './core/core.module';
import { LayoutModule } from './layout/layout.module';
import { RoutesModule } from './routes/routes.module';
import { AppConfig } from './shared/app.config';
import { AppConstants } from './shared/app.constant';
import { SharedModule } from './shared/shared.module';
import { HttpConfigInterceptor } from './core/services/httpconfig.interceptor';

import * as $ from 'jquery'
import { GlobalErrorHandler } from './core/services/global.error.handler';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';



@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule.withServerTransition({ appId: "evabot" }),
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    GooglePlaceModule,
    SharedModule.forRoot(),
    LayoutModule,
    RoutesModule,
    NgxUiLoaderModule,
    NgxUiLoaderHttpModule.forRoot({
      showForeground: true,
      exclude: [AppConfig.HOST + 'cart/updateCartAddress', AppConfig.HOST + 'cart/updateHandwrittenNote',AppConfig.HOST + 'address/getAddressByEmail', AppConfig.HOST+'campaign/searchUser']
    })
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },{provide:ErrorHandler, useClass:GlobalErrorHandler}],
  bootstrap: [AppComponent]
})
export class AppModule { }

 declare module "@angular/core" {
   interface ModuleWithProviders<T = any> {
     ngModule: Type<T>;
     providers?: Provider[];
   }
}
