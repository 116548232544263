import { NgModule } from '@angular/core';
import { HeaderComponent } from './header/header.component';
import { SharedModule } from '../shared/shared.module';
import { MenuComponent } from './menu/menu.component';
import { CoreModule } from '../core/core.module';
import { ToastrModule } from 'ngx-toastr';
import { LayoutComponent } from './layout.component';

@NgModule(
    {
        imports: [
            SharedModule,
            CoreModule,
            ToastrModule.forRoot({
                timeOut: 5000,
                positionClass: "toast-top-center",
                preventDuplicates: true,
                
            }),

        ],
        declarations: [
            HeaderComponent,
            MenuComponent,
            LayoutComponent
        ],
        exports: [
            HeaderComponent,
            MenuComponent,
            LayoutComponent
        ]
    }
)
export class LayoutModule { }