import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { ApiService } from "./api.service";
declare var fbq: any;
declare var gtag: any;
@Injectable()
export class GiftService {
  constructor(private apiService: ApiService) {

  }

  validateRefreshToken(object: any): Observable<any> {
    return this.apiService.post("user/validateRefreshToken", object);
  }

  sendGiftViaEmail(object: any): Observable<any> {
    return this.apiService.post("gift/sendGmailGift", object).pipe(
      map((data:any) => {
        fbq("track", "Purchase", {
          value: object.giftValue,
          currency: "usd",
        });
        gtag('event', 'conversion', {
          'send_to': 'AW-822217819/i9uPCIjygqIBENuYiIgD',
          'transaction_id': ''
        });
        const hashId = data.hashId;
        return hashId;
      })
    );
  }


  getGeneratedLinkList(): Observable<any> {
    return this.apiService.post("gift/getGeneratedLinkList",{}).pipe(
      map((data:any) => {
        const links = data.links;
        return links;
      })
    );
  }

  generateLink(object: any): Observable<any> {
    return this.apiService.post("gift/generateLink", object).pipe(
      map(data => {
        fbq("track", "Purchase", {
          value: object.giftValue,
          currency: "usd",
        });
        gtag('event', 'conversion', {
          'send_to': 'AW-822217819/i9uPCIjygqIBENuYiIgD',
          'transaction_id': ''
        });
        
        return data;
      })
    );
  }

  sendAnotherGift(object: any): Observable<any> {
    return this.apiService.post("gift/sendAnotherGift", object);
  }

  sendDemoGift(object: any): Observable<any> {
    return this.apiService.post("gift/sendDemoGift", object);
  }

  setReminderStatus(object: any): Observable<any> {
    return this.apiService.post("order/updateReminderStatus", object);
  }

}
