<div class="wrapper">
  <div class="register-content">
    <div class="register-panel">
      <div class="register-text">
        <div class="register-text-inner">
          <div>
            <img
              width="90"
              src="../../../../assets/img/logo/eva-new-logo.png"
            />
          </div>
          <div class="p-3"></div>
          <div
            style="
              letter-spacing: -0.4px;
              color: #302f2f;
              font-size: 24px;
              font-family: 'Poppins Light';
            "
          >
            Say bye to the 'One Size Fits All' approach
          </div>
          <div
            style="
              color: #302f2f;
              font-size: 40px;
              font-family: 'MuseoSlab-300';
            "
          >
            Say Hi to <span style="color: #dd015b">3x more reviews!</span>
          </div>
          <div class="p-3"></div>
          <div>
            <img
              style="width: 100%"
              src="../../../../assets/img/signup/register.gif"
            />
          </div>
          <div class="p-3"></div>
          <div style="font-size: 16px">
            Curious?
            <span style="color: #dd015b"
              >Sign up for free & get $40 off your first gift.</span
            >
            <br />
            7 days free trial. Cancel Anytime.
          </div>
          <div class="p-5"></div>
          <div>
            <span>&copy; 2024 - EvaBot Inc. All Rights Reserved.</span>
          </div>
        </div>
      </div>
      <div class="register-form">
        <div class="register-text-inner">
          <div class="start-text">Lets Get Started</div>
          <div class="p-3"></div>

          <form class="mb-3" (ngSubmit)="onSignUp(f)" #f="ngForm">
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="first-name">First Name</label>
                <input
                  id="first-name"
                  [(ngModel)]="userFirstName"
                  class="form-control"
                  type="text"
                  name="firstname"
                  ngModel
                  autocomplete="off"
                  #firstName="ngModel"
                  [ngClass]="{ 'input-error': isSubmitted && !firstName.valid }"
                  required
                />
              </div>
              <div class="form-group col-md-6">
                <label for="last-name">Last Name</label>
                <input
                  id="last-name"
                  [(ngModel)]="userLastName"
                  class="form-control"
                  type="text"
                  name="lastname"
                  ngModel
                  autocomplete="off"
                  #lastName="ngModel"
                />
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-md-12">
                <label for="user-email">Email</label>
                <input
                  id="user-email"
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,64}$"
                  [(ngModel)]="userEmail"
                  class="form-control"
                  type="email"
                  name="email"
                  ngModel
                  oninput="this.value = this.value.toLowerCase()"
                  autocomplete="off"
                  #email="ngModel"
                  [ngClass]="{ 'input-error': isSubmitted && !email.valid }"
                  required
                  email
                />
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-md-12">
                <label for="user-password">Password</label>
                <div class="input-group">
                  <input
                    id="user-password"
                    [type]="keyType"
                    pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-+=@$#^!%*?()&.<>{}[]|;:''?`~/])[A-Za-z\d-+=@$#^()!%*?&.<>{}[]|;:''?`~/]$"
                    class="form-control border-right-0"
                    ngModel
                    name="password"
                    #password="ngModel"
                    [ngClass]="{
                      'input-error': isSubmitted && password.invalid
                    }"
                    autocomplete="off"
                    required
                  />
                  <div
                    class="input-group-addon"
                    style="
                      line-height: 2.5;
                      border: 1px solid #ced4da;
                      border-left: 0;
                      width: 30px;
                      background: inherit;
                      cursor: pointer;
                    "
                    (click)="onShowPassword()"
                  >
                    <i
                      *ngIf="isShowPassword"
                      class="fa fa-eye"
                      aria-hidden="true"
                    ></i>
                    <i
                      *ngIf="!isShowPassword"
                      class="fa fa-eye-slash"
                      aria-hidden="true"
                    ></i>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group" *ngIf="isSubmitted && password.invalid">
              <div>
                <div>Password must contain the following:</div>
                <div
                  [ngClass]="{
                    'error-message': !lowercaseRegex.test(password.value),
                    'success-message': lowercaseRegex.test(password.value)
                  }"
                >
                  <span
                    *ngIf="lowercaseRegex.test(password.value)"
                    class="fa fa-check"
                  ></span
                  ><span
                    *ngIf="!lowercaseRegex.test(password.value)"
                    class="fa fa-times"
                  ></span
                  >&nbsp;A lowercase letter
                </div>
                <div
                  [ngClass]="{
                    'error-message': !uppercaseRegex.test(password.value),
                    'success-message': uppercaseRegex.test(password.value)
                  }"
                >
                  <span
                    *ngIf="uppercaseRegex.test(password.value)"
                    class="fa fa-check"
                  ></span
                  ><span
                    *ngIf="!uppercaseRegex.test(password.value)"
                    class="fa fa-times"
                  ></span
                  >&nbsp;A Capital (uppercase) letter
                </div>
                <div
                  [ngClass]="{
                    'error-message': !numberRegex.test(password.value),
                    'success-message': numberRegex.test(password.value)
                  }"
                >
                  <span
                    *ngIf="numberRegex.test(password.value)"
                    class="fa fa-check"
                  ></span
                  ><span
                    *ngIf="!numberRegex.test(password.value)"
                    class="fa fa-times"
                  ></span
                  >&nbsp;A Number
                </div>
                <div
                  [ngClass]="{
                    'error-message': !specialcharcterRegex.test(password.value),
                    'success-message': specialcharcterRegex.test(password.value)
                  }"
                >
                  <span
                    *ngIf="specialcharcterRegex.test(password.value)"
                    class="fa fa-check"
                  ></span
                  ><span
                    *ngIf="!specialcharcterRegex.test(password.value)"
                    class="fa fa-times"
                  ></span
                  >&nbsp;A special character
                </div>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="user-industry">Industry</label>
                <select
                  id="user-industry"
                  name="industry"
                  #industry="ngModel"
                  ngModel
                  [ngClass]="{ 'input-error': isSubmitted && !industry.valid }"
                  class="form-control"
                  required
                >
                  <option value="">Select Industry</option>
                  <option>Real Estate</option>
                  <option>Mortgage</option>
                  <option>Title & Escrow</option>
                  <option>Employee Engagement</option>
                  <option>Customer Success</option>
                  <option>Sales</option>
                  <option>Financial Advisor</option>
                  <option>Others</option>
                </select>
              </div>
              <div class="form-group col-md-6" *ngIf="!inviteCode">
                <label for="last-name">Company (Optional)</label>
                <input
                  class="form-control"
                  ngModel
                  type="text"
                  name="company"
                  #company="ngModel"
                  [ngClass]="{ 'input-error': isSubmitted && company.invalid }"
                />
              </div>

              <div class="form-group col-md-6" *ngIf="inviteCode">
                <label for="last-name">Company</label>
                <input
                  class="form-control border-left-0"
                  ngModel
                  type="text"
                  name="company"
                  #company="ngModel"
                  ngModel
                  [ngClass]="{ 'input-error': isSubmitted && !company.valid }"
                  required
                />
              </div>
            </div>

            <div class="p-2"></div>
            <p *ngIf="isInvalid" class="text-left text-danger">
              {{ signUpError }}<br />
            </p>
            <button
              style="font-size: 14px !important; padding: 8px !important"
              class="btn btn-block btn-danger btn-c-account mt-3"
              type="submit"
            >
              Create Account
            </button>
          </form>

          <p class="pt-1 text-left">
            Already a member?&nbsp;<a
              class="link-color"
              [routerLink]="['/login-cta']"
              >Sign in</a
            >
          </p>
          <div class="p-1"></div>
          <p class="line-btw-text"><span>or</span></p>
          <div class="p-1"></div>
          <a
            href="https://client.stage.evabot.com/user/googleLogin"
            style="
              font-size: 14px !important;
              background: #ffffff 0% 0% no-repeat padding-box;
              border: 1px solid #e2e9f2;
              opacity: 1;
              padding: 8px !important;
            "
            class="btn btn-block mt-3 link-color btn-google"
            type="submit"
          >
            <img
              width="15"
              src="../../../../assets/img/signup/google.png"
            />&nbsp;&nbsp;Sign up with Google</a
          >
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <p class="text-center m-3">
                        <a href="https://client.stage.evabot.com/user/googleLogin">
                            <img class="google-option-image" src="../../../../assets/img/btn_google_signin_dark_normal_web.png">
                        </a>
                    </p> -->

<!-- <p *ngIf="isInvalid" class="text-center text-danger">{{signUpError}}</p> -->
