import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from './api.service';

@Injectable()
export class PromoService {
    constructor(private apiService: ApiService) { }

    verifyCode(obj: any): Observable<any> {
        return this.apiService.post("promoCode/verifyCode", obj).pipe(
            map((data:any) => {
                const promoData = data.promoData;
                return promoData;
            })
        )
    }

    verifyCodeByEmail(obj: any): Observable<any> {
        return this.apiService.post("promoCode/verifyCodeByEmail", obj).pipe(
            map((data:any) => {
                const promoData = data.promoData;
                return promoData;
            })
        )
    }

    validateWheelOffer(obj: any): Observable<any> {
        return this.apiService.post("promoCode/validateWheelOffer", obj).pipe(
            map((data:any) => {
                const promoData = data.promoData;
                return promoData;
            })
        )
    }

}