import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { User } from '../../../core/models/user.model';
import { UserSocialAccount } from '../../../core/services/social.service';
import { UserService } from '../../../core/services/user.service';

@Component({
  selector: 'app-linkedin-callback',
  templateUrl: './linkedin-callback.component.html',
  styleUrls: ['./linkedin-callback.component.css'],
  providers: [UserSocialAccount]
})
export class LinkedinCallbackComponent implements OnInit, OnDestroy {

  private ngUnsubscribe = new Subject();

  constructor(private activateRoute: ActivatedRoute, private userService: UserService, private userSocialAccount: UserSocialAccount, private router: Router) { }

  ngOnInit() {
    this.userService.currentUser.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (user: User) => {
        if (user.TokenNumber) {
          this.activateRoute.queryParams.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
            (params: Params) => {
              const obj = { code: params["code"], state: params["state"] }
              this.userSocialAccount.saveLinkedInData(obj).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
                (data) => {
                  
                  this.router.navigate(['/giftSettings']);
                }
              )
            }
          )
        }
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
