import { Component, OnInit, OnDestroy, Inject, PLATFORM_ID } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router } from "@angular/router";
import { isPlatformBrowser } from '@angular/common';

import { Md5 } from "ts-md5/dist/md5";

import { AppConstants } from '../../../shared/app.constant';
import { Helper } from "../../../shared/helper";
import { User } from '../../../core/models/user.model';
import { UserService } from '../../../core/services/user.service';
declare var require: any
const Swal = require("sweetalert2");

@Component({
  selector: 'app-signin-cta',
  templateUrl: './signin-cta.component.html',
  styleUrls: ['../../on-boarding/on-boarding.css'],
})
export class SigninCTAComponent implements OnInit, OnDestroy {
  public isSubmitted: boolean = false;
  public isInvalid: boolean = false;
  private userSubscription: any
  public isView: boolean = false;
  public IsSSO: boolean =false;
  public isShowPassword: boolean = false;
  public keyType: string = "password";

  

  constructor(private userService: UserService, private router: Router, @Inject(PLATFORM_ID) private platformId: Object) {
    this.isView = isPlatformBrowser(this.platformId)
  }

  ngOnInit() {
  }

  onSignIn(signInForm: NgForm) {
    this.isSubmitted = true;
    this.isInvalid = false;
    if (signInForm.valid) {

      const email = signInForm.value.email;
      const password = Md5.hashStr(signInForm.value.password);

       if (email.indexOf("@amerifirst.com") != -1 || email.indexOf("@ameritrusthomemortgage.com") != -1 || email.indexOf("@ameritrusthomeloans.com") != -1 || email.indexOf("@ameriuno.com") != -1) {
        Swal.fire({
          icon: 'error',
          title: '',
          html:'Your organization only allows sign in through SSO. Please click <a href="https://myapps.microsoft.com/signin/3f7ad09d-d31f-4d59-bcb4-85ba26efd834?tenantId=d423bccd-fe9d-4ba8-a245-a65a3876bf5c">here</a> to sign in through SSO <br><br> <p>If you don\'t have an Evabot account, please contact your Eva administrator (marketing@amerifirst.com)</p>',
          confirmButtonColor: AppConstants.CONFIRM_BUTTON_COLOR
        })
        return;
      }

      const credentials = { email: email, password: password, isSession: false };

      this.userSubscription = this.userService.attemptAuth(credentials).subscribe(
        (user: User) => {
          let data: any = user;
          if (!data.error) {
            var route = [];
            if (Helper.IsGuildUser(user.Email)) {
              route = Helper.guildRoute(user.HomeActivity, user.IsSetUp);
            }
            else if (Helper.IsSVBUser(user.Email)) {
              route = Helper.svbRoute(user.Email, user.IsSetUp);
            }
            else if (user.HomeActivity.indexOf("remoteuser") != -1 && user.PlanName == "free") {
              route = ['/employeegifts', 'plan'];
            }
            else if (user.IsSetUp == 0) {
              route = [localStorage.getItem("ob"), "step1"];
            }
            else {
              if (localStorage.getItem("navigationUrl"))
                route = localStorage.getItem("navigationUrl").split("||");
              else
                route = ["/"]
            }

            localStorage.removeItem("navigationUrl");
            if (localStorage.getItem("queryParams")) {
              let params = localStorage.getItem("queryParams");
              localStorage.removeItem("queryParams");
              let queryParams = JSON.parse('{"' + decodeURI(params).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}'
              )
              this.router.navigate(route, { queryParams: queryParams });
            }
            else {
              this.router.navigate(route);
            }

            // if (user.TokenNumber && user.TotalLogin < 3 && false) {
            //   this.data = Object.assign({ route: route });
            // }
            // else {
            //   console.log(route);
            //   this.router.navigate(route);
            // }

          }
          else {
            data.error == 1 ? this.IsSSO=true : this.IsSSO=false;
            this.isSubmitted = false;
            this.isInvalid = true;
          }

        },
        error => {
          this.isSubmitted = false;
          this.isInvalid = true;
          this.IsSSO=false;
        }
      );


    }
  }

  onShowPassword() {
    this.isShowPassword ? this.keyType = "password" : this.keyType = "text";
    this.isShowPassword = !this.isShowPassword;
  }

  ngOnDestroy() {
    if (this.userSubscription)
      this.userSubscription.unsubscribe();
  }
}
