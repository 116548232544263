/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./redirect-gift-platform.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../core/services/gift.platform.service";
import * as i3 from "../../../core/services/api.service";
import * as i4 from "./redirect-gift-platform.component";
import * as i5 from "@angular/router";
import * as i6 from "../../../core/services/user.service";
var styles_RedirectGiftPlatformComponent = [i0.styles];
var RenderType_RedirectGiftPlatformComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RedirectGiftPlatformComponent, data: {} });
export { RenderType_RedirectGiftPlatformComponent as RenderType_RedirectGiftPlatformComponent };
export function View_RedirectGiftPlatformComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" loading...\n"]))], null, null); }
export function View_RedirectGiftPlatformComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-redirect-gift-platform", [], null, null, null, View_RedirectGiftPlatformComponent_0, RenderType_RedirectGiftPlatformComponent)), i1.ɵprd(512, null, i2.GiftPlatformService, i2.GiftPlatformService, [i3.ApiService]), i1.ɵdid(2, 114688, null, 0, i4.RedirectGiftPlatformComponent, [i2.GiftPlatformService, i5.ActivatedRoute, i6.UserService, i5.Router], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var RedirectGiftPlatformComponentNgFactory = i1.ɵccf("app-redirect-gift-platform", i4.RedirectGiftPlatformComponent, View_RedirectGiftPlatformComponent_Host_0, {}, {}, []);
export { RedirectGiftPlatformComponentNgFactory as RedirectGiftPlatformComponentNgFactory };
