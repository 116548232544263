import { Component, OnInit, OnDestroy, Inject, PLATFORM_ID } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { NgForm } from '@angular/forms';
import { isPlatformBrowser } from '@angular/common';

import { Md5 } from 'ts-md5';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Helper } from '../../../shared/helper';
import { User } from '../../../core/models/user.model';
import { TeamService } from '../../../core/services/team.service';
import { UserService } from '../../../core/services/user.service';


@Component({
  selector: 'app-register-mortgage',
  templateUrl: './register-mortgage.component.html',
  styleUrls: ['./register-mortgage.component.css'],
  providers: [TeamService]
})

export class RegisterMortgageComponent implements OnInit, OnDestroy {
  public isSubmitted: boolean = false;
  public isInvalid: boolean = false;
  private ngUnsubscribe = new Subject();
  public inviteCode: string;
  private orderHash: string;
  public userName: string;
  public userEmail: string;
  public cEvent: string;
  public signUpError: string = "User already exists";

  public slides: any;
  public slideConfig: any = { slidesToShow: 1, slidesToScroll: 1, arrows: false, autoplay: true, dots: true };
  public isMobile: boolean = Helper.isMobile();
  public sliderData: any;

  public images = this.isMobile ?
    [
      { img: "../../../../assets/img/mortgage/slide-1-1.png" },
      { img: "../../../../assets/img/mortgage/slide-2-2.png" },
      { img: "../../../../assets/img/mortgage/slide-3-3.png" },
    ] :
    [
      { img: "../../../../assets/img/mortgage/1.png" },
      { img: "../../../../assets/img/mortgage/2.png" },
      { img: "../../../../assets/img/mortgage/3.png" },
    ];

  public brandingList: any = [];
  public brandingBoxStatus: any;
  public brandingCardStatus: any;


  constructor(private userService: UserService, private router: Router, private activatedRoute: ActivatedRoute, private teamService: TeamService, @Inject(PLATFORM_ID) private platformId: Object) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(
      (params: Params) => {
        this.inviteCode = params["ic"];
        this.orderHash = params["hs"];
        this.userName = params["na"];
        this.userEmail = params["em"];
        this.cEvent = params["p"];

        if (params["tid"]) {
          localStorage.setItem("teamHash", params["tid"]);
        }

        if (params["cu"]) {
          this.userService.getUserByHashId(params["cu"]).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
            (user: User) => {
              this.userEmail = user.Email;
            }
          )
        }

        if (params["type"] && params["type"] + "".toLowerCase() == "dm") {
          localStorage.setItem("dmPlan", "dm");
        }

        if (params["redirect"]) {
          localStorage.setItem("redirect", params["redirect"]);
        }

        localStorage.setItem("ob", "/ob");

        if (isPlatformBrowser(this.platformId)) {
          localStorage.setItem("ic", params["ic"]);
          localStorage.setItem("hs", params["hs"]);
          localStorage.setItem("cEvent", params["p"]);
        }
      });
  }

  onSignUp(signUpForm: NgForm) {
    this.isSubmitted = true;
    this.isInvalid = false;
    if (signUpForm.valid) {
      let obj = {
        name: signUpForm.value.name,
        email: signUpForm.value.email,
        password: Md5.hashStr(signUpForm.value.password),
        company: "",
        inviteCode: "",
        orderHash: this.orderHash
      }

      var identifier = signUpForm.value.email,
        contact_properties = {
          "fs_contact": true,
          "First name": signUpForm.value.name,
          "Last name": "",
          "Date Of Birth": "",
          "company": {
            "Name": "",
            "Website": ""
          }
        };


      if (localStorage.getItem("teamHash")) {
        const TeamObj = { teamHash: localStorage.getItem("teamHash"), emailId: signUpForm.value.email }
        this.teamService.inviteUserByTeamCode(TeamObj).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
          (data) => {
            if (data.Message == 1 || data.Message == 2)
              this.onRegisterUser(obj);
            else if (data.Message == 0) {
              this.signUpError = "You are already added to some another team. Please contact us at care@evabot.ai."
              this.isSubmitted = false;
              this.isInvalid = true;
            }
            else if (data.Message == 3) {
              this.signUpError = "You are already added to " + data.TeamName + " Team."
              this.isSubmitted = false;
              this.isInvalid = true;
            }
            else {
              this.signUpError = "You are already on another plan and hence cannot be added to a team automatically."
              this.isSubmitted = false;
              this.isInvalid = true;
            }
          }
        )
      }
      else {
        this.onRegisterUser(obj);
      }

    }
  }

  onRegisterUser(obj: any) {
    this.userService.registerUser(obj).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data) => {
        if (data.Message) {
          this.isSubmitted = false;
          this.isInvalid = true;
        }
        else {
          if (isPlatformBrowser(this.platformId)) {
            localStorage.removeItem("hs");
          }
          this.userService.populate();
          this.userService.currentUser.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
            (user: User) => {
              if (user.TokenNumber) {
                if (this.cEvent)

                if (localStorage.getItem("dmPlan")) {
                  this.userService.updateOnBoardingStatus(false);
                  this.router.navigate(["/directMailerPlan"]);
                }
                else if (localStorage.getItem("redirect")) {
                  this.userService.updateOnBoardingStatus(false);
                  let redirectionUrl = localStorage.getItem("redirect").split("/");
                  redirectionUrl[0] = "/" + redirectionUrl[0];
                  this.router.navigate(redirectionUrl);
                }
                else {
                  let obPrefix = localStorage.getItem("ob");
                  this.router.navigate([obPrefix, "step1"]);
                }
              }
            }
          )
        }
      },
    );
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
    localStorage.removeItem("teamHash");
    localStorage.removeItem("redirect");
  }
}

