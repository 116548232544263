import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  CanActivate
} from "@angular/router";
import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { map, take } from "rxjs/operators";

import { UserService } from "./user.service";

@Injectable()
export class RedirectionGuard implements CanActivate {
  path: ActivatedRouteSnapshot[];
  route: ActivatedRouteSnapshot;
  constructor(private userService: UserService, private router: Router) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.userService.isAuthenticated.pipe(
      take(1),
      map((isAuthenticated: boolean) => {
        if (localStorage.getItem("sessionId")) {
          return true;
        }
        else
          this.router.navigate(["/login"]);
      })
    );
  }
}
