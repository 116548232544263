<div *ngIf="isSaveDetails" class="wrapper bg-white">
  <div class="block-center">
    <div class="eva-header">
      Welcome to Eva
    </div>
    <div class="dropdown-divider divider"></div>
    <div class="eva-sub-header">Please enter your name to continue</div>
    <div class="p-2"></div>

    <div class="user-details">
      <form (ngSubmit)="onUpdateDetails(f)" #f="ngForm">
        <div class="form-group">
          <label class="text-muted">Full Name</label>
          <div class="input-group with-focus">
            <input class="form-control border-right-0" type="text" placeholder="Please enter your name" name="fullname"
              ngModel autocomplete="off" #fullname="ngModel" [ngClass]="{'input-error':isSubmitted&& !fullname.valid}"
              required>
            <div class="input-group-append">
              <span [ngClass]="{'input-error':isSubmitted&& !fullname.valid}"
                class="input-group-text text-muted bg-transparent border-left-0">
                <em class="fa fa-user"></em>
              </span>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label class="text-muted">Email Address</label>
          <div class="input-group with-focus">
            <input disabled class="form-control border-right-0" type="email" placeholder="Email Address" name="email"
              ngModel [(ngModel)]="userEmail" autocomplete="off" #email="ngModel">
            <div class="input-group-append">
              <span class="input-group-text text-muted bg-transparent border-left-0">
                <em class="fa fa-envelope"></em>
              </span>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="input-group with-focus">
            <select name="industry" #industry="ngModel" ngModel
              [ngClass]="{'input-error':isSubmitted && !industry.valid}" class="form-control" required>
              <option value="">Select Industry</option>
              <option>Real Estate</option>
              <option>Mortgage</option>
              <option>Title & Escrow</option>
              <option>Employee Engagement</option>
              <option>Customer Success</option>
              <option>Sales</option>
              <option>Financial Advisor</option>
              <option>Others</option>
            </select>
            <div class="input-group-append">
              <span [ngClass]="{'input-error':isSubmitted && industry.invalid}"
                class="input-group-text text-muted bg-transparent border-left-0">
                <em class="fa fa-industry"></em>
              </span>
            </div>
          </div>
        </div>
        <div *ngIf="!inviteCode" class="form-group">
          <label class="text-muted">Company</label>
          <div class="input-group with-focus">
            <input class="form-control border-right-0" type="text" placeholder="Company (optional)" name="company"
              ngModel autocomplete="off" #company="ngModel">
            <div class="input-group-append">
              <span [ngClass]="{'input-error':isSubmitted&& !company.valid}"
                class="input-group-text text-muted bg-transparent border-left-0">
                <em class="fa fa-building"></em>
              </span>
            </div>
          </div>
        </div>

        <div *ngIf="inviteCode" class="form-group">
          <label class="text-muted">Company</label>
          <div class="input-group with-focus">
            <input class="form-control border-right-0" type="text" placeholder="Company" name="company" ngModel
              autocomplete="off" #company="ngModel" ngModel [ngClass]="{'input-error':isSubmitted && !company.valid}"
              required>
            <div class="input-group-append">
              <span [ngClass]="{'input-error':isSubmitted&& !company.valid}"
                class="input-group-text text-muted bg-transparent border-left-0">
                <em class="fa fa-building"></em>
              </span>
            </div>
          </div>
        </div>

        <button class="btn btn-danger btn-block" type="submit">Continue</button>
      </form>
    </div>

  </div>

</div>
<p *ngIf="isLoading">Loading...</p>
<app-upgraded-alert [data]="data"></app-upgraded-alert>