import { Injectable } from "@angular/core";

import { Observable, BehaviorSubject } from "rxjs";
import { map } from "rxjs/operators";

import { Team } from "../models/team.model";
import { User } from "../models/user.model";

import { ApiService } from "./api.service";

@Injectable()
export class TeamService {
  constructor(private apiService: ApiService) { }

  getTeamPriceList(teamId: number): Observable<any> {
    return this.apiService.post("team/getPriceList", { teamId: teamId }).pipe(
      map((data: any) => {
        const priceIds = data.prices;
        return priceIds;
      })
    )
  }

  getTeamList(): Observable<Team[]> {
    return this.apiService.post("team/getTeamList", {}).pipe(
      map((data: any) => {
        const teams = data.teams;
        return teams;
      })
    );
  }

  addTeam(object: any) {
    return this.apiService.post("team/addTeam", object).pipe(
      map(result => {
        console.log(result);
      })
    );
  }

  editTeam(object: any) {
    return this.apiService.post("team/editTeam", object).pipe(
      map(result => {
        return result;
      })
    );
  }

  deleteTeam(teamId: number): Observable<any> {
    return this.apiService.post("team/deleteTeam", { teamId: teamId });
  }

  removeTeamUserCard(user: User): Observable<any> {
    return this.apiService.post("team/removeTeamUserCard/", user);

  }

  getUserList(teamId: number): Observable<User[]> {
    return this.apiService.post("team/getUserList", { teamId: teamId }).pipe(
      map((data: any) => {
        const users = data.users;
        return users;
      })
    );
  }

  inviteUser(object: any) {
    return this.apiService.post("team/inviteUser", object).pipe(
      map((result: any) => {
        const data = result.data[0];
        return data;
      })
    );
  }

  updateTeamUser(object: any) {
    return this.apiService.post("team/updateTeamUser", object).pipe(
      map((result: any) => {
        const data = result.data[0];
        return data;
      })
    );
  }


  getDeactivateUserDetails(id: number, type: string) {
    const obj = { id: id, type: type }
    return this.apiService.post("team/getDeactivateUserDetails", obj).pipe(
      map((result: any) => {
        const data = result.data[0];
        return data;
      })
    );
  }


  deactivateUserOrTeam(id: number, type: string) {
    const obj = { id: id, type: type }
    return this.apiService.post("team/deactivateUserOrTeam", obj).pipe(
      map((result: any) => {
        const data = result.data[0];
        return data;
      })
    );
  }

  inviteUserByTeamCode(object: any) {
    return this.apiService.post("team/inviteUserByTeamCode", object).pipe(
      map((result: any) => {
        const data = result.data[0];
        return data;
      })
    );
  }

  resendInvitation(object: any): Observable<any> {
    return this.apiService.post("team/resendInvitation", object);
  }

  deleteTeamUser(teamId: number, userId: number): Observable<any> {
    return this.apiService.post("team/deleteTeamUser", { teamId: teamId, userId: userId });
  }

  deleteInvitation(teamId: number, userId: number): Observable<any> {
    return this.apiService.post("team/deleteInvitation", { teamId: teamId, userId: userId });
  }

  changeUserTeam(obj: any): Observable<any> {
    return this.apiService.post("team/updateTeamUser/", obj);
  }

  removeAllTeamUsers(currentUser: User): Observable<Team[]> {
    return this.apiService.post("team/getUserList", {}).pipe(
      map((data: any) => {
        const users = data.users;
        console.log("delete usres", users);
        users.forEach((user: any) => {
          if (user.TeamId != "" && currentUser.UserId != user.UserId) {
            this.deleteTeamUser(user.TeamId, user.UserId).subscribe();
          }
        });
        return users;
      })
    );
  }

  setDefaultCard(object: any) {
    return this.apiService.post("team/setDefaultCard", object);
  }

  updateTeamUserOwnCard(object: any) {
    return this.apiService.multiplePost("team/updateTeamUserOwnCard", object);
  }

  updateUserType(object: any) {
    return this.apiService.post("team/updateUserType", object);
  }

  updatePaymentSettings(object: any) {
    return this.apiService.post("team/updatePaymentSettings", object);
  }
}
