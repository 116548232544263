import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

import { Address } from '../models/cart.model';

import { ApiService } from './api.service';

@Injectable()
export class AddressService {
    private addresses: Address[];
    private addressSubject = new BehaviorSubject<Address[]>([] as Address[]);
    public addressList = this.addressSubject.asObservable();

    constructor(private apiService: ApiService) {

    }

    fetchAddressList() {
        return this.apiService
            .post("address/getAddressList", {})
            .pipe(
                map((data:any) => {
                    const addresses = data.addresses;
                    return addresses;
                })
            )
            .subscribe((addresses: Address[]) => {
                this.addresses = addresses;
                this.addressSubject.next(addresses);
            });
    }

    addAddress(address: Address) {
        return this.apiService.post("address/addAddress", address).pipe(
            map((data:any) => {
                const addresses = data.addresses;
                this.addresses = addresses;
                this.addressSubject.next(addresses);
                return address.AutoId;
            })
        )
    }

    deleteAddress(autoId: number) {
        return this.apiService
            .post("address/deleteAddress", { autoId: autoId })
            .pipe(
                map((data:any) => {
                    const addresses = data.addresses;
                    return addresses;
                })
            ).subscribe((addresses: Address[]) => {
                this.addresses = addresses;
                this.addressSubject.next(addresses);
            });
    }

    markDefault(autoId: number) {
        return this.apiService
            .post("address/markDefault", { autoId: autoId })
            .pipe(
                map((data:any) => {
                    const addresses = data.addresses;
                    return addresses;
                })
            ).subscribe((addresses: Address[]) => {
                this.addresses = addresses;
                this.addressSubject.next(addresses);
            });
    }

    getAddressByEmail(email: string) {
        return this.apiService.post("address/getAddressByEmail", { email: email });
    }

    getGiftPlatFormAddress(txnId: string, externalId: string) {
        return this.apiService.post("address/getGiftPlatFormAddress", { txnId: txnId, externalId: externalId });
    }
}