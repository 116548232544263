import { Component, OnInit, OnDestroy, Inject, PLATFORM_ID } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { NgForm } from '@angular/forms';
import { isPlatformBrowser } from '@angular/common';

import { Md5 } from 'ts-md5';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Helper } from '../../../../shared/helper';
import { User } from '../../../../core/models/user.model';
import { TeamService } from '../../../../core/services/team.service';
import { UserService } from '../../../../core/services/user.service';
import { SubscriptionService } from '../../../../core/services/subscription.service';

@Component({
  selector: 'app-side-register',
  templateUrl: './side-register.component.html',
  styleUrls: ['./side-register.component.css'],
  providers: [TeamService, SubscriptionService]
})

export class SideRegisterComponent implements OnInit, OnDestroy {
  public isSubmitted: boolean = false;
  public isInvalid: boolean = false;
  private ngUnsubscribe = new Subject();
  public inviteCode: string;
  private orderHash: string;
  public userName: string;
  public userEmail: string;
  public cEvent: string;
  public signUpError: string = "User already exists";
  public lowercaseRegex: RegExp = /[a-z]/;
  public uppercaseRegex: RegExp = /[A-Z]/;
  public numberRegex: RegExp = /\d/;
  public min8Regex: RegExp = /.{8,}/;
  public specialcharcterRegex: RegExp = /\W/;
  public isShowPassword: boolean = false;
  public keyType: string = "password";
  public isGuildError: boolean = false;

  constructor(private userService: UserService, private router: Router, private activatedRoute: ActivatedRoute, private teamService: TeamService, @Inject(PLATFORM_ID) private platformId: Object, private subscriptionService: SubscriptionService) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(
      (params: Params) => {
        this.inviteCode = params["ic"];
        this.orderHash = params["hs"];
        this.userName = params["na"];
        this.userEmail = params["em"];
        this.cEvent = params["p"];

        if (params["tid"]) {
          localStorage.setItem("teamHash", params["tid"]);
        }

        if (params["cu"]) {
          this.userService.getUserByHashId(params["cu"]).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
            (user: User) => {
              this.userEmail = user.Email;
            }
          )
        }

      });
  }

  onSignUp(signUpForm: NgForm) {
    this.isSubmitted = true;
    this.isInvalid = false;
    this.isGuildError = false;
    if (signUpForm.valid) {

      // if (!Helper.IsSideUser(signUpForm.value.email)) {
      //   this.isGuildError = true;
      //   return;
      // }

      let obj = {
        name: signUpForm.value.name,
        email: signUpForm.value.email,
        password: Md5.hashStr(signUpForm.value.password),
        company: signUpForm.value.company,
        industry: signUpForm.value.industry,
        inviteCode: this.inviteCode,
        orderHash: this.orderHash
      }

      if (localStorage.getItem("teamHash")) {
        const TeamObj = { teamHash: localStorage.getItem("teamHash"), emailId: signUpForm.value.email }
        this.teamService.inviteUserByTeamCode(TeamObj).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
          (data) => {
            if (data.Message == 1 || data.Message == 2)
              this.onRegisterUser(obj);
            else if (data.Message == 0) {
              this.signUpError = "You are already added to some another team. Please contact us at care@evabot.ai."
              this.isSubmitted = false;
              this.isInvalid = true;
            }
            else if (data.Message == 3) {
              this.signUpError = "You are already added to " + data.TeamName + " Team."
              this.isSubmitted = false;
              this.isInvalid = true;
            }
            else {
              this.signUpError = "You are already on another plan and hence cannot be added to a team automatically."
              this.isSubmitted = false;
              this.isInvalid = true;
            }
          }
        )
      }
      else {
        this.onRegisterUser(obj);
      }
    }
  }

  onRegisterUser(obj: any) {
   
    this.userService.registerUser(obj).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data) => {
        if (data.Message) {
          this.isSubmitted = false;
          this.isInvalid = true;
        }
        else {
          this.userService.populate();
          this.userService.currentUser.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
            (user: User) => {
              if (user.TokenNumber) {
                const tag = "$$$side$$$";
                this.subscriptionService.updateGuildZeroPlan({ tag: tag }).subscribe(
                  (data) => {
                    this.userService.updateOnBoardingStatus(false);
                    user.HomeActivity = user.HomeActivity + ",$$$side$$$";
                    user.IsSetUp = 1;
                    this.userService.updateUserReference(user);
                    this.router.navigate(["/home"]);
                  });
              }
            }
          )
        }
      },
    );
  }

  onShowPassword() {
    this.isShowPassword ? this.keyType = "password" : this.keyType = "text";
    this.isShowPassword = !this.isShowPassword;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }
}


