<!-- START Top Navbar-->
<nav id="notlogin-header" style="box-shadow:0px 0px 0px 1px lightgrey" *ngIf="!currentUser.TokenNumber&&isHeaderShow"
    class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand">
        <div class="brand-logo">
            <img style="width: 50px; image-rendering: auto; " style="width: 243px;padding-left: 23px;"
                src="https://s3.amazonaws.com/eva-bot/img/6a92eb0f-1083-400b-898b-6cc623a2afab.png" alt="Eva Logo" />
        </div>
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup"
        aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
        <ul class="navbar-nav ml-auto">
            <li class="nav-item header2-item">
                <a class="nav-link" href="https://evabot.ai">Home <span class="sr-only">(current)</span></a>
            </li>
            <li class="nav-item header2-item">
                <a class="nav-link" href="https://evabot.ai/remote">Remote Teams <span
                        class="sr-only">(current)</span></a>
            </li>
            <li class="nav-item header2-item">
                <a class="nav-link" href="https://dashboard.evabot.ai/exploreGifts">Gift Boxes</a>
            </li>
            <li class="nav-item header2-item">
                <a class="nav-link" href="https://www.evabot.ai/branding">Branding</a>
            </li>
            <li class="nav-item header2-item ">
                <a class="nav-link" href="https://www.evabot.ai/pricing">Pricing</a>
            </li>
            <li class="nav-item header2-item">
                <a class="nav-link" href="https://www.evabot.ai/catalog/">Catalog</a>
            </li>
            <li class="nav-item header2-item">
                <a class="nav-link" href="https://www.evabot.ai/covid">COVID-19</a>
            </li>
            <li class="nav-item header2-item">
                <a [routerLink]="['/login']" style="font-size: 18px;" class="btn btn-danger">Sign Up / Login</a>
            </li>
        </ul>
    </div>
</nav>
<header class="topnavbar-wrapper" *ngIf="currentUser.TokenNumber && currentUser.IsSetUp==1">
    <nav class="navbar topnavbar" role="navigation">
        <!-- START navbar header-->
        <div id="h-panel" class="navbar-header">
            <a style="margin-left: -55px;" *ngIf="currentUser.TokenNumber" class="navbar-brand" href="/">
                <div class="brand-logo">
                    <img style="width: 243px;padding-left: 23px;" src="https://s3.amazonaws.com/eva-bot/img/6a92eb0f-1083-400b-898b-6cc623a2afab.png" alt="Eva Logo" />
                </div>
                <div class="brand-logo-collapsed">
                    <img style="width: 243px;padding-left: 23px;" src="https://s3.amazonaws.com/eva-bot/img/6a92eb0f-1083-400b-898b-6cc623a2afab.png" alt="Eva Logo" />
                </div>
            </a>

            <a  style="margin-left: -55px;" *ngIf="!currentUser.TokenNumber" class="navbar-brand">
                <div class="brand-logo">
                    <img style="width: 243px;padding-left: 23px;" src="https://s3.amazonaws.com/eva-bot/img/6a92eb0f-1083-400b-898b-6cc623a2afab.png" alt="Eva Logo" />
                </div>
                <div class="brand-logo-collapsed">
                    <img style="width: 243px;padding-left: 23px;" src="https://s3.amazonaws.com/eva-bot/img/6a92eb0f-1083-400b-898b-6cc623a2afab.png" alt="Eva Logo" />
                </div>
            </a>
        </div>
        <!-- END navbar header-->
        <!-- START Left navbar-->
        <ul *ngIf="isMobile" class="navbar-nav mr-auto flex-row">
            <li class="nav-item">
                <!-- Button used to collapse the left sidebar. Only visible on tablet and desktops-->
                <a class="nav-link d-none d-md-block d-lg-block d-xl-block" trigger-resize=""
                    (click)="toggleCollapsedSideabar()" *ngIf="!isCollapsedText()">
                    <em style="color:#DD015B !important ;" class="fas fa-bars"></em>
                </a>
                <!-- Button to show/hide the sidebar on mobile. Visible on mobile only.-->
                <a class="nav-link sidebar-toggle d-md-none"
                    (click)="settings.toggleLayoutSetting('asideToggled'); $event.stopPropagation()">
                    <em  style="color:#DD015B !important ;" class="fas fa-bars"></em>
                </a>
            </li>


        </ul>
        <!-- END Left navbar-->
         <div style="position: relative; display:flex; column-gap: 20px; margin-right: 30px;">
            <!--<div *ngIf="currentUser.TokenNumber && currentUser.IsSetUp==1"
                [routerLink]="['/cart']" id="explore-cart">
                <div class="cart-number evagreentext">{{userStatics.TotalCartItem}}</div>
                <span style="color:#000;font-size: 15px" class="fa fa-shopping-cart"></span>
            </div> -->
            <div  *ngIf="currentUser.TokenNumber && currentUser.IsSetUp==1">
                <img style="width: 40px;height: 40px;border-radius: 40px;   border: 1px solid #EAECEE;" [src]="currentUser.Image">
            </div>

        </div> 


        <div style="margin-right: 2%;cursor: pointer;position:absolute;right: 0;"
            *ngIf="currentUser.TokenNumber && currentUser.HomeActivity.indexOf('remoteuser')!=-1&& currentUser.IsSetUp==0">
            <a (click)="onLogOut()" style="color:white;font-size: 16px"><span style="color:white;font-size: 16px"
                    class="fa fa-power-off"></span>&nbsp;Log Out</a>
        </div>


    </nav>
</header>

<nav id="notlogin-header-2" style="box-shadow:0px 0px 0px 1px lightgrey;display: none;position: fixed; width: 100%;"
    *ngIf="!currentUser.TokenNumber" class="navbar navbar-light bg-light">
    <a class="navbar-brand">
        <div class="brand-logo">
            <img style="width: 50px; image-rendering: auto; " style="width: 243px;padding-left: 23px;"
                src="../../../assets/img/evabot-logo-mobile.png" alt="Eva Logo" />
        </div>
    </a>
    <div>
        <a style="font-size: 16px; border-radius: 8px;" class="btn btn-danger" href=""
            onclick="Calendly.initPopupWidget({url: 'https://calendly.com/jetinfromevabot/30min'});return false;">Schedule
            a gifting demo</a>
    </div>

</nav>
<!-- END Top Navbar-->
<!-- <app-google-login-alert [data]="alertData"></app-google-login-alert> -->