import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgxPaginationModule } from 'ngx-pagination';

import { NgxMyDatePickerModule } from 'ngx-mydatepicker';

import { ClipboardModule } from "ngx-clipboard";

import { ScModalModule } from "angular-5-popup";

import { NgxMaskModule } from "ngx-mask";

import { QuillModule } from 'ngx-quill';

import { PapaParseModule } from 'ngx-papaparse';

import { SlickCarouselModule } from 'ngx-slick-carousel';

import { S3UploaderModule } from 'ngx-s3-uploader';

import { PopoverModule } from 'ngx-bootstrap/popover';

import { CountdownTimerModule } from 'ngx-countdown-timer';

import { OrderModule } from 'ngx-order-pipe';

import { TagInputModule } from 'ngx-chips';

import { TooltipModule } from "ngx-bootstrap";

import { ExploreGiftService } from '../core/services/explore.gift.service';
import { CardListComponent } from '../routes/card/card-list/card-list-component';

import { HandwrittenCharacterCount, handwrittenReplaceSpecialCharacters, MailerHandwrittenCharacterCount } from './pipes/handwritten-character-count.pipe';
import { preference } from './pipes/preference.pipe';
import { removeCharacter } from './pipes/remove-character.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { SortPipe } from './pipes/sort.pipe';
import { UrlValidator } from './validator/url';
import { AddChildUserComponent } from './modals/add-child-user/add-child-user.component';
import { AddMerchandiseComponent } from './modals/add-merchandise/add-merchandise.component';
import { AddRecipientAutomatedCampaignComponent } from './modals/add-recipient-automated-campaign/add-recipient-automated-campaign.component';
import { AddressListComponent } from './modals/address-list/address-list.component';
import { AutoCompleteAddressComponent } from './modals/auto-complete-address/auto-complete-address.component';
import { BookDemoComponent } from './modals/book-demo/book-demo.component';
import { BrandingOfferComponent } from './modals/branding-offer/branding-offer.component';
import { BuyEnterprisePlanComponent } from './modals/buy-enterprise-plan/buy-enterprise-plan.component';
import { BuyMailerPostcardComponent } from './modals/buy-mailer-postcard/buy-mailer-postcard.component';
import { ChangeCardComponent } from './modals/change-card/change-card.component';
import { ChangeEmailComponent } from './modals/change-email/change-email.component';
import { ChangeMailerAddressComponent } from './modals/change-mailer-address/change-mailer-address.component';
import { ChangeMobileComponent } from './modals/change-mobile/change-mobile.component';
import { ChangeTeamComponent } from './modals/change-team/change-team.component';
import { ConfigureAutoRechargeComponent } from './modals/configure-auto-recharge/configure-auto-recharge.component';
import { EmailAlertDiffSenderComponent } from './modals/email-alert-diff-sender/email-alert-diff-sender.component';
import { EmailAlertComponent } from './modals/email-alert/email-alert.component';
import { EnterprisePlanComponent } from './modals/enterprise-plan/enterprise-plan.component';
import { ExploreGiftPricePointComponent } from './modals/explore-gift-price-point/explore-gift-price-point.component';
import { GoogleLoginAlertComponent } from './modals/google-login-alert/google-login-alert.component';
import { HandwrittenNoteComponent } from './modals/handwritten-note/handwritten-note.component';
import { GiftWishComponent } from './modals/gift-wish/gift-wish.component';
import { GiftSentComponent } from "./modals/gift-sent/gift-sent.component";
import { GiftSentFrequencyComponent } from "./modals/gift-sent-frequency/gift-sent-frequency.component"
import { ImageSliderComponent } from './modals/image-slider/image-slider.component';
import { ImageComponent } from './modals/image/image.component';
import { MerchandiseProductComponent } from './modals/merchandise-product/merchandise-product.component';
import { OfferPlanComponent } from './modals/offer-plan/offer-plan.component';
import { PauseSubscriptionComponent } from './modals/pause-subscription/pause-subscription.component';
import { PlanComponent } from './modals/plan/plan.component';
import { PremiumPlanComponent } from './modals/premium-plan/premium-plan.component';
import { RecommendationComponent } from './modals/recommendation/recommendation.component';
import { ReferralTemplateComponent } from './modals/referral-template/referral-template.component';
import { ReviewLinkExampleComponent } from './modals/review-link-example/review-link-example.component';
import { SendFirstGiftComponent } from './modals/send-first-gift/send-first-gift.component';
import { SmsAlertComponent } from './modals/sms-alert/sms-alert.component';
import { SmsInviteAlertComponent } from './modals/sms-invite-alert/sms-invite-alert.component';
import { SocialPostSampleComponent } from './modals/social-post-sample/social-post-sample.component';
import { SubscriptionChangeCardComponent } from './modals/subscription-change-card/subscription-change-card.component';
import { UpgradedAlertComponent } from './modals/upgraded-alert/upgraded-alert.component';
import { AddAddressComponent } from './templates/add-address/add-address.component';
import { AppOfferCardComponent } from './templates/app-offer-card/app-offer-card.component';
import { BrandingDiscountComponent } from './templates/branding-discount/branding-discount.component';
import { BrandingHandwrittenNotesComponent } from './templates/branding-handwritten-notes/branding-handwritten-notes.component';
import { BrandingHandwrittenSliderComponent } from './templates/branding-handwritten-slider/branding-handwritten-slider.component';
import { BrandingSliderComponent } from './templates/branding-slider/branding-slider.component';
import { BrandingSpecificationsComponent } from './templates/branding-specifications/branding-specifications.component';
import { CardFormComponent } from './templates/card-form/card-form.component';
import { ComparePlanComponent } from './templates/compare-plan/compare-plan.component';
import { EmployeeGiftOfferComponent } from './templates/employee-gift-offer/employee-gift-offer.component';
import { ExploreGiftPriceComponent } from './templates/explore-gift-price/explore-gift-price.component';
import { FirstGiftComponent } from './templates/first-gift/first-gift.component';
import { GoogleButtonComponent } from './templates/google-button/google-button.component';
import { GooglePlacesComponent } from './templates/google-places/google-places.component';
import { MailerPlanDemoComponent } from './templates/mailer-plan-demo/mailer-plan-demo.component';
import { MailerPlanComponent } from './templates/mailer-plan/mailer-plan.component';
import { MobileComponent } from './templates/mobile/mobile.component';
import { OutlookButtonComponent } from './templates/outlook-button/outlook-button.component';
import { ShipGiftMeComponent } from './templates/ship-gift-me/ship-gift-me.component';
import { SubscriptionComponent } from './templates/subscription/subscription.component';
import { facebookLinkValidator } from './validator/review-link/facebook';
import { googleLinkValidator } from './validator/review-link/google';
import { TeamFormComponent } from './modals/team-form/team-form.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { InviteUserFormComponent } from './modals/invite-user-form/invite-user-form.component';
import { DeactivateTeamComponent } from './modals/deactivate-team/deactivate-team.component';
import { PaymentSettingsComponent } from './modals/payment-settings/payment-settings.component';
import { DeleteCardComponent } from './modals/delete-card/delete-card.component';
import { SearchGooglePlaceComponent } from './modals/search-google-place/search-google-place.component';
import { SpecialCharacterValidator } from './validator/no-special-character';
import { AddCardComponent } from './modals/add-card/add-card.component';
import { PasswordStrengthComponent } from './validator/password-strength/password-strength.component';
import { CreateUglComponent } from './modals/create-ugl/create-ugl.component';
import { BulkGiftSettingsComponent } from './modals/bulk-gift-settings/bulk-gift-settings.component';
import { UglRecipientListComponent } from './modals/ugl-recipient-list/ugl-recipient-list.component';
import { FirstGiftHolidayComponent } from './modals/first-gift-holiday/first-gift-holiday.component';
import { Obv2Step2Component } from './templates/obv2-step2/obv2-step2.component';
import { Obv2Step1Component } from './templates/obv2-step1/obv2-step1.component';
import { Obv2Step4Component } from './templates/obv2-step4/obv2-step4.component';
import { HolidayVideoComponent } from './modals/holiday-video/holiday-video.component';



@NgModule(
    {
        imports: [
            NgxSliderModule,
            CommonModule,
            FormsModule,
            ReactiveFormsModule,
            RouterModule,
            NgxPaginationModule,
            NgxMyDatePickerModule.forRoot(),
            ClipboardModule,
            ScModalModule,
            NgxMaskModule.forRoot(),
            QuillModule.forRoot(),
            PapaParseModule,
            SlickCarouselModule,
            S3UploaderModule.forRoot({
                region: "us-east-1",
                bucket: "eva-bot",
                credentials: {
                    accessKeyId: "AKIAJHPMEOXCUW4LKBIQ",
                    secretAccessKey: "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"
                }
            }),
            PopoverModule.forRoot(),
            CountdownTimerModule.forRoot(),
            OrderModule,
            TagInputModule,
            TooltipModule.forRoot()
        ],
        declarations: [
            HandwrittenCharacterCount,
            handwrittenReplaceSpecialCharacters,
            MailerHandwrittenCharacterCount,
            SortPipe,
            preference,
            SafePipe,
            SmsAlertComponent,
            MobileComponent,
            EmailAlertComponent,
            SmsInviteAlertComponent,
            CardListComponent,
            AddCardComponent,
            GooglePlacesComponent,
            BrandingSliderComponent,
            UpgradedAlertComponent,
            BrandingSpecificationsComponent,
            ChangeCardComponent,
            ChangeTeamComponent,
            ChangeMobileComponent,
            ChangeEmailComponent,
            PlanComponent,
            SubscriptionComponent,
            PremiumPlanComponent,
            EnterprisePlanComponent,
            ConfigureAutoRechargeComponent,
            ComparePlanComponent,
            FirstGiftComponent,
            SubscriptionChangeCardComponent,
            BrandingHandwrittenNotesComponent,
            ChangeMailerAddressComponent,
            BuyMailerPostcardComponent,
            ImageComponent,
            ImageSliderComponent,
            AutoCompleteAddressComponent,
            AddAddressComponent,
            ShipGiftMeComponent,
            AddressListComponent,
            ExploreGiftPricePointComponent,
            ExploreGiftPriceComponent,
            GoogleLoginAlertComponent,
            MailerPlanComponent,
            BrandingHandwrittenSliderComponent,
            MailerPlanDemoComponent,
            HandwrittenNoteComponent,
            GiftWishComponent,
            GiftSentComponent,
            GiftSentFrequencyComponent,
            ReviewLinkExampleComponent,
            PauseSubscriptionComponent,
            ReferralTemplateComponent,
            EmployeeGiftOfferComponent,
            AppOfferCardComponent,
            MerchandiseProductComponent,
            BuyEnterprisePlanComponent,
            removeCharacter,
            CardFormComponent,
            BrandingOfferComponent,
            BrandingDiscountComponent,
            AddRecipientAutomatedCampaignComponent,
            AddChildUserComponent,
            EmailAlertDiffSenderComponent,
            OfferPlanComponent,
            GoogleButtonComponent,
            OutlookButtonComponent,
            AddMerchandiseComponent,
            RecommendationComponent,
            SendFirstGiftComponent,
            googleLinkValidator,
            UrlValidator,
            facebookLinkValidator,
            SocialPostSampleComponent,
            BookDemoComponent,
            TeamFormComponent,
            InviteUserFormComponent,
            DeactivateTeamComponent,
            PaymentSettingsComponent,
            DeleteCardComponent,
            SearchGooglePlaceComponent,
            SpecialCharacterValidator,
            PasswordStrengthComponent,
            CreateUglComponent,
            BulkGiftSettingsComponent,
            UglRecipientListComponent,
            FirstGiftHolidayComponent,
            Obv2Step2Component,
            Obv2Step1Component,
            Obv2Step4Component,
            HolidayVideoComponent
        ],
        exports: [
            CommonModule,
            RouterModule,
            FormsModule,
            NgxPaginationModule,
            NgxMyDatePickerModule,
            HandwrittenCharacterCount,
            handwrittenReplaceSpecialCharacters,
            MailerHandwrittenCharacterCount,
            preference,
            ClipboardModule,
            ScModalModule,
            SmsAlertComponent,
            NgxMaskModule,
            MobileComponent,
            QuillModule,
            EmailAlertComponent,
            PapaParseModule,
            SmsInviteAlertComponent,
            CardListComponent,
            SlickCarouselModule,
            S3UploaderModule,
            GooglePlacesComponent,
            BrandingSliderComponent,
            SafePipe,
            UpgradedAlertComponent,
            BrandingSpecificationsComponent,
            PopoverModule,
            CountdownTimerModule,
            ChangeCardComponent,
            ChangeTeamComponent,
            OrderModule,
            ChangeMobileComponent,
            ChangeEmailComponent,
            SubscriptionComponent,
            PlanComponent,
            ConfigureAutoRechargeComponent,
            EnterprisePlanComponent,
            ComparePlanComponent,
            FirstGiftComponent,
            SubscriptionChangeCardComponent,
            BrandingHandwrittenNotesComponent,
            ChangeMailerAddressComponent,
            BuyMailerPostcardComponent,
            ImageComponent,
            ImageSliderComponent,
            AutoCompleteAddressComponent,
            AddAddressComponent,
            ShipGiftMeComponent,
            AddressListComponent,
            ExploreGiftPricePointComponent,
            GoogleLoginAlertComponent,
            MailerPlanComponent,
            BrandingHandwrittenSliderComponent,
            MailerPlanDemoComponent,
            TagInputModule,
            HandwrittenNoteComponent,
            GiftWishComponent,
            GiftSentComponent,
            GiftSentFrequencyComponent,
            ReviewLinkExampleComponent,
            PauseSubscriptionComponent,
            ReferralTemplateComponent,
            EmployeeGiftOfferComponent,
            AppOfferCardComponent,
            MerchandiseProductComponent,
            BuyEnterprisePlanComponent,
            removeCharacter,
            CardFormComponent,
            BrandingOfferComponent,
            AddRecipientAutomatedCampaignComponent,
            AddChildUserComponent,
            EmailAlertDiffSenderComponent,
            OfferPlanComponent,
            TooltipModule,
            GoogleButtonComponent,
            OutlookButtonComponent,
            AddMerchandiseComponent,
            RecommendationComponent,
            SendFirstGiftComponent,
            googleLinkValidator,
            UrlValidator,
            facebookLinkValidator,
            SocialPostSampleComponent,
            BookDemoComponent,
            SortPipe,
            TeamFormComponent,
            InviteUserFormComponent,
            DeactivateTeamComponent,
            PaymentSettingsComponent,
            SearchGooglePlaceComponent,
            SpecialCharacterValidator,
            AddCardComponent,
            PasswordStrengthComponent,
            CreateUglComponent,
            BulkGiftSettingsComponent,
            UglRecipientListComponent,
            FirstGiftHolidayComponent,
            Obv2Step2Component,
            Obv2Step1Component,
            Obv2Step4Component,
            HolidayVideoComponent
        ],
        providers: [ExploreGiftService]
    }
)

export class SharedModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: SharedModule
        };
    }
}