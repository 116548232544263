import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError, finalize } from 'rxjs/operators';
import { ErrorService } from './error.service';
import { isPlatformBrowser } from '@angular/common';
import { Helper } from '../../shared/helper';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

    public loading: boolean = false;

    constructor(private errorService: ErrorService, @Inject(PLATFORM_ID) private platformId: Object) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (isPlatformBrowser(this.platformId)) {
            this.loading = true;
            const token = localStorage.getItem('sessionId') == null ? "" : localStorage.getItem('sessionId');
            const tnounce = (new Date()).getTime();
            const apiHash = '?hmac=' + Helper.getHMAC(request.body, tnounce) + '&access_key=cf32521ed1852c92dc8ca85ad1d468bdd2246a89a04a836e3c682b376cabd742&tonce=' + tnounce + '';
            const endPoint = request.url.indexOf("wms/inventory")==-1 ? request.url + apiHash:"";
            if (request.url.indexOf("https://api.evabot.ai") == -1)
                request = request.clone({ url: endPoint, headers: request.headers.
                    set('X-AccessToken', token+"").
                    set('X-AccessKey', "cf32521ed1852c92dc8ca85ad1d468bdd2246a89a04a836e3c682b376cabd742").
                    set('X-Timestamp', tnounce + "").
                    set("X-Signature", Helper.getHMAC(request.body, tnounce)+"").
                    set("Authorization", "Bearer " + localStorage.getItem("uAccessToken")) });
            return next.handle(request).pipe(

                map((event: HttpEvent<any>) => {
                    return event;
                }),
                catchError((error: HttpErrorResponse) => {


                    if (error.status == 401)
                        this.errorService.authenticationError();
                    else if (error.status == 403 && localStorage.getItem("sessionId")) {
                        localStorage.removeItem("sessionId");
                        localStorage.removeItem("uAccessToken");
                        this.errorService.authenticationError();
                    }
                    else if (error.status == 499)
                        this.errorService.cardValidationError();
                    else
                        this.errorService.serverError();
                    return throwError(error);
                }),

            );
        }
    }
}

