<div class="p-3"></div>
<div class="text-center text-danger lead">
  Please use your <b>official Guild Email Address</b> to sign up and to receive
  the corporate plan benefits
</div>

<div class="wrapper">
  <div class="block-center mt-4 wd-xl">
    <!-- START card-->
    <div class="card card-flat">
      <div class="card-header text-center">
        <img style="width: 200px; image-rendering: auto;max-width: 220px !important;" class="block-center rounded eva-logo" src="https://s3.amazonaws.com/eva-bot/img/ad758549-6113-46a8-a146-02680f108288.png" alt="Image">
      </div>
      <div class="card-body">
        <p class="text-center py-2 eva-sub-header">Sign Up With Eva</p>
        <!-- <p class="text-center m-3">
          <a href="https://client.stage.evabot.com/user/googleLogin">
            <img class="google-option-image" src="../../../../assets/img/btn_google_signin_dark_normal_web.png">
          </a>
        </p> -->
        <!-- <p class="dropdown-divider pb-2"></p> -->
        <p class="pb-2"></p>
        <p *ngIf="isInvalid" class="text-center text-danger">
          {{ signUpError }}
        </p>
        <div class="text-danger text-center" *ngIf="isGuildError">
          <p>
            Oops! This is not a Guild Mortgage email. It must be
            xyz@guildmortgage.net or xyz@guildmortgage.com
          </p>
          <!-- <p>
            Not a Guild Mortgage user? Sign Up
            <a [routerLink]="['/signup']" style="text-decoration: underline"
              >here</a
            >
          </p> -->
        </div>
        <form class="mb-3" (ngSubmit)="onSignUp(f)" #f="ngForm">
          <div class="form-group">
            <div class="input-group with-focus">
              <div class="input-group-prepend">
                <span
                  id="icon-useremail"
                  [ngClass]="{ 'input-error': isSubmitted && !name.valid }"
                  class="input-group-text text-muted bg-transparent border-right-0"
                >
                  <em class="fa fa-user"></em>
                </span>
              </div>
              <input
                aria-describedby="icon-useremail"
                [(ngModel)]="userName"
                class="form-control border-left-0"
                type="text"
                placeholder="Enter full name"
                name="name"
                ngModel
                autocomplete="off"
                #name="ngModel"
                [ngClass]="{ 'input-error': isSubmitted && !name.valid }"
                required
              />
            </div>
          </div>
          <div class="form-group">
            <div class="input-group with-focus">
              <div class="input-group-prepend">
                <span
                  [ngClass]="{ 'input-error': isSubmitted && !email.valid }"
                  class="input-group-text text-muted bg-transparent border-right-0"
                >
                  <em class="fa fa-envelope"></em>
                </span>
              </div>
              <input
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,64}$"
                [(ngModel)]="userEmail"
                class="form-control border-left-0"
                type="email"
                placeholder="Enter email"
                name="email"
                ngModel
                oninput="this.value = this.value.toLowerCase()"
                autocomplete="off"
                #email="ngModel"
                [ngClass]="{ 'input-error': isSubmitted && !email.valid }"
                required
                email
              />
            </div>
          </div>
          <div class="form-group">
            <div class="input-group with-focus">
              <div class="input-group-prepend">
                <span
                  [ngClass]="{ 'input-error': isSubmitted && password.invalid }"
                  class="input-group-text text-muted bg-transparent border-right-0"
                >
                  <em class="fa fa-lock"></em>
                </span>
              </div>
              <input
                [type]="keyType"
                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-+=@$#^!%*?()&.<>{}[]|;:''?`~/])[A-Za-z\d-+=@$#^()!%*?&.<>{}[]|;:''?`~/]{8,}$"
                class="form-control border-left-0"
                ngModel
                placeholder="Password"
                name="password"
                #password="ngModel"
                [ngClass]="{ 'input-error': isSubmitted && password.invalid }"
                autocomplete="off"
                required
              />
              <div
                class="input-group-append cursor-pointer"
                (click)="onShowPassword()"
              >
                <span
                  [ngClass]="{ 'input-error': isSubmitted && password.invalid }"
                  class="input-group-text text-muted bg-transparent border-left-0"
                >
                  <i
                    *ngIf="isShowPassword"
                    class="fa fa-eye"
                    aria-hidden="true"
                  ></i>
                  <i
                    *ngIf="!isShowPassword"
                    class="fa fa-eye-slash"
                    aria-hidden="true"
                  ></i>
                </span>
              </div>
            </div>
            <div *ngIf="isSubmitted && password.invalid">
              <div>Password must contain the following:</div>
              <div
                [ngClass]="{
                  'error-message': !lowercaseRegex.test(password.value),
                  'success-message': lowercaseRegex.test(password.value)
                }"
              >
                <span
                  *ngIf="lowercaseRegex.test(password.value)"
                  class="fa fa-check"
                ></span
                ><span
                  *ngIf="!lowercaseRegex.test(password.value)"
                  class="fa fa-times"
                ></span
                >&nbsp;A lowercase letter
              </div>
              <div
                [ngClass]="{
                  'error-message': !uppercaseRegex.test(password.value),
                  'success-message': uppercaseRegex.test(password.value)
                }"
              >
                <span
                  *ngIf="uppercaseRegex.test(password.value)"
                  class="fa fa-check"
                ></span
                ><span
                  *ngIf="!uppercaseRegex.test(password.value)"
                  class="fa fa-times"
                ></span
                >&nbsp;A Capital (uppercase) letter
              </div>
              <div
                [ngClass]="{
                  'error-message': !numberRegex.test(password.value),
                  'success-message': numberRegex.test(password.value)
                }"
              >
                <span
                  *ngIf="numberRegex.test(password.value)"
                  class="fa fa-check"
                ></span
                ><span
                  *ngIf="!numberRegex.test(password.value)"
                  class="fa fa-times"
                ></span
                >&nbsp;A Number
              </div>
              <div
                [ngClass]="{
                  'error-message': !min8Regex.test(password.value),
                  'success-message': min8Regex.test(password.value)
                }"
              >
                <span
                  *ngIf="min8Regex.test(password.value)"
                  class="fa fa-check"
                ></span
                ><span
                  *ngIf="!min8Regex.test(password.value)"
                  class="fa fa-times"
                ></span
                >&nbsp;Minimum 8 characters
              </div>
              <div
                [ngClass]="{
                  'error-message': !specialcharcterRegex.test(password.value),
                  'success-message': specialcharcterRegex.test(password.value)
                }"
              >
                <span
                  *ngIf="specialcharcterRegex.test(password.value)"
                  class="fa fa-check"
                ></span
                ><span
                  *ngIf="!specialcharcterRegex.test(password.value)"
                  class="fa fa-times"
                ></span
                >&nbsp;A special character
              </div>
            </div>
          </div>
          <!-- <div class="form-group">
            <div class="input-group with-focus">
              <input class="form-control border-right-0" ngModel type="password" placeholder="Confirm Password"
                name="cpassword" #cpassword="ngModel"
                [ngClass]="{'input-error':isSubmitted && (password.value!=cpassword.value)}" required>
              <div class="input-group-append">
                <span [ngClass]="{'input-error':isSubmitted && (password.value!=cpassword.value)}"
                  class="input-group-text text-muted bg-transparent border-left-0">
                  <em class="fa fa-lock"></em>
                </span>
              </div>
            </div>
            <span *ngIf="isSubmitted && (password.value!=cpassword.value)" class="error-message">passwords donot
              match</span>

          </div> -->
          <div class="clearfix"></div>
          <button class="btn btn-block btn-danger mt-3" type="submit">
            Create Account
          </button>
        </form>
        <p class="pt-3 text-center">Have an account?</p>
        <a class="btn btn-block btn-secondary" [routerLink]="['/login']"
          >Sign In</a
        >
      </div>
    </div>
    <!-- END card-->

    <div class="p-3 text-center">
      <span>&copy; 2024 - EvaBot Inc. All Rights Reserved.</span>
    </div>
  </div>
</div>
