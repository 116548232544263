import { Injectable } from '@angular/core';

import * as moment from 'moment';

import { User } from '../models/user.model';

import { ApiService } from './api.service';
import { UserService } from './user.service';
//declare var stonlyTrack: any;

@Injectable()
export class StonleyService {
    private currentUser: User;
    constructor(private apiService: ApiService, private userService: UserService) {
        this.userService.currentUser.subscribe(
            (currentUser: User) => {
                this.currentUser = currentUser;
                // this.mailboxConnected(this.currentUser.RefreshToken != "" ? 1 : 0);
                // this.currentUser.PlanName == "free" && stonlyTrack('reviewLinks', []);
                // stonlyTrack('identify', this.currentUser.Email, { userEmail: this.currentUser.Email });
                // stonlyTrack('identify', this.currentUser.Email, { planName: this.currentUser.PlanName });
                // stonlyTrack('identify', this.currentUser.Email, { reviewlinkadded: this.currentUser.Settings.ReviewUrl!="" });
            }
        )
    }

    // setCustomerIdentity() {
    //     stonlyTrack('identify', this.currentUser.Email);
    // }

    // firstGiftSent(totalGiftsSent: number) {
    //     stonlyTrack('identify', this.currentUser.Email, {"firstGiftSentDate":moment(new Date()).format("YYYYMMDD")});
    // }

    // firstPlan() {
    //     stonlyTrack('identify', this.currentUser.Email, {'firstplansubscribedon': moment(new Date()).format("MMM DD, YYYY")});
    // }

    // mailboxConnected(value) {
    //     stonlyTrack('identify', this.currentUser.Email, {'mailboxConnected':value});
    // }

    // isInternationalShipping(value) {
    //     stonlyTrack('identify', this.currentUser.Email, {'isInternationalShipping': value==1?true:false});
    // }

    // reviewFeature(value) {
    //     stonlyTrack('identify', this.currentUser.Email, {'reviewLinks': value.split("$$$")});
    // }

    // isReferralEnabled(value) {
    //     stonlyTrack('identify', this.currentUser.Email, {'referralemail': value});
    // }

    // reviewsCollected(value) {
    //     stonlyTrack('identify', this.currentUser.Email, {'reviewscollected': value});
    // }

    // noOfDaysSinceLastGift(value) {
    //     stonlyTrack('identify', this.currentUser.Email, {'Lastgiftsent': value});
    // }


}