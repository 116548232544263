import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable, forkJoin } from "rxjs";

import { AppConfig } from "../../shared/app.config";


@Injectable()
export class ApiService {
  private apiUrl = AppConfig.HOST;

  constructor(private httpClient: HttpClient) { }

  get(path: string): Observable<any> {
    return this.httpClient.get(`${this.apiUrl}${path}`);
  }

  post(path: string, body: any): Observable<any> {
    return this.httpClient.post(`${this.apiUrl}${path}`, body);
  }

  multipleGet(requestObjects: any): Observable<any[]> {
    let request: any = [];
    requestObjects.forEach(requestUrl => {
      request.push(this.httpClient.get(`${this.apiUrl}${requestUrl}`));
    });
    return forkJoin(request);
  }
  multiplePost(path: string, requestObjects: any): Observable<any[]> {
    let request: any = [];
    requestObjects.forEach(obj => {
      request.push(this.httpClient.post(`${this.apiUrl}${path}`, obj))
    });
    return forkJoin(request);
  }

  async GetData(path: string) {
    let result = await this.httpClient.get<any>(`${this.apiUrl}${path}`).toPromise();
    return result;
  }

  async GetDirectData(path: string) {
    let result = await this.httpClient.get<any>(path).toPromise();
    return result;
  }

  directGet(path: string): Observable<any> {
    return this.httpClient.get(path);
  }

  directPost(path: string, body: object = {}): Observable<any> {
    console.log(body);
    return this.httpClient.post(path, body);
  }
}
