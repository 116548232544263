import { Injectable } from "@angular/core";

import { Observable, BehaviorSubject, observable } from "rxjs";
import { map } from "rxjs/operators";

import { UserStatics } from "../models/user.statics.model";

import { ApiService } from "./api.service";
import { StonleyService } from "./stonley.service";

@Injectable()
export class SettingService {
  private userStaticsSubject = new BehaviorSubject<UserStatics>(
    {} as UserStatics
  );
  public userStatics = this.userStaticsSubject.asObservable();

  constructor(private apiService: ApiService, private stonleyService: StonleyService) {

  }

  getUserCredits(): Observable<any> {
    return this.apiService.post("settings/getUserCredits", {}).pipe(
      map((creditObj: any) => {
        const credits = creditObj.data;
        return credits;
      })
    );
  }

  getBrandingStatics(): Observable<any> {
    return this.apiService.post("stat/getBrandingStatics", {}).pipe(
      map((data: any) => {
        const brandingStatics = data.statics;
        return brandingStatics;
      })
    );
  }

  getHomeStatics(obj): Observable<any> {
    return this.apiService.post("stat/getHomeStatics", obj).pipe(
      map((data: any) => {
        const homeStatics = data.statics[0];
        return homeStatics;
      })
    );
  }

  getShippingStatics(): Observable<any> {
    return this.apiService.post("stat/getFreeShippingStatics", {}).pipe(
      map((data: any) => {
        const shippingStatics = data.statics[0];
        return shippingStatics;
      })
    );
  }



  updateHomeStatics(activity: string): Observable<any> {
    return this.apiService.post("stat/updateHomeStatics/", { activity: activity });
  }

  fetchUserStatics() {
    this.apiService
      .post("stat/getUserStatics", {})
      .pipe(
        map((data: any) => {
          const userStatics = data.statics[0];
          return userStatics;
        })
      )
      .subscribe((userStatics: UserStatics) => {
        this.userStaticsSubject.next(userStatics);
      });
  }

  getUserStatics(): Observable<any> {
    return this.apiService.post("stat/getUserStatics", {})
      .pipe(
        map((data: any) => {
          const userStatics = data.statics[0];
          return userStatics;
        })
      )
  }

  updateReviewUrl(obj: any): Observable<any> {
    return this.apiService.post("settings/updateReviewUrl/", obj).pipe(
      map((data) => {
        // this.stonleyService.reviewFeature(obj.reviewUrl);
        return data;
      })
    );
  }

  getBrandingSpecifications(): Observable<any> {
    return this.apiService.post("branding/getBrandingSpecifications", {}).pipe(
      map(
        (data: any) => {
          const specifications = data.specifications[0];
          return specifications;
        }
      )
    );
  }

  updateBrandingSpecifications(obj: any): Observable<any> {
    return this.apiService.post("branding/updateBrandingSpecifications/", obj);
  }

  updateBrandingSpecificationStatus(obj: any): Observable<any> {
    return this.apiService.post("branding/updateBrandingSpecificationStatus/", obj);
  }


  updateGiftSelectionStatus(obj: any): Observable<any> {
    return this.apiService.post("settings/updateGiftSelectionStatus/", obj).pipe(
      map((data) => {
        return data;
      })
    );
  }

  updateGiftShippingStatus(obj: any): Observable<any> {
    return this.apiService.post("settings/updateGiftShippingStatus/", obj).pipe(
      map((data) => {
        return data;
      })
    );
  }

  updateClosingAutomation(obj: any): Observable<any> {
    return this.apiService.post("settings/updateClosingAutomation/", obj).pipe(
      map((data) => {
        return data;
      })
    );
  }

  enableRefferal(obj: object) {
    return this.apiService.post("settings/enableReferral", obj);
  }

  enableInternationalShipping(obj: object) {
    return this.apiService.post("settings/enableInternationalShipping", obj);
  }
}
