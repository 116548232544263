import { Component, OnInit, OnDestroy, Inject, PLATFORM_ID } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";

import { Md5 } from "ts-md5/dist/md5";

import { AppConstants } from '../../../shared/app.constant';
import { Helper } from "../../../shared/helper";
import { User } from '../../../core/models/user.model';
import { UserService } from '../../../core/services/user.service';
declare var require: any
const Swal = require("sweetalert2");

@Component({
  selector: 'app-set-up-password',
  templateUrl: './set-up-password.component.html',
  styleUrls: ['./set-up-password.component.css']
})
export class SetUpPasswordComponent implements OnInit, OnDestroy {
  public isSubmitted: boolean = false;
  public isInvalid: boolean = false;
  private userSubscription: any
  public isView: boolean = false;
  public isShowPassword: boolean = false;
  public keyType: string = "password";
  public userEmail: string = "";
  public userName: string = "";
  public isMobile = Helper.isMobile();
  public errorMessage: string = "";
  public lowercaseRegex: RegExp = /[a-z]/;
  public uppercaseRegex: RegExp = /[A-Z]/;
  public numberRegex: RegExp = /\d/;
  public specialcharcterRegex: RegExp = /\W/;
  public min8Regex: RegExp = /.{8,}/;
  public hashId: string = "";
  public isLinkExpired: boolean = false;
  public type: string = "";


  constructor( private router:Router, private activatedRoute: ActivatedRoute, private userService: UserService) {
    activatedRoute.queryParams.subscribe(
      (params: Params) => {
        this.hashId = params["hashId"];
        this.type = params["type"];
        this.userService.validateForgotPasswordPage({ hashId: this.hashId }).subscribe(
          (data) => {
            console.log("data", data);
            this.userEmail = data.email;
            this.userName = data.name;
            this.isLinkExpired = data.isActive == 0;
          }
        )
      }
    )
  }

  ngOnInit() {
    
  }


  onSignUp(signInForm: NgForm) {
    this.isSubmitted = true;
    this.isInvalid = false;
    if (signInForm.valid && signInForm.value.password == signInForm.value.cpassword) {
      const password = Md5.hashStr(signInForm.value.password);
      const credentials = { hashId: this.hashId, password: password, type: this.type ? 1 : 0 };
      this.userService.recoverPassword(credentials).subscribe(
        (data) => {
          Swal.fire({
            icon: "success",
            type: "success",
            title:
              "<p style='font-size:22x;font-weight:400 !important'>Password for your account is successfully set</p>",
            confirmButtonColor: AppConstants.CONFIRM_BUTTON_COLOR,
            confirmButtonText:"Login to Eva",
            allowOutsideClick: false,
            allowEscapeKey: false,
          }).then((result: any) => {
            if (result.value) {
              this.router.navigate(["/login"]);
            }
          });
        }
      )
    }
  }

  onShowPassword() {
    this.isShowPassword ? this.keyType = "password" : this.keyType = "text";
    this.isShowPassword = !this.isShowPassword;
  }

  ngOnDestroy() {
    if (this.userSubscription)
      this.userSubscription.unsubscribe();
  }
}
