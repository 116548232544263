import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { Md5 } from 'ts-md5';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Helper } from '../../../../shared/helper';
import { User } from '../../../../core/models/user.model';
import { GiftService } from '../../../../core/services/gift.service';
import { UserService } from '../../../../core/services/user.service';

declare var pintrk: any;
declare var gtag: any;
@Component({
  selector: 'app-demo-handwritten',
  templateUrl: './demo-handwritten.component.html',
  styleUrls: ['./demo-handwritten.component.css'],
  providers: [GiftService]
})
export class DemoHandwrittenComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  public isSubmittedGeneral: boolean = false;
  public isSubmittedSignUp: boolean = false;
  public isSubmittedSignIn: boolean = false;
  public isSignUpInvalid: boolean = false;
  public isSignInInvalid: boolean = false;
  public customerName: string = "";
  public customerEmail: string = "";
  public userName: string = "";
  public userEmail: string = "";
  private demoEmail: string = "";
  private pEventName: string = ""
  private mode: string = "";
  public isMobile: boolean = Helper.isMobile();
  public totalSlides: number = this.isMobile ? 1 : 2;
  public images = ["../../../../assets/img/branding/card-6.png", "../../../../assets/img/branding/card-2.png",
    "../../../../assets/img/branding/card-1.png", "../../../../assets/img/branding/card-3.png",
    "../../../../assets/img/branding/card-4.png", "../../../../assets/img/branding/card-5.png"]

  public images2 = ["../../../../assets/img/branding/card-6.png", "../../../../assets/img/branding/card-5.png"]

  constructor(private userService: UserService, private router: Router, private activatedRoute: ActivatedRoute, private giftService: GiftService) { }

  ngOnInit() {
    localStorage.setItem("dmPlan", "1");
    this.activatedRoute.queryParams.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (params: Params) => {
        if (params["p"])
          this.pEventName = params["p"];
        if (params["mode"])
          this.mode = params["mode"];
        if (params["email"])
          this.demoEmail = params["email"];
      }
    )

    $(document).ready(function () {

      $("#step-1").fadeIn(1000);
      $('#sign-in-name').keypress(function () {
        var textLength = 0;  //$(this).val().length;
        if (textLength < 16) {
          // Do noting
        } else if (textLength < 30) {
          $(this).css('font-size', '95%');
        } else if (textLength > 30 && textLength < 40) {
          $(this).css('font-size', '87%');
        } else if (textLength > 40 && textLength < 50) {
          $(this).css('font-size', '80%');
        } else if (textLength > 50) {
          $(this).css('font-size', '70%');
        }
      });

      $('#sign-in-email').keypress(function () {
        var textLength = 0; //$(this).val().length;
        if (textLength < 16) {
          // Do noting
        } else if (textLength < 30) {
          $(this).css('font-size', '95%');
        } else if (textLength > 30 && textLength < 40) {
          $(this).css('font-size', '87%');
        } else if (textLength > 40 && textLength < 50) {
          $(this).css('font-size', '80%');
        } else if (textLength > 50) {
          $(this).css('font-size', '70%');
        }
      });

      $('#sign-in-password').keypress(function () {
        var textLength = 0; //$(this).val().length;
        if (textLength < 16) {
          // Do noting
        } else if (textLength < 30) {
          $(this).css('font-size', '95%');
        } else if (textLength > 30 && textLength < 40) {
          $(this).css('font-size', '87%');
        } else if (textLength > 40 && textLength < 50) {
          $(this).css('font-size', '80%');
        } else if (textLength > 50) {
          $(this).css('font-size', '70%');
        }
      });

      $("#sign_up_not_interested").click(function () {
        if ($(this).is(":checked")) {
          $("#sign_up_interested").prop("checked", false);
        } else {
          $("#sign_up_not_interested").prop("checked", true);
        }
      });

      $("#sign_up_interested").click(function () {
        if ($(this).is(":checked")) {
          $("#sign_up_not_interested").prop("checked", false);
        } else {
          $("#sign_up_interested").prop("checked", true);
        }
      });

      $("#sign_in_not_interested").click(function () {
        if ($(this).is(":checked")) {
          $("#sign_in_interested").prop("checked", false);
        } else {
          $("#sign_in_not_interested").prop("checked", true);
        }
      });

      $("#sign_in_interested").click(function () {
        if ($(this).is(":checked")) {
          $("#sign_in_not_interested").prop("checked", false);
        } else {
          $("#sign_in_interested").prop("checked", true);
        }
      });

      $(document).ready(function () {

        var customerName = ''
        var customerEmail = ''
        var giftLink = '';

        $("#step-1-button").click(function () {
          if (!$('#customer-name').val() || !$('#customer-email').val()) {
            $("#no-input-warning").show();
            $("#short-password-warning").hide();
            $("#email-invalid-warning").hide();
            setTimeout(
              function () {
                $("#no-input-warning").hide();
              }, 2000);

          } else {

            if (!$("#customer-email").val()) { // if (!$("#customer-email").val().match(re)) {
              $("#no-input-warning").hide();
              $("#short-password-warning").hide();
              $("#email-invalid-warning").show();
              setTimeout(
                function () {
                  $("#email-invalid-warning").hide();
                }, 2000);
            } else {

              //   clevertap.event.push("Website Demo");
              // clevertap.event.push("Demo Started");

              // var p = utility.getParameterByName("p");
              // if (p != undefined && p != "") {
              //   console.log("event:", p);
              //  // clevertap.event.push(p + "");
              // }

              $("#sign-in-name").val(customerName);
              $("#sign-in-email").val(customerEmail);
              $("#no-input-warning").hide();
              $("#short-password-warning").hide();
              $("#email-invalid-warning").hide();
              $("#step-1").hide();
              window.scrollTo(0, 0);
              $("#step-2").fadeIn(1000);
              $("#step-count").text('2');
            }
          }
        });

        $("#step-4-yes").click(function () {
          $("#step-4").hide();
          window.scrollTo(0, 0);
          $("#step-5").fadeIn(1000);
          $(".card-bottom-section").fadeIn(1000);
          $("#step-count").text('4');
          $("#skip-demo").hide();

          var textLength = 0; // $('#sign-in-name').val().length;
          if (textLength < 16) {
            // Do noting
          } else if (textLength < 30) {
            $('#sign-in-email').css('font-size', '95%');
          } else if (textLength > 30 && textLength < 40) {
            $('#sign-in-email').css('font-size', '87%');
          } else if (textLength > 40 && textLength < 50) {
            $('#sign-in-email').css('font-size', '80%');
          } else if (textLength > 50) {
            $('#sign-in-email').css('font-size', '70%');
          }

          var textLength = 0; //$('#sign-in-email').val().length;
          if (textLength < 16) {
            // Do noting
          } else if (textLength < 30) {
            $('#sign-in-email').css('font-size', '95%');
          } else if (textLength > 30 && textLength < 40) {
            $('#sign-in-email').css('font-size', '87%');
          } else if (textLength > 40 && textLength < 50) {
            $('#sign-in-email').css('font-size', '80%');
          } else if (textLength > 50) {
            $('#sign-in-email').css('font-size', '70%');
          }
        });

        $("#step-4-no").click(function () {
          $("#step-4").hide();
          window.scrollTo(0, 0);
          $("#step-5").fadeIn(1000);
          $(".card-bottom-section").fadeIn(1000);
          $("#step-count").text('5');
          $("#skip-demo").hide();

          var textLength = 0; //$('#sign-in-name').val().length;
          if (textLength < 16) {
            // Do noting
          } else if (textLength < 30) {
            $('#sign-in-email').css('font-size', '95%');
          } else if (textLength > 30 && textLength < 40) {
            $('#sign-in-email').css('font-size', '87%');
          } else if (textLength > 40 && textLength < 50) {
            $('#sign-in-email').css('font-size', '80%');
          } else if (textLength > 50) {
            $('#sign-in-email').css('font-size', '70%');
          }

          var textLength = 0; //$('#sign-in-email').val().length;
          if (textLength < 16) {
            // Do noting
          } else if (textLength < 30) {
            $('#sign-in-email').css('font-size', '95%');
          } else if (textLength > 30 && textLength < 40) {
            $('#sign-in-email').css('font-size', '87%');
          } else if (textLength > 40 && textLength < 50) {
            $('#sign-in-email').css('font-size', '80%');
          } else if (textLength > 50) {
            $('#sign-in-email').css('font-size', '70%');
          }
        });

        $("#step-4-back").click(function () {
          $("#step-4").hide();
          window.scrollTo(0, 0);
          if (giftLink != "") {
            $("#step-2").fadeIn(1000);
            $("#step-count").text('2');
          } else {
            $("#step-1").fadeIn(1000);
            $("#step-count").text('1');
          }
        });

        $(".step-5-back").click(function () {
          $("#step-4").hide();
          $("#step-5").hide();
          $("#step-count").text('4');
          $('.sign-in-footer').hide();
          $("#skip-demo").show();
          if (giftLink != "") {
            $("#step-4").fadeIn(1000);
            $("#step-count").text('4');
          } else {
            $("#step-1").fadeIn(1000);
            $("#step-count").text('1');
          }
        });

        $("#step-5-button").click(function () {
          if (!$('#sign-in-name').val() || !$('#sign-in-email').val() || !$(
            '#sign-in-password').val()) {
            $("#no-input-warning-signup").show();
            $("#short-password-warning-signup").hide();
            $("#email-invalid-warning-signup").hide();
            setTimeout(
              function () {
                $("#no-input-warning-signup").hide();
              }, 2000);
          }
          // else if (!$("#sign-in-email").val().match(re)) {
          //   $("#no-input-warning-signup").hide();
          //   $("#short-password-warning-signup").hide();
          //   $("#email-invalid-warning-signup").show();
          //   $("#email-invalid-warning-signup").text("Invalid email!");
          //   setTimeout(
          //     function () {
          //       $("#email-invalid-warning-signup").hide();
          //     }, 2000);
          // } 
          // else if ($('#sign-in-password').val().trim().length < 6) {
          //   $("#no-input-warning-signup").hide();
          //   $("#short-password-warning-signup").show();
          //   $("#email-invalid-warning-signup").hide();
          //   setTimeout(function () {
          //     $("#short-password-warning-signup").hide();
          //   }, 2000);
          // }

          else {
            $.post('/signupDemo', {
              FullName: $('#sign-in-name').val(),
              Email: $('#sign-in-email').val(),
              Password: $('#sign-in-password').val(),
              Company: $('#sign-in-company').val()
            }, function (result) {
              if (result.error) {
                $("#email-invalid-warning-signup").show();
                $("#email-invalid-warning-signup").text(
                  "Your account already exists.")
                setTimeout(function () {
                  $("#email-invalid-warning-signup").hide();
                }, 2000);
              } else {
                //  var mode = utility.getParameterByName("mode");
                // if (mode != undefined && mode != "") {
                //   clevertap.event.push("Unbounce Demo");
                //   mode = "&mode=ad";
                // } else
                //   clevertap.event.push("Website Demo");

                //fbq("track", "CompleteRegistration");

                console.log(result);
              }
            });
          }
        })

        $("#step-5-1-button").click(function () {
          if (!$('#login-in-email').val() || !$('#login-in-password').val()) {
            $("#no-input-warning-login").show();
            $("#short-password-warning-login").hide();
            $("#email-invalid-warning-login").hide();
            setTimeout(
              function () {
                $("#no-input-warning-login").hide();
              }, 2000);
          }
          else {
            $.post('/user/login', {
              email: $('#login-in-email').val(),
              password: $('#login-in-password').val()
            }, function (result) {
              if (result.data.Message) {
                $("#email-invalid-warning-login").show();
                $("#email-invalid-warning-login").text(
                  "Invalid email or password")
                setTimeout(function () {
                  $("#email-invalid-warning-login").hide();
                }, 2000);
              } else {
              }
            });
          }
        });

        $("#log-in").click(function () {
          $("#step-5").hide();
          $(".card-bottom-section").hide();
          $(".card-bottom-section1").show();
          $("#step-5-1").fadeIn(1000);
        });

        $("#sign-up").click(function () {
          $("#step-5-1").hide();
          $(".card-bottom-section").show();
          $(".card-bottom-section1").hide();
          $("#step-5").fadeIn(1000);
        });
      });
    });

  }

  onStartDemo() {
    $("#step-1").hide();
    $("#step-2").show();
    $("#step-count").text('2');
    setTimeout(() => {
      gtag('event', 'conversion', { 'send_to': 'AW-822217819/VE4WCPKwgbcBENuYiIgD' });
    }, 3000);

    pintrk("load", "2617844340670", {
      em: this.demoEmail
    });
    pintrk('track', 'custom', {
      property: 'Start Demo'
    });
    const demoObj = {
      name: "",
      email: this.demoEmail,
      message: "send gift",
      subject: this.pEventName == "" ? "+" : this.pEventName,
      price: "32",
      priceId: "63",
      isDemo: "1",
    }
    this.giftService.sendDemoGift(demoObj).subscribe(
      (data) => { }
    )
    this.userEmail = this.demoEmail;
  }

  onSkipDemo() {
    this.onNextStep(5, 4);
  }

  onNextStep(nextStep: number, label: number) {
    let currentStep = $("#step-count").text() == "3" ? "4" : $("#step-count").text();
    $("#step-" + currentStep).hide();
    $("#step-" + nextStep).fadeIn(1000);
    $("#step-count").text(label);
    if (nextStep == 5)
      $(".skip-demo-text").hide();

  }

  onSignUp(signUpForm: NgForm) {
    this.isSubmittedSignUp = true;
    if (signUpForm.valid) {
      let obj = {
        name: signUpForm.value.name,
        email: signUpForm.value.email,
        password: Md5.hashStr(signUpForm.value.password),
        company: signUpForm.value.company,
        inviteCode: "",
        orderHash: "",
      }

      this.userService.registerUser(obj).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
        (data) => {
          if (data.Message) {
            this.isSubmittedSignUp = false;
            this.isSignUpInvalid = true;
          }
          else {
            this.userService.populate();
            this.userService.currentUser.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
              (user: User) => {
                if (user.TokenNumber) {
                  this.router.navigate(["/buyDirectMailers"]);
                }
              }
            )
          }
        },
      );
    }

  }

  onSignIn(signInForm: NgForm) {
    this.isSubmittedSignIn = true;
    if (signInForm.valid) {
      const email = signInForm.value.email;
      const password = Md5.hashStr(signInForm.value.password);
      const credentials = { email: email, password: password, isSession: false };
      this.userService.attemptAuth(credentials).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
        (user: User) => {
          var route = [];
          if (user.IsSetUp == 0) {
            route = [localStorage.getItem("ob"), "step1"];
          }
          else {
            if (localStorage.getItem("navigationUrl"))
              route = localStorage.getItem("navigationUrl").split("||");
            else
              route = ["/"]
          }

          localStorage.removeItem("navigationUrl");
          if (localStorage.getItem("queryParams")) {
            let params = localStorage.getItem("queryParams");
            localStorage.removeItem("queryParams");
            let queryParams = JSON.parse('{"' + decodeURI(params).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}'
            )
            this.router.navigate(route, { queryParams: queryParams });
          }
          else {
            this.router.navigate(route);
          }
        },
        error => {
          console.log(error);
          this.isSubmittedSignIn = false;
          this.isSignInInvalid = true;
        }
      );
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }
}

