import { Injectable } from '@angular/core';
import { AppConstants } from '../../shared/app.constant';
import { Router } from '@angular/router';
declare var require: any
const Swal = require("sweetalert2");

@Injectable()
export class ErrorService {
    constructor(private router: Router) { }

    authenticationError() {
       this.router.navigate(["/logout"]);
        // Swal.fire({
        //     title: 'Session expired',
        //     type: 'error',
        //     focusConfirm: true,
        //     confirmButtonText: 'Login',
        //     confirmButtonColor: AppConstants.CONFIRM_BUTTON_COLOR
        // }).then((result) => {
        //     this.router.navigate(["/login"]);
        // })
    }

    serverError() {

        Swal.fire({
            type: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
            confirmButtonColor: AppConstants.CONFIRM_BUTTON_COLOR
        })
    }

    cardValidationError() {
        Swal.fire({
            type: 'error',
            title: 'Oops...',
            text: 'Card validation failed!',
            confirmButtonColor: AppConstants.CONFIRM_BUTTON_COLOR
        })
    }

}