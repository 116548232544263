import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { Helper } from '../shared/helper';
import { menu, menuBDAP, menuHolidayGiftingPlan, menuHolidayGiftingPlanNOPayment, menuHolidayGiftingPlanUTL } from '../core/models/menu.model';
import { User } from '../core/models/user.model';
import { AddressService } from '../core/services/address.service';
import { CardService } from '../core/services/card.service';
import { EmailTemplateService } from '../core/services/email.template.service';
import { MenuService } from '../core/services/menu.service';
import { PriceService } from '../core/services/price.service';
import { SettingService } from '../core/services/settings.service';
import { UserService } from '../core/services/user.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css'],
  providers: [SettingService]
})
export class LayoutComponent implements OnInit, OnDestroy {
  public isAuthenticated: boolean;
  public isSetUp: number = 0;
  private userSubscription: any = null;
  private isDataLoaded: boolean = false;
  public currentPath: string = "";

  constructor(private userService: UserService,
    private priceService: PriceService,
    private cardService: CardService,
    private addressService: AddressService,
    private emailTemplateService: EmailTemplateService,
    private settingService: SettingService,
    private router: Router,
    private menuService: MenuService
  ) {
    this.isDataLoaded = false;
  }

  ngOnInit() {
    this.currentPath = this.router.url;
    this.userSubscription = this.userService.isAuthenticated.subscribe(
      (isAuthenticated: boolean) => {
        this.isAuthenticated = isAuthenticated;
        if (isAuthenticated) {

          if (!this.isDataLoaded) {
            this.isDataLoaded = true;
            this.priceService.fetchPriceList();
            this.cardService.fetchCardList();
            // this.addressService.fetchAddressList();
            this.emailTemplateService.fetchEmailTemplateList();
            this.settingService.fetchUserStatics();
            this.userService.currentUser.subscribe(
              (currentUser: User) => {
                
                if (currentUser.TokenNumber) {
                  if (currentUser.HomeActivity.includes("[hide_payment_method]")) {
                    this.menuService.addMenu(menuHolidayGiftingPlanNOPayment,currentUser);
                  }
                  else if (currentUser.HomeActivity.includes("[holiday_plan_signup]")) {
                     currentUser.subscription.IsUtl ? this.menuService.addMenu(menuHolidayGiftingPlanUTL,currentUser)
                     :this.menuService.addMenu(menuHolidayGiftingPlan,currentUser);
                  }
                  else if (currentUser.HomeActivity.indexOf("[bdap]") != -1) {
                    this.menuService.addMenu(menuBDAP,currentUser);
                  }
                  else
                    this.menuService.addMenu(menu,currentUser);

                }
              });
          }

        } else {
          if (this.router.url.toLowerCase() != "/exploregifts" && this.router.url.toLowerCase().indexOf("/exploretags") == -1 && this.router.url.toLowerCase().indexOf("/faq") == -1 && this.router.url.toLowerCase().indexOf("/obv2") == -1 &&
            this.router.url.toLowerCase().indexOf("/verifyemail") == -1 && this.router.url.toLowerCase().indexOf("/redirectsso") == -1 && this.router.url.toLowerCase().indexOf("/interospecialoffer") == -1 && this.router.url.toLowerCase().indexOf("/giftoptions") == -1 && this.router.url.toLowerCase().indexOf("/redirectgiftplatform") == -1 && this.router.url.toLowerCase().indexOf("/giftplatform") == -1) {
            this.router.navigate(["/login"]);
          }
        }
      }
    );

    this.userSubscription = this.userService.currentUser.subscribe(
      (currentUser: User) => {
        this.isSetUp = currentUser.IsSetUp;
      }
    )
  }



  ngOnDestroy() {
    if (this.userSubscription)
      this.userSubscription.unsubscribe();
  }

}
