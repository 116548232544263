// const Option5 = {
//     TotalBoxes: 5,
//     TotalCards: 5,
//     PaymentInfo: "$65 for 5 branded boxes and 5 branded postcards,",
//     Price: 65,
//     BoxType: "z6MQgoby",
//     PostCardType: "z6MQgoby",
//     BoxId: "lVMDeBJd",
//     PostCardId: "ZbMkrXLw",
//     HashId: "5poxmX98",
//     CashHashId: "5poxmX98",
//     CashBack: 0,
//     BrandingInfo: "5 Branded Boxes & Postcards (Trial offer)",
//     PerBox: 13
// };

const Option500 = {
    TotalBoxes: 500,
    TotalCards: 500,
    PaymentInfo: "$5000 for 500 branded boxes and 500 branded postcards,",
    Price: 5000,    
    BoxType: "5poxNbM9",
    PostCardType: "5poxNbM9",
    BoxId: "poxj4dM9",      
    PostCardId: "5pox1WX9",
    HashId: "5poxmX98",
    CashHashId: "5poxmX98",
    CashBack: 0,
    BrandingInfo: "500 Branded Boxes & Postcards",
    PerBox: 10
};

const Option250 = {
    TotalBoxes: 250,
    TotalCards: 250,
    PaymentInfo: "$3000 for 250 branded boxes and 250 branded postcards,",
    Price: 3000,    
    BoxType: "lVMDbjod",
    PostCardType: "lVMDbjod",
    BoxId: "poxjjqM9",      
    PostCardId: "5poxQ0X9",
    HashId: "5poxmX98",
    CashHashId: "5poxmX98",
    CashBack: 0,
    BrandingInfo: "250 Branded Boxes & Postcards",
    PerBox: 12
};

const Option100 = {
    TotalBoxes: 100,
    TotalCards: 100,
    PaymentInfo: "$1300 for 100 branded boxes and 100 branded postcards,",
    Price: 1300,
    BoxType: "5poxAyX9",
    PostCardType: "5poxAyX9",
    BoxId: "5poxqqX9",
    PostCardId: "5poxKWJ9",
    HashId: "5poxmX98",
    CashHashId: "5poxmX98",
    CashBack: 0,
    BrandingInfo: "100 Branded Boxes & Postcards (Recommended)",
    PerBox: 13
};

const Option50 = {
    TotalBoxes: 50,
    TotalCards: 50,
    PaymentInfo: "$750 for 50 branded boxes and 50 branded postcards,",
    Price: 750,
    BoxType: "lVMDeBJd",
    PostCardType: "lVMDeBJd",
    BoxId: "5poxNbM9",
    PostCardId: "lVMDbjod",
    HashId: "5poxmX98",
    CashHashId: "5poxmX98",
    CashBack: 0,
    BrandingInfo: "50 Branded Boxes & Postcards (Popular)",
    PerBox: 15
};

const Option25 = {
    TotalBoxes: 25,
    TotalCards: 25,
    PaymentInfo: "$500 for 25 branded boxes and 25 branded postcards,",
    Price: 500,
    BoxType: "nbogNJ1A",
    PostCardType: "nbogNJ1A",
    BoxId: "lVMDkAXd",
    PostCardId: "lVMDNaod",
    HashId: "5poxmX98",
    CashHashId: "5poxmX98",
    CashBack: 0,
    BrandingInfo: "25 Branded Boxes & Postcards",
    PerBox: 20
};

const Option10 = {
    TotalBoxes: 10,
    TotalCards: 10,
    PaymentInfo: "$250 for 10 branded boxes and 10 branded postcards,",
    Price: 250,
    BoxType: "y6J9pMND",
    PostCardType: "y6J9pMND",
    BoxId: "5poxWdJ9",
    PostCardId: "lVMDeBJd",
    HashId: "5poxmX98",
    CashHashId: "5poxmX98",
    CashBack: 0,
    BrandingInfo: "10 Branded Boxes & Postcards",
    PerBox: 25
};
export const BrandingOptions = [Option500,Option250, Option100, Option50, Option25] // small boxes on branding page


const OptionD250 = {
    TotalBoxes: 250,
    TotalCards: 250,
    PaymentInfo: "$2000 for 250 branded boxes and 250 branded postcards,",
    Price: (2000 - (2000*5/100)).toFixed(2),
    BoxType: "lVMDbjod",
    PostCardType: "lVMDbjod",
    BoxId: "5pox1WX9",      
    PostCardId: "5poxNbM9",
    HashId: "5poxmX98",
    CashHashId: "5poxAyX9",
    CashBack: 2000,
    BrandingInfo: "250 Branded Boxes & Postcards",
    PerBox: 8
};

const OptionD100 = {
    TotalBoxes: 100,
    TotalCards: 100,
    PaymentInfo: "$900 for 100 branded boxes and 100 branded postcards,",
    Price: (900-(900*5/100)).toFixed(2),
    BoxType: "5poxAyX9",
    PostCardType: "5poxAyX9",
    BoxId: "5pox25M9",
    PostCardId: "5pox9nX9",
    HashId: "5poxmX98",
    CashHashId: "PEJ6ddX4",
    CashBack: 900,
    BrandingInfo: "100 Branded Boxes & Postcards (Recommended)",
    PerBox: 9
};

const OptionD50 = {
    TotalBoxes: 50,
    TotalCards: 50,
    PaymentInfo: "$500 for 50 branded boxes and 50 branded postcards,",
    Price: (500-(500*5/100)).toFixed(2),
    BoxType: "lVMDeBJd",
    PostCardType: "lVMDeBJd",
    BoxId: "lVMDkAXd",
    PostCardId: "lVMDNaod",
    HashId: "5poxmX98",
    CashHashId: "nbogNJ1A",
    CashBack: 500,
    BrandingInfo: "50 Branded Boxes & Postcards (Popular)",
    PerBox: 10
};

const OptionD25 = {
    TotalBoxes: 25,
    TotalCards: 25,
    PaymentInfo: "$340 for 25 branded boxes and 25 branded postcards,",
    Price: (340-(340*5/100)).toFixed(2),
    BoxType: "nbogNJ1A",
    PostCardType: "nbogNJ1A",
    BoxId: "5poxWdJ9",
    PostCardId: "G7o07aJl",
    HashId: "5poxmX98",
    CashHashId: "EgXdkopj",
    CashBack: 340,
    BrandingInfo: "25 Branded Boxes & Postcards",
    PerBox: 13.60
};


export const BrandingDOptions = [OptionD250,OptionD100, OptionD50, OptionD25]



const bigOption500 = {
    TotalBoxes: 500,
    TotalCards: 500,
    PaymentInfo: "$8500 for 250 branded boxes and 250 branded postcards,",
    Price: 8500,
    BoxType: "5poxNbM9",
    PostCardType: "5poxNbM9",
    BoxId: "poxpWbo9",
    PostCardId: "poxjynM9",
    HashId: "5poxmX98",
    CashHashId: "5poxmX98",
    CashBack: 0,
    BrandingInfo: "500 Branded Boxes & Postcards",
    PerBox: 17
};

const bigOption250 = {
    TotalBoxes: 250,
    TotalCards: 250,
    PaymentInfo: "$5250 for 250 branded boxes and 250 branded postcards,",
    Price: 5250,
    BoxType: "lVMDbjod",
    PostCardType: "lVMDbjod",
    BoxId: "poxAArX9",
    PostCardId: "lVMD0aMd",
    HashId: "5poxmX98",
    CashHashId: "5poxmX98",
    CashBack: 0,
    BrandingInfo: "250 Branded Boxes & Postcards",
    PerBox: 21
};

const bigOption100 = {
    TotalBoxes: 100,
    TotalCards: 100,
    PaymentInfo: "$2200 for 100 branded boxes and 100 branded postcards,",
    Price: 2200,
    BoxType: "5poxAyX9",
    PostCardType: "5poxAyX9",
    BoxId: "5poxnbM9",
    PostCardId: "5pox25M9",
    HashId: "5poxmX98",
    CashHashId: "5poxmX98",
    CashBack: 0,
    BrandingInfo: "100 Branded Boxes & Postcards (Recommended)",
    PerBox: 22
};

const bigOption50 = {
    TotalBoxes: 50,
    TotalCards: 50,
    PaymentInfo: "$1250 for 50 branded boxes and 50 branded postcards,",
    Price: 1250,
    BoxType: "lVMDeBJd",
    PostCardType: "lVMDeBJd",
    BoxId: "lVMDd9Md",
    PostCardId: "5poxKWJ9",
    HashId: "5poxmX98",
    CashHashId: "5poxmX98",
    CashBack: 0,
    BrandingInfo: "50 Branded Boxes & Postcards (Popular)",
    PerBox: 25
};

// const bigOption25 = {
//     TotalBoxes: 25,
//     TotalCards: 25,
//     PaymentInfo: "$300 for 25 branded boxes and 25 branded postcards,",
//     Price: 300,
//     BoxType: "nbogNJ1A",
//     PostCardType: "nbogNJ1A",
//     BoxId: "lVMDbjod",
//     PostCardId: "lVMDeBJd",
//     HashId: "5poxmX98",
//     CashHashId: "5poxmX98",
//     CashBack: 0,
//     BrandingInfo: "25 Branded Boxes & Postcards",
//     PerBox: 12
// };

export const BigBrandingOptions = [bigOption500,bigOption250, bigOption100, bigOption50]


const bigOptionD250 = {
    TotalBoxes: 250,
    TotalCards: 250,
    PaymentInfo: "$2565 for 250 branded boxes and 250 branded postcards,",
    Price: 2565,
    BoxType: "lVMDbjod",
    PostCardType: "lVMDbjod",
    BoxId: "poxjjqM9",
    PostCardId: "5poxarX9",
    HashId: "5poxmX98",
    CashHashId: "ZzoExxJl",
    CashBack: 2700,
    BrandingInfo: "250 Branded Boxes & Postcards",
    PerBox: 10
};

const bigOptionD100 = {
    TotalBoxes: 100,
    TotalCards: 100,
    PaymentInfo: "$1235 for 100 branded boxes and 100 branded postcards,",
    Price: 1235,
    BoxType: "5poxAyX9",
    PostCardType: "5poxAyX9",
    BoxId: "5poxqqX9",
    PostCardId: "5poxKWJ9",
    HashId: "5poxmX98",
    CashHashId: "zkJVN2JA",
    CashBack: 1300,
    BrandingInfo: "100 Branded Boxes & Postcards (Recommended)",
    PerBox: 12
};

const bigOptionD50 = {
    TotalBoxes: 50,
    TotalCards: 50,
    PaymentInfo: "$712.5 for 50 branded boxes and 50 branded postcards,",
    Price: 712.5,
    BoxType: "lVMDeBJd",
    PostCardType: "lVMDeBJd",
    BoxId: "5poxNbM9",
    PostCardId: "lVMDbjod",
    HashId: "5poxmX98",
    CashHashId: "O6o3BJpL",
    CashBack: 750,
    BrandingInfo: "50 Branded Boxes & Postcards (Popular)",
    PerBox: 13
};

export const BigBrandingDOptions = [bigOptionD250, bigOptionD100, bigOptionD50]