<div class="wrapper" style="background:white">
    <div class="block-center mt-4 card-panel">
        <!-- START card-->
        <div class="card card-flat">
            <div class="card-header text-center">
                <a href="#">
                    <img style="width: 65px; image-rendering: auto;max-width: 65px !important;" class="block-center rounded eva-logo" src="../../../../assets/img/eva-logo.png" alt="Image">
                </a>
            </div>
            <div class="card-body">
                <p style="font-size: 22px;" class="py-2 eva-sub-header">Sign In with Single Sign-On (SSO)</p>
                <p class="dropdown-divider pb-4"></p>
                <p style="font-size: 16px;" *ngIf="isInvalid" class="text-center text-danger">{{signUpError}}</p>
                <form class="mb-3" (ngSubmit)="onValidateDomain(f)" #f="ngForm">
                    <div class="form-group">
                        <label style="font-size: 16px;">Enter your organization's domain</label>
                        <div class="input-group with-focus">
                            <div class="input-group-prepend">
                                <span [ngClass]="{'input-error':isSubmitted&& !domain.valid}" class="input-group-text text-muted bg-transparent border-right-0">
                  <em class="fa fa-server"></em>
                </span>
                            </div>
                            <input [(ngModel)]="userDomain" class="form-control border-left-0" type="text" placeholder="Enter your domain" name="domain" ngModel oninput="this.value = this.value.toLowerCase()" autocomplete="off" #domain="ngModel" [ngClass]="{'input-error':isSubmitted&& !domain.valid}"
                                required>

                        </div>
                    </div>
                    <div class="clearfix">
                    </div>
                    <button style="font-size: 16px;" class="btn btn-block btn-danger mt-3" type="submit">Continue</button>
                </form>
                <div class="text-center">
                    <a class="skip-link" [routerLink]="['/login']">Return to Sign In</a>
                </div>
            </div>
        </div>
        <!-- END card-->
        <div class="p-3 text-center">
            <span>&copy; 2024 - EvaBot Inc. All Rights Reserved.</span>
        </div>
    </div>
</div>