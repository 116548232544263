import { Injectable } from "@angular/core";

import { map } from "rxjs/operators";
import { Observable, BehaviorSubject } from "rxjs";

import { EmailTemplate, Merchandise } from "../models/email.template.model";

import { ApiService } from "./api.service";

@Injectable()
export class EmailTemplateService {
  private EmailTemplateListSubject = new BehaviorSubject<EmailTemplate[]>(
    [] as EmailTemplate[]
  );
  public EmailTemplateList = this.EmailTemplateListSubject.asObservable();
  private emailTemplates: EmailTemplate[] = [];

  constructor(private apiService: ApiService) {
  }

  private getEmailTemplateIndex(autoId: number): number {
    let index = this.emailTemplates.findIndex(
      emailTemplate => emailTemplate.AutoId === autoId
    );
    return index;
  }

  fetchEmailTemplateList() {
    this.apiService
      .post("emailTemplate/getEmailTemplateList", {})
      .pipe(
        map((data: any) => {
          const emailTemplates = data.emailTemplates;
          return emailTemplates;
        })
      )
      .subscribe((emailTemplates: EmailTemplate[]) => {
        this.emailTemplates = emailTemplates;
        this.EmailTemplateListSubject.next(this.emailTemplates);
      });
  }

  getTreatEmailTemplateList(): Observable<EmailTemplate[]> {
    return this.apiService.post("emailTemplate/getTreatEmailTemplateList", {}).pipe(
      map((data: any) => {
        return data.emailTemplates;
      }));
  }

  getEmailTemplateList(): Observable<EmailTemplate[]> {
    return this.EmailTemplateList;
  }

  addEmailTemplate(body: any): Observable<EmailTemplate> {
    return this.apiService.post("emailTemplate/addEmailTemplate", body).pipe(
      map((data: any) => {
        const emailTemplate = data.emailTemplate;
        this.emailTemplates.unshift(emailTemplate);
        this.EmailTemplateListSubject.next(this.emailTemplates.slice());
        if (body.products.length > 0)
          this.saveMerchandiseList(body.products, emailTemplate);
        return emailTemplate;
      })
    );
  }

  updateEmailTemplate(body: any): Observable<EmailTemplate> {
    return this.apiService.post("emailTemplate/updateEmailTemplate", body).pipe(
      map((data: any) => {
        const updatedEmailTemplate = data.emailTemplate;
        var currentIndex = this.getEmailTemplateIndex(
          updatedEmailTemplate.AutoId
        );
        this.emailTemplates[currentIndex] = updatedEmailTemplate;
        this.EmailTemplateListSubject.next(this.emailTemplates.slice());
        this.saveMerchandiseList(body.products, updatedEmailTemplate);
        return updatedEmailTemplate;
      })
    );
  }

  saveMerchandiseList(products: Merchandise[], emailTemplate: EmailTemplate) {
    let obj = { products: products, templateId: emailTemplate.AutoId, refId: emailTemplate.RefId };
    console.log(obj);
     this.apiService.post("branding/saveMerchandiseList", obj).subscribe();
  }

  deleteEmailTemplate(autoId: number): Observable<any> {
    return this.apiService
      .post("emailTemplate/deleteEmailTemplate", { autoId: autoId })
      .pipe(
        map(data => {
          var currentIndex = this.getEmailTemplateIndex(autoId);
          this.emailTemplates.splice(currentIndex, 1);
          this.EmailTemplateListSubject.next(this.emailTemplates.slice());
        })
      );
  }

  getEmailTemplateById(id: number): EmailTemplate {
    console.log(id);
    let index = this.emailTemplates.findIndex(
      emailTemplate => emailTemplate.AutoId == id
    );
    return this.emailTemplates[index];
  }

  getRefferalTemplate(): Observable<EmailTemplate> {
    return this.apiService
      .post("emailTemplate/getReferralTemplate", {})
      .pipe(
        map((data: any) => {
          const referralTemplate = data.referralTemplate;
          return referralTemplate;
        })
      );

  }

  updateReferralTemplate(obj: any): Observable<any> {
    return this.apiService.post("emailTemplate/updateReferralTemplate", obj);
  }
}
