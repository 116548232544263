/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "../../node_modules/ngx-ui-loader/ngx-ui-loader.ngfactory";
import * as i4 from "ngx-ui-loader";
import * as i5 from "@angular/platform-browser";
import * as i6 from "./app.component";
import * as i7 from "./core/services/menu.setting.service";
import * as i8 from "./core/services/user.service";
import * as i9 from "@angular/common";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { outlet: 0 }), (_l()(), i1.ɵeld(1, 16777216, null, null, 1, "router-outlet", [], null, [[null, "activate"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("activate" === en)) {
        var pd_0 = (_co.onChangeRouter() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 212992, [[1, 4]], 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, { activateEvents: "activate" }), (_l()(), i1.ɵeld(3, 0, null, null, 1, "ngx-ui-loader", [["fgsColor", "#DD015B"], ["fgsSize", "75"]], null, null, null, i3.View_ɵb_0, i3.RenderType_ɵb)), i1.ɵdid(4, 770048, null, 0, i4.ɵb, [i5.DomSanitizer, i1.ChangeDetectorRef, i4.NgxUiLoaderService], { fgsColor: [0, "fgsColor"], fgsSize: [1, "fgsSize"], fgsType: [2, "fgsType"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_0 = "#DD015B"; var currVal_1 = "75"; var currVal_2 = _co.SPINNER; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], [[2, "aside-collapsed", null], [2, "layout-boxed", null], [2, "layout-fs", null], [2, "hidden-footer", null], [2, "layout-h", null], [2, "aside-float", null], [2, "offsidebar-open", null], [2, "aside-toggled", null], [2, "aside-collapsed-text", null]], null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i6.AppComponent, [i7.MenuSettingService, i8.UserService, i2.Router, i1.PLATFORM_ID, i2.ActivatedRoute, i9.LocationStrategy], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).isCollapsed; var currVal_1 = i1.ɵnov(_v, 1).isBoxed; var currVal_2 = i1.ɵnov(_v, 1).useFullLayout; var currVal_3 = i1.ɵnov(_v, 1).hiddenFooter; var currVal_4 = i1.ɵnov(_v, 1).horizontal; var currVal_5 = i1.ɵnov(_v, 1).isFloat; var currVal_6 = i1.ɵnov(_v, 1).offsidebarOpen; var currVal_7 = i1.ɵnov(_v, 1).asideToggled; var currVal_8 = i1.ɵnov(_v, 1).isCollapsedText; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
var AppComponentNgFactory = i1.ɵccf("app-root", i6.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
