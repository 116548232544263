import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';

@Injectable()
export class GiftPlatformService {
    constructor(private apiService: ApiService) {

    }

    getUserToken(obj: any): Observable<any> {
        return this.apiService.post("giftPlatform/getUserToken", obj);
    }

    makePayment(object: any) {
        return this.apiService.post("giftPlatform/makePayment/", object);
    }
}