import { Router } from "@angular/router";

import { BigBrandingOptions, BigBrandingDOptions, BrandingDOptions, BrandingOptions } from "../core/models/branding.model";
import { EmailTemplate } from "../core/models/email.template.model";
import { brandingGuild } from "../core/models/guild.branding";
import { User } from "../core/models/user.model";

import { AppConstants } from "./app.constant";
declare var $: any;
declare var require: any;
const Swal = require("sweetalert2");
const CryptoJS = require("crypto-js");
import hmacSHA512 from 'crypto-js/hmac-sha512';



export class Helper {
  static toCaptalize(str: string): string {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  static getParameterByName(name: string, url: string) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  static formatDate(date: Date): string {
    return (
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
    );
  }


  static formatDateYear(date: Date): string {
    let todayDate = new Date();
    let year = todayDate > date ? todayDate.getFullYear() + 1 : date.getFullYear();
    return (
      year + "-" + (date.getMonth() + 1) + "-" + date.getDate()
    );
  }


  static formatMMDYYToDate(value: string): string {
    let MM = value[0] + value[1];
    let DD = value[2] + value[3];
    let date = new Date(MM + "/" + DD);
    return (
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
    );
  }

  static replaceRecipientTag(message: string, name: string): string {
    message = message.replace(AppConstants.RECIPIENT_NAME, name);
    return message;
  }

  static replaceRecipientAndGiftLinkTag(
    message: string,
    name: string,
    link: string
  ): string {
    message = message.replace(/\[Recipient_Name\]/g, name);

    message = message.replace(/\[Gift_Link\]/g, link);
    return message;
  }

  static replaceSenderTag(
    message: string,
    name: string
  ): string {
    message = message.replace(/\[Sender_Name\]/g, name);
    return message;
  }

  static getHandwrittenNote(
    senderName: string,
    recipientName: string,
    handwrittenNote: string
  ): string {
    if (handwrittenNote == "")
      handwrittenNote = AppConstants.DEFAULT_HANDWRITTEN_NOTE;
    // handwrittenNote = handwrittenNote.replace(
    //   AppConstants.RECIPIENT_NAME,
    //   Helper.getFirstName(recipientName)
    // );
    // handwrittenNote = handwrittenNote.replace(
    //   AppConstants.RECEIVER_NAME,
    //   Helper.getFirstName(recipientName)
    // );
    // handwrittenNote = handwrittenNote.replace(
    //   AppConstants.RECEIVER1_NAME,
    //   Helper.getFirstName(recipientName)
    // );
    handwrittenNote = handwrittenNote.replace(
      AppConstants.SENDER_NAME,
      Helper.getFirstName(senderName)
    );
    return handwrittenNote;
  }

  static getFirstName(recipientName: string) {
    return recipientName.indexOf("&") != -1 ? recipientName : recipientName.split(" ")[0];
  }

  static getCurrentMonth() {
    let currentDate = new Date();
    let months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return months[currentDate.getMonth()];
  }

  static getMonthName(monthNumber: number) {
    let months = [
      "December",
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
    ];
    return months[monthNumber];
  }

  static getReferralLink(inviteCode: string): string {
    return AppConstants.REFERRAL_LINK_PREFIX + inviteCode;
  }

  static isMobile(): boolean {
    return $(window).width() <= 700;
  }

  static getBrandingCreditObject() {
    let obj = {
      100: {
        price: 700,
        cashback: 500,
      },
      50: {
        price: 400,
        cashback: 200,
      },
      25: {
        price: 250,
        cashback: 50,
      },
      10: {
        price: 130,
        cashback: 0,
      },
    };
    return obj;
  }

  static getEmailBodyForRemainder(emailTemplate: EmailTemplate) {
    return emailTemplate.HTML.indexOf("[Gift_Link]") != -1
      ? emailTemplate.HTML
      : emailTemplate.SMSText.indexOf("[Gift_Link]") != -1
        ? emailTemplate.SMSText
        : emailTemplate.HTML;
  }

  static getSignature(user: any) {
    let signatureText = "";
    signatureText +=
      "<div style='padding:0 !important;margin:0 !important;margin-top:10px !important'>Thank you,</div>";
    if (user.RefreshToken != "") {
      signatureText +=
        "<div style='padding:0 !important;margin:0 !important'>" +
        user.Name +
        "</div><div style='padding:0 !important;margin:0 !important'>" +
        user.CompanyName +
        "</div>";
    } else {
      signatureText +=
        "<div style='padding:0 !important;margin:0 !important'>Eva</div><div style='padding:0 !important ;margin:0 !important'>Personal Gifting Assistant to " +
        Helper.toCaptalize(user.FirstName) +
        "</div>";
    }

    return signatureText;
  }

  static getHMAC(body:any, tnounce) {
    let access_key = "cf32521ed1852c92dc8ca85ad1d468bdd2246a89a04a836e3c682b376cabd742";
    let secret_key = "872fbc5890777bb998081186f196ed56fca36b358049879cd20e69203b34d5d9";
    const payload = `${tnounce}.${JSON.stringify(body)}`;
    return CryptoJS.HmacSHA512(payload, secret_key).toString(CryptoJS.enc.Hex); //"57004ee15300c9d92f0cac6f4457331209e7526be1787e985c3056ae22f54e7cad529de6f52319d602997216dff7b775"; //
  }

  static IsGuildUser(email: string) {
    return email.toLowerCase().indexOf("guildmortgage.net") != -1 || email.toLowerCase().indexOf("guildmortgage.com") != -1;
  }

  static IsSVBUser(email: string) {
    return email.toLowerCase().indexOf("svb.com") != -1;
  }

  static IsGuild(homeActivity: string) {
    return homeActivity.indexOf("$$$Guild$$$") != -1;
  }

  static IsEvergreenUser(homeActivity: string) {
    return homeActivity.indexOf("$$$evergreen$$$") != -1 || homeActivity.indexOf("@evergreenhomeloans.com") != -1 || homeActivity.indexOf("@thedormanteam.com") != -1 || homeActivity.indexOf("@salmonbaylending.com") != -1;
  }
  static IsSideUser(homeActivity: string) {
    return homeActivity.indexOf("$$$side$$$") != -1  || homeActivity.indexOf("side.com") != -1;;
  }

  static IsAmerifirst(homeActivity: string) {
    return homeActivity.indexOf("$$$30637$$$") != -1;
  }

  static guildRoute(homeActivity: string, isSetUp: number) {
    if (homeActivity.indexOf("[AccountBox]") == -1 && isSetUp == 0)
      return ["/guild", "plan"];
    else if (homeActivity.indexOf("[GuidelinesAccepted]") == -1 && isSetUp == 0) {
      return ["/guidelines"];
    }
    else {
      return ["/home"];
    }
  }

  static svbRoute(email: string, isSetUp: number) {
    if (this.IsSVBUser(email) && isSetUp == 0)
      return ["/svb", "plan"];
    else {
      return ["/home"];
    }
  }

  static getBoxesList(isGuild: boolean, isBig: boolean) {
    let b = isBig ? BigBrandingOptions : (isGuild ? BrandingOptions : BrandingOptions);
    return b;
  }

  static getBoxesDList(isBig: boolean) {
    //let b = isBig ? BigBrandingDOptions : BrandingDOptions;
    let b = isBig ? BigBrandingOptions : BrandingOptions;
    return b;
  }

  static getUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  static getScreenHeight() {
    return window.innerHeight;
  }

  static setScreenHeight() {
    if (!this.isMobile()) {
      let height = Helper.getScreenHeight() + "px";
      $(".register-form").css("height", height);
      $(".register-text").css("height", height);
    }
  }

  static formatCurrencyNumber(value: number) {
    var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    return formatter.format(value);
  }

  static isEmailValid(value: string) {
    try {
      let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return (
        (value.trim() != "" && emailRegex.test(value.trim()))
      );
    }
    catch (e) {
      return false;
    }
  }

}
