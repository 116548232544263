export class AppConstants {
  //static readonly HOST: string = "https://invoice.evabot.ai/";
  //static readonly HOST: string = "http://localhost:9105/";
  static readonly RECIPIENT_NAME: string = "[Recipient_Name]";
  static readonly RECEIVER_NAME: string = "[Reciever_Name]";
  static readonly RECEIVER1_NAME: string = "[Receiver_Name]";
  static readonly SENDER_NAME: string = "[Sender_Name]";
  static readonly GIFT_LINK: string = "[Gift_Link]";
  static readonly DEFAULT_EMAIL_BODY_TEXT: string =
    "<p>Hi [Recipient_Name],</p><p><br></p><p>{Type your message for example- Wanted to send you a gift as a token of appreciation.}</p><p><br></p><p>Please click this link to accept and personalize your gift -- [Gift_Link]</p>";

  static readonly DEFAULT_EMAIL_BODY_TEXT_SMS: string =
    "<p>Hi [Recipient_Name],</p><p><br></p><p>{Type your message for example- Wanted to send you a gift as a token of appreciation.}</p><p><br></p><p>Please click this link to accept your gift -- [Gift_Link]</p>";

  static readonly DEFAULT_SMS_TEXT =
    "Hi " +
    AppConstants.RECIPIENT_NAME +
    ", Sending you a small gift, it's fun 😊. Please accept it by clicking this link- " +
    AppConstants.GIFT_LINK;

  static CONFIRM_BUTTON_COLOR = "#DD015B";
  static SECONDARY_BUTTON_COLOR = "#dedede";
  static CONFIRM_BUTTON_TEXT = "Yes, Delete it!";
  static CANCEL_BUTTON_TEXT = "No, keep it";
  static EMAIL_TEMPLATE_CREATION_SUCCESS =
    '<p><h3>Email Template Created Successfully.</h3><p> <p>This Template can be used from <br>"Send Gifts" → "Email" → Email Template section</p><p> Note: Only works if you have connected your Google or Outlook Account.</p>';
  static GUILD_NEW_TEMPLATE_WARNING = '<p><h3>Email Template Created Successfully.</h3><p> <p style="color:red">This template will only work if you send gifts manually from the dashboard. If you wish to use the template for automation please email to support@evabot.ai and mention "Custom Guild Template" in the subject.</p>';


  static PRICE_TAG = "*P&S";
  static PRICE_INFO =
    "*P&S=Shipping, packaging & handling and taxes (*Int'l shipping + customs is extra).";

  static PRICE_TAG_PREMIUM = "*P";
  static PRICE_INFO_PREMIUM =
    "*P=Packaging & handling and taxes (*Int'l shipping + customs is extra).";

  static HANDWRITTEN_NOTE_TAG_INFO =
    "Note: The Tag " +
    AppConstants.RECIPIENT_NAME +
    " will automatically pick the name. Please do not change/remove it.";

  static DEFAULT_EMAIL_ALERT_TEXT =
    "Hi " +
    AppConstants.RECIPIENT_NAME +
    ",\n Sending you a surprise which you can personalize for yourself by chatting with Eva. Just click here to chat with Eva -- " +
    AppConstants.GIFT_LINK;

  static DEFAULT_SUBJECT = "Sending you a gift";

  static REFERRAL_LINK_PREFIX = "https://evabot.ai/referral/";
  static CANCEL_EMAIL_TEMPLATE = 95;
  static FIRST_GIFT_EMAILER_TEMPLATE = 481;
  static DEFAULT_HANDWRITTEN_NOTE =
    "Hi " +
    AppConstants.RECIPIENT_NAME +
    " Sending you a small gift. Hope you will like it. Enjoy ~" +
    AppConstants.SENDER_NAME;
  static LINK_COPIED_SUCCESS =
    "Link copied";
  static FROM_EVA = "Eva Gifting Assistant<eva@evabot.ai>";
  static HANDWRITTEN_COST = 2;
  static WATERMARKS_GIFT = {
    RecipientName: "Recipient Name",
    RecipientEmail: "Email address",
    RecipientMobile: "Mobile",
  };

  static readonly REMOTE_OCCASION_ID: number = 857;
  static readonly UGL_HOLIDAY_HANDWRITTEN = "Dear " + AppConstants.RECIPIENT_NAME + ", Thank you for your efforts during another successive year! May the festive season end this year on a positive note and make way for a fresh and bright new year. Happy Holidays! ~" + AppConstants.SENDER_NAME;
  static readonly UGL_HOLIDAY_TAG = "[holiday_plan_signup]";
  static readonly UGL_SEND_ALL_HOLIDAY_TAG = "[send_all_holiday_gift]";
  static readonly UGL_HOLIDAY_CREATED = "[ugl_created]";
}
