import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { SettingService } from './settings.service';
import { UserStatics } from '../models/user.statics.model';
import { User } from '../models/user.model';
import { Helper } from '../../shared/helper';

@Injectable()
export class MenuService {
    menuItems: Array<any>;
    public dataSubscription: any;

    constructor(private settingService: SettingService) {
        this.menuItems = [];

    }

    addMenu(items: Array<{
        text: string,
        data?: string,
        heading?: boolean,
        link?: string,     // internal route links
        elink?: string,    // used only for external links
        target?: string,   // anchor target="_blank|_self|_parent|_top|framename"
        icon?: string,
        alert?: string,
        submenu?: Array<any>
    }>, currentUser: User) {
        this.menuItems = [];
        this.settingService.userStatics.subscribe(
            (userStatics: UserStatics) => {
                if (this.menuItems.length == 0)
                    items.forEach((item) => {
                        console.log("item.text", item.submenu);
                        if (item.text == "Branding") {
                            currentUser.subscription.IsBranding && this.menuItems.push(item);
                        }

                        else if (item.submenu && item.submenu.find(s => s.text == "BDAP") && !Helper.IsGuild(currentUser.HomeActivity)) {
                            item.submenu.forEach((sitem, index) => {
                                if (sitem.text == "BDAP")
                                    item.submenu.splice(index, 1);
                            });
                            this.menuItems.push(item)
                        }   

                        else if (item.submenu && item.submenu.find(s => s.text == "Customer Data")) {
                            if (currentUser.subscription.IsCustomerData) 
                            { this.menuItems.push(item) }
                            else {
                                item.submenu.forEach((sitem, index) => {
                                    if (sitem.text == "Customer Data")
                                        item.submenu.splice(index, 1);
                                });

                                this.menuItems.push(item)
                            };
                        }
                        else
                            this.menuItems.push(item);
                    });
            })

    }

    getMenu() {
        return this.menuItems;
    }

}



