import { Component, OnInit, HostBinding, ViewChild, Inject, PLATFORM_ID } from "@angular/core";
import { MenuSettingService } from './core/services/menu.setting.service';
import { UserService } from './core/services/user.service';
import { SPINNER } from 'ngx-ui-loader';
import { Router, RouterOutlet, ActivationStart, Params, ActivatedRoute } from '@angular/router';
import { isPlatformBrowser, LocationStrategy } from '@angular/common';
declare var window: any;
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  // @HostBinding('class.layout-fixed') get isFixed() { return this.settings.getLayoutSetting('isFixed'); };
  @HostBinding('class.aside-collapsed') get isCollapsed() { return this.settings.getLayoutSetting('isCollapsed'); };
  @HostBinding('class.layout-boxed') get isBoxed() { return this.settings.getLayoutSetting('isBoxed'); };
  @HostBinding('class.layout-fs') get useFullLayout() { return this.settings.getLayoutSetting('useFullLayout'); };
  @HostBinding('class.hidden-footer') get hiddenFooter() { return this.settings.getLayoutSetting('hiddenFooter'); };
  @HostBinding('class.layout-h') get horizontal() { return this.settings.getLayoutSetting('horizontal'); };
  @HostBinding('class.aside-float') get isFloat() { return this.settings.getLayoutSetting('isFloat'); };
  @HostBinding('class.offsidebar-open') get offsidebarOpen() { return this.settings.getLayoutSetting('offsidebarOpen'); };
  @HostBinding('class.aside-toggled') get asideToggled() { return this.settings.getLayoutSetting('asideToggled'); };
  @HostBinding('class.aside-collapsed-text') get isCollapsedText() { return this.settings.getLayoutSetting('isCollapsedText'); };
  public SPINNER = SPINNER.ballSpinClockwiseFadeRotating;
  @ViewChild(RouterOutlet, { static: false }) outlet: RouterOutlet;

  constructor(private settings: MenuSettingService, private userService: UserService, private router: Router, @Inject(PLATFORM_ID) private platformId: Object, private activatedRoute: ActivatedRoute, private location: LocationStrategy) {
    this.router.events.subscribe((ev) => {
      if (router.url.includes("obv2")) {
        this.onChangeRouter();
      }
    })
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      if (window.location.pathname.toLowerCase() == "/demoredirect") {
        this.activatedRoute.queryParams.subscribe(
          (params: Params) => {
            if (params["hashId"]) {
              localStorage.setItem("sessionId", params["hashId"]);
              localStorage.removeItem("navigationUrl");
              window.location = "/";
            }
          })
      }
      else {
        this.userService.populate();
        this.router.events.subscribe(e => {
          if (e instanceof ActivationStart && e.snapshot.outlet === "administration")
            this.outlet.deactivate();
        });
      }
    }
  }


  onChangeRouter() {
    
    history.pushState(null, null, window.location.href);
    // check if back or forward button is pressed.
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
      //this.stepper.previous();
    });
  }

}
