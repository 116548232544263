<div class="wrapper">
    <app-header *ngIf="!isAuthenticated && currentPath.indexOf('giftPlatform')==-1 && currentPath.indexOf('giftPlatform')==-1"></app-header>

    <app-header *ngIf="isAuthenticated && currentPath.indexOf('giftPlatform')==-1 && currentPath.indexOf('giftPlatform')==-1"></app-header>
    <ng-template [ngIf]="isAuthenticated && isSetUp==1 && currentPath.indexOf('giftPlatform')==-1 && currentPath.indexOf('giftPlatform')==-1">
        <app-menu></app-menu>
    </ng-template>
    <div>
        <router-outlet></router-outlet>
    </div>
</div>