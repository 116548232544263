<div style="background: #f6f6f6">
  <div class="wrapper p-0">
    <div class="row" style="margin: 0 auto">
      <div class="col-md-1"></div>
      <div class="col-md-6 pl-0 pr-0">
        <ngx-slick-carousel
          class="carousel"
          #slickModal="slick-carousel"
          [config]="slideConfig"
        >
          <div
            class="slide-container"
            ngxSlickItem
            *ngFor="let slide of images; let i = index"
          >
            <img src="{{ slide.img }}" alt="" class="slide-image" />
          </div>
        </ngx-slick-carousel>
      </div>
      <div style="background: #f6f6f6" class="col-md-4">
        <div class="register-form" [ngClass]="{ 'desk-position': !isMobile }">
          <p *ngIf="!isMobile" class="p-1"></p>
          <p
            class="text-center py-2 eva-sub-header cr-acc-text"
            style="font-size: 21px; opacity: 0.9"
          >
            Create your account to receive your referral gift
          </p>
          <p *ngIf="!isMobile" class="p-1"></p>
          <p
            *ngIf="!isMobile"
            style="font-size: 24px; color: #4d4d4d !important; opacity: 0.9"
            class="text-center py-2 eva-sub-header"
          >
            Sign Up
          </p>
          <p class="p-1"></p>
          <p *ngIf="isInvalid" class="text-center text-danger">
            {{ signUpError }}
          </p>
          <form class="mb-3" (ngSubmit)="onSignUp(f)" #f="ngForm">
            <div class="form-group">
              <div class="input-group with-focus">
                <input
                  [(ngModel)]="userName"
                  class="form-control border-right-0"
                  type="text"
                  placeholder="Enter full name"
                  name="name"
                  ngModel
                  autocomplete="off"
                  #name="ngModel"
                  [ngClass]="{ 'input-error': isSubmitted && !name.valid }"
                  required
                />
                <div class="input-group-append">
                  <span
                    [ngClass]="{ 'input-error': isSubmitted && !name.valid }"
                    class="input-group-text text-muted bg-transparent border-left-0"
                  >
                    <em class="fa fa-user"></em>
                  </span>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="input-group with-focus">
                <input
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,64}$"
                  [(ngModel)]="userEmail"
                  class="form-control border-right-0"
                  type="email"
                  placeholder="Enter email"
                  name="email"
                  ngModel
                  oninput="this.value = this.value.toLowerCase()"
                  autocomplete="off"
                  #email="ngModel"
                  [ngClass]="{ 'input-error': isSubmitted && !email.valid }"
                  required
                  email
                />
                <div class="input-group-append">
                  <span
                    [ngClass]="{ 'input-error': isSubmitted && !email.valid }"
                    class="input-group-text text-muted bg-transparent border-left-0"
                  >
                    <em class="fa fa-envelope"></em>
                  </span>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="input-group with-focus">
                <input
                  pattern=".{6,}"
                  class="form-control border-right-0"
                  ngModel
                  type="password"
                  placeholder="Password"
                  name="password"
                  autocomplete="new-password"
                  #password="ngModel"
                  [ngClass]="{ 'input-error': isSubmitted && password.invalid }"
                  required
                />
                <div class="input-group-append">
                  <span
                    [ngClass]="{
                      'input-error': isSubmitted && password.invalid
                    }"
                    class="input-group-text text-muted bg-transparent border-left-0"
                  >
                    <em class="fa fa-lock"></em>
                  </span>
                </div>
              </div>
              <span
                *ngIf="isSubmitted && password.invalid"
                class="error-message"
                >password should be atleast 6 characters long</span
              >
            </div>
            <div class="clearfix"></div>
            <div class="text-center">
              <button
                style="
                  width: 180px;
                  font-size: 18px;
                  box-shadow: 1px 1px 2px 0px lightgrey;
                "
                class="btn btn-danger mt-3"
                type="submit"
              >
                Sign Up
              </button>
            </div>
          </form>
          <p class="text-center lead">---------- or -----------</p>
          <p class="text-center m-2">
            <a href="https://client.stage.evabot.com/user/googleLogin">
              <img
                style="width: 180px; border-radius: 5px"
                src="../../../../assets/img/google-login-normal.png"
              />
            </a>
          </p>
          <p class="pt-4 text-center">
            Have an account?&nbsp;<a class="skip-link" [routerLink]="['/login']"
              >Sign In</a
            >
          </p>
        </div>
      </div>
      <div class="col-md-1"></div>
    </div>
  </div>
</div>

<div class="p-5 text-center">
  <span>&copy; 2024 - EvaBot Inc. All Rights Reserved.</span>
</div>
