/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./redirectv2.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./redirectv2.component";
import * as i3 from "../../../core/services/user.service";
import * as i4 from "@angular/router";
var styles_Redirectv2Component = [i0.styles];
var RenderType_Redirectv2Component = i1.ɵcrt({ encapsulation: 0, styles: styles_Redirectv2Component, data: {} });
export { RenderType_Redirectv2Component as RenderType_Redirectv2Component };
export function View_Redirectv2Component_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" loading...\n"]))], null, null); }
export function View_Redirectv2Component_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-redirectv2", [], null, null, null, View_Redirectv2Component_0, RenderType_Redirectv2Component)), i1.ɵdid(1, 245760, null, 0, i2.Redirectv2Component, [i3.UserService, i4.ActivatedRoute, i4.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var Redirectv2ComponentNgFactory = i1.ɵccf("app-redirectv2", i2.Redirectv2Component, View_Redirectv2Component_Host_0, {}, {}, []);
export { Redirectv2ComponentNgFactory as Redirectv2ComponentNgFactory };
