import { NgModule, Optional, SkipSelf } from '@angular/core';
import { DatePipe } from '@angular/common';

import { S3UploaderService } from 'ngx-s3-uploader';

import { AddressService } from './services/address.service';
import { ApiService } from './services/api.service';
import { AuthGuard } from './services/auth.guard.service';
import { CardService } from './services/card.service';
import { EmailTemplateService } from './services/email.template.service';
import { ErrorService } from './services/error.service';
import { MenuService } from './services/menu.service';
import { MenuSettingService } from './services/menu.setting.service';
import { PaymentFailedGuard } from './services/payment.failed.guard.service';
import { PremiumGuard } from './services/premium.guard.service';
import { PriceService } from './services/price.service';
import { PromoService } from './services/promo.service';
import { RedirectionGuard } from './services/redirection.guard.service';
import { SettingService } from './services/settings.service';
import { StonleyService } from './services/stonley.service';
import { UserService } from './services/user.service';

@NgModule(
    {
        imports: [],
        declarations: [],
        exports: [],
        providers: [MenuService,
            ApiService, UserService,
            PriceService, CardService,AddressService,
            EmailTemplateService,
            MenuSettingService,
            DatePipe, S3UploaderService,
            SettingService,
            ErrorService,PromoService,
            AuthGuard, PremiumGuard,RedirectionGuard, PaymentFailedGuard, StonleyService]
    }
)
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {

    }
}