import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ModalComponent } from 'angular-5-popup';
import { Router } from '@angular/router';

@Component({
  selector: 'app-upgraded-alert',
  templateUrl: './upgraded-alert.component.html',
  styleUrls: ['./upgraded-alert.component.css']
})
export class UpgradedAlertComponent implements OnInit {

  @ViewChild("modal4",{static:false}) modal: ModalComponent;
  private route: any;

  @Input("data") set data(data: any) {
    if (data) {
      setTimeout(() => {
        this.modal.openModal("modal-4");
        this.route = data.route;

      }, 200);
    }
  }

  constructor(private router: Router) { }

  ngOnInit() { }

  onClose() {
    this.router.navigate(this.route);
    this.modal.closeModal("modal-4");
  }

}
