import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ApiService } from "./api.service";

@Injectable()
export class UserSocialAccount {
    private ngUnsubscribe = new Subject();
    constructor(private apiService: ApiService) {

    }
    getSocialData():Observable<any> {
        return this.apiService.post("social/getSocialData", {});
    }

    removeSocialAccount(type:string):Observable<any> {
        return this.apiService.post("social/removeSocialAccount", {type:type});
    }


  saveTwitterData(obj: any): Observable<any> {
    return this.apiService.post("social/saveTwitterData", obj);

  }

  saveLinkedInData(obj: any): Observable<any> {
    return this.apiService.post("social/saveLinkedInData", obj);

  }
}