import { Component, OnInit, OnDestroy, Injector } from "@angular/core";
import { Router } from "@angular/router";

import { AppConstants } from "../../shared/app.constant";
import { User } from "../../core/models/user.model";
import { MenuService } from "../../core/services/menu.service";
import { MenuSettingService } from "../../core/services/menu.setting.service";
import { UserService } from "../../core/services/user.service";
import { Helper } from "../../shared/helper";

declare var $: any;
declare var require: any;
const Swal = require("sweetalert2");

@Component({
  selector: "app-menu",
  templateUrl: "menu.component.html",
  styleUrls: ["menu.component.css"],
})
export class MenuComponent implements OnInit, OnDestroy {
  public currentUser: User;
  private userSubscription: any;
  public menuItems: Array<any>;
  public router: Router;
  public sbclickEvent = "click.sidebar-toggle";
  $doc: any = null;

  constructor(
    private menuService: MenuService,
    private userService: UserService,
    public settings: MenuSettingService,
    private injector: Injector
  ) {
    this.menuItems = menuService.getMenu();
  }

  ngOnInit() {
    this.router = this.injector.get(Router);

    this.router.events.subscribe((val) => {
      this.removeFloatingNav();
      window.scrollTo(0, 0);
      this.settings.setLayoutSetting("asideToggled", false);
    });

    // enable sidebar autoclose from extenal clicks
    this.anyClickClose();

    this.userSubscription = this.userService.currentUser.subscribe(
      (user: User) => {
        this.currentUser = user;
        let attribute = this.router.url.toLowerCase();
        if (this.currentUser.HomeActivity.includes("[holiday_plan_signup]")) {
          if (!(attribute.includes("sendgift") || attribute.includes("giftstatus") || attribute.includes("branding") || attribute.includes("card") || attribute.includes("profile") || attribute.includes("setting") || attribute.includes("invoice") || attribute.includes("faq") || attribute.includes("logout"))) {
            //if (!Helper.IsGuildUser(this.currentUser.Email))
              //this.router.navigate(["/sendGift", "ugl"]);
          }
        }
      }
    );
  }

  anyClickClose() {
    this.$doc = $(document).on(this.sbclickEvent, (e) => {
      if (!$(e.target).parents(".aside-container").length) {
        this.settings.setLayoutSetting("asideToggled", false);
      }
    });
  }

  onSignOut() {
    this.userService.purgeAuth();
  }

  toggleSubmenuClick(event) {
    event.preventDefault();

    if (
      !this.isSidebarCollapsed() &&
      !this.isSidebarCollapsedText() &&
      !this.isEnabledHover()
    ) {
      let target = $(event.target || event.srcElement || event.currentTarget);
      let ul,
        anchor = target;

      // find the UL
      if (!target.is("a")) {
        anchor = target.parent("a").first();
      }
      ul = anchor.next();

      // hide other submenus
      let parentNav = ul.parents(".sidebar-subnav");
      $(".sidebar-subnav").each((idx, el) => {
        let $el = $(el);
        // if element is not a parent or self ul
        if (!$el.is(parentNav) && !$el.is(ul)) {
          this.closeMenu($el);
        }
      });

      // abort if not UL to process
      if (!ul.length) {
        return;
      }

      // any child menu should start closed
      ul.find(".sidebar-subnav").each((idx, el) => {
        this.closeMenu($(el));
      });

      // toggle UL height
      if (parseInt(ul.height(), 0)) {
        this.closeMenu(ul);
      } else {
        // expand menu
        ul.on("transitionend", () => {
          ul.height("auto").off("transitionend");
        }).height(ul[0].scrollHeight);
        // add class to manage animation
        ul.addClass("opening");
        setTimeout(() => {
          let attribute = (<HTMLAnchorElement>event.target).getAttribute(
            "datamenu"
          );

          if (this.currentUser.HomeActivity.includes("[holiday_plan_signup]")) {
            if (attribute != "sendgift" && attribute != "giftstatus" && attribute != "branding-not" && attribute != "setting" && attribute != "faq" && attribute != "logout") {
              //if (!Helper.IsGuildUser(this.currentUser.Email))
              //this.router.navigate(["/sendGift", "ugl"]);
            }
          }

          else {

            if (attribute == "sendgift") {
              if (this.currentUser.RefreshToken != "") {
                this.router.navigate(["/sendGift", "email"]);
              } else {
                this.router.navigate(["/sendGift", "createLink"]);
              }
            }
            else if (attribute == "referral") {
              this.router.navigate(["/referral"]);
            }
          }
        }, 100);
      }
    }
  }

  // Close menu collapsing height
  closeMenu(elem) {
    elem.height(elem[0].scrollHeight); // set height
    elem.height(0); // and move to zero to collapse
    elem.removeClass("opening");
  }

  toggleSubmenuHover(event) {
    let self = this;
    if (
      this.isSidebarCollapsed() ||
      this.isSidebarCollapsedText() ||
      this.isEnabledHover()
    ) {
      event.preventDefault();

      this.removeFloatingNav();

      let target = $(event.target || event.srcElement || event.currentTarget);
      let ul,
        anchor = target;
      // find the UL
      if (!target.is("a")) {
        anchor = target.parent("a");
      }
      ul = anchor.next();

      if (!ul.length) {
        return; // if not submenu return
      }

      let $aside = $(".aside-container");
      let $asideInner = $aside.children(".aside-inner"); // for top offset calculation
      let $sidebar = $asideInner.children(".sidebar");
      let mar =
        parseInt($asideInner.css("padding-top"), 0) +
        parseInt($aside.css("padding-top"), 0);
      let itemTop = anchor.parent().position().top + mar - $sidebar.scrollTop();

      let floatingNav = ul.clone().appendTo($aside);
      let vwHeight = $(window).height();

      // let itemTop = anchor.position().top || anchor.offset().top;

      floatingNav
        .removeClass("opening") // necesary for demo if switched between normal//collapsed mode
        .addClass("nav-floating")
        .css({
          position: this.settings.getLayoutSetting("isFixed")
            ? "fixed"
            : "absolute",
          top: itemTop,
          bottom:
            floatingNav.outerHeight(true) + itemTop > vwHeight ? 0 : "auto",
        });

      floatingNav
        .on("mouseleave", () => {
          floatingNav.remove();
        })
        .find("a")
        .on("click", function (e) {
          e.preventDefault(); // prevents page reload on click
          // get the exact route path to navigate
          let routeTo = $(this).attr("route");
          if (routeTo) self.router.navigate([routeTo]);
        });

      this.listenForExternalClicks();
    }
  }

  listenForExternalClicks() {
    let $doc = $(document).on("click.sidebar", (e) => {
      if (!$(e.target).parents(".aside-container").length) {
        this.removeFloatingNav();
        $doc.off("click.sidebar");
      }
    });
  }

  removeFloatingNav() {
    $(".nav-floating").remove();
  }

  isSidebarCollapsed() {
    return this.settings.getLayoutSetting("isCollapsed");
  }
  isSidebarCollapsedText() {
    return this.settings.getLayoutSetting("isCollapsedText");
  }
  isEnabledHover() {
    return this.settings.getLayoutSetting("asideHover");
  }

  onProfileImageClick() {
    this.router.navigate([".profile"]);
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
    if (this.$doc) this.$doc.off(this.sbclickEvent);
  }
}
