import { Component, OnInit } from '@angular/core';
import { GiftPlatformService } from '../../../core/services/gift.platform.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UserService } from '../../../core/services/user.service';

@Component({
  selector: 'app-redirect-gift-platform',
  templateUrl: './redirect-gift-platform.component.html',
  styleUrls: ['./redirect-gift-platform.component.css'],
  providers: [GiftPlatformService]
})
export class RedirectGiftPlatformComponent implements OnInit {

  constructor(private giftPlatformService: GiftPlatformService, private activatedRoute: ActivatedRoute, private userService: UserService, private router: Router) {
    localStorage.removeItem("sessionId");
    localStorage.removeItem("txnId");
    localStorage.removeItem("externalId");
    this.activatedRoute.queryParams.subscribe(
      (params: Params) => {
        localStorage.setItem("txnId", params["txnId"]);
        this.giftPlatformService.getUserToken({ reqToken: params["email"], name: params["name"], params: params }).subscribe(
          (data) => {
            console.log(data);
            localStorage.setItem("sessionId", data.tokenNumber);
            localStorage.setItem("externalId", data.externalId);
            localStorage.setItem("uAccessToken", data.token.accessToken);
            this.userService.populate();
            this.router.navigate(["/giftPlatform"]);
          }
        )
      }
    )
  }

  ngOnInit() {
  }

}
