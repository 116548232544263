export class FeatureConstant {
    static readonly Branding: string = "branding";
    static readonly SendGift: string = "giftsend";
    static readonly DirectMailer: string = "directmailers";
    static readonly Merchandise: string = "merchandise";
    static readonly Handwritten: string = "handwritten";
    static readonly Campaign: string = "campaign";
    static readonly AutomatedBirthday: string = "automatedbirthday";
    static readonly AutomatedHomeAnniversary: string = "automatedhomeanniversary";
    static readonly AutomatedEmployee: string = "automatedemployee";
    static readonly AutomatedClientRenewal: string = "automatedclientrenewal";
    static readonly TeamManagement: string = "teammanagement";
    static readonly AdvancedTeamManagement: string = "advancedteammanagement";
    static readonly CustomerData: string = "customerdata";
    static readonly Integration: string = "integration";
    static readonly Referrals: string = "referrals";
    static readonly GiftSelection: string = "giftselection";
    static readonly Testimonial: string = "testimonial";
    static readonly CustomTemplate: string = "customtemplates";
    static readonly UniversalLink: string = "universal";
    static readonly AutoReview: string = "autoreview";
    static readonly Utl: string = "utl";
    static readonly validationList: string = "validationlist";
    static readonly addressCustomerData: string = "addresscustomerdata";
}