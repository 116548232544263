<div class="outer-wrapper" style="background: white">
  <div class="fluid-container">
    <div class="border-wrapper">
      <div class="inner-wrapper">
        <!-- Section includes step count, logo, and skip demo link -->
        <div class="card-top-section">
          <div class="row">
            <div class="col-4 card-top-left">
              <div class="dot-full">
                <span class="inner-dot" id="step-count">1</span>
              </div>
              <span class="of-text-container">
                <span class="of-text">of</span>
              </span>
              <div class="dot-outer">
                <span class="inner-dot">4</span>
              </div>
            </div>
            <div class="col-4 card-top-center">
              <img
                src="../../../../../assets/img/evabot-logo-mobile.png"
                alt="Evabot logo"
                class="evabot-logo-img"
              />
            </div>
            <div class="col-4 card-top-right">
              <p class="skip-demo-text">
                <a
                  style="font-size: 18px"
                  id="skip-demo"
                  style="cursor: pointer"
                  (click)="onSkipDemo()"
                  >Skip Demo</a
                >
              </p>
            </div>
          </div>
        </div>
        <div id="chat-header" class="card-top-section" style="display: none">
          <!-- style="display:none;background:#fafafa;height:56px;position:fixed;top:0;z-index:1" -->
          <div class="row" id="demo-header">
            <div class="col-4 card-top-left">
              <div class="dot-full">
                <span class="inner-dot" id="step-count">3</span>
              </div>
              <span class="of-text-container">
                <span class="of-text"> of </span>
              </span>
              <div class="dot-outer">
                <span class="inner-dot">5</span>
              </div>
            </div>
            <div class="col-4 card-top-center">
              &nbsp;
              <!-- <img src="images/eva-santa-logo.png" alt="Evabot logo" class="evabot-logo-img"/> -->
            </div>
            <div class="col-4 card-top-right">
              <p class="skip-demo-text">
                <a id="continue-demo" style="cursor: pointer">Skip Chat</a>
              </p>
            </div>
          </div>
        </div>
        <!-- End of upper Section -->
        <!-- Wrapper for all four steps -->
        <div class="steps-wrapper">
          <div class="row">
            <div class="col-12">
              <!-- Step 1 -->
              <div id="step-1" class="step">
                <div class="text-container">
                  <h3 class="main-tag-line">Sending a gift with Eva is easy</h3>
                  <img
                    src="../../../../../assets/img/demo/step-1-send.png"
                    alt="showing how evabot sends gifts"
                    class="step-img"
                  />
                  <p>
                    To begin with, enter your info.<span
                      style="font-size: 0.8em"
                    >
                      <br />You’ll enter your gift recipients contact<br />
                      information after the demo.
                    </span>
                  </p>
                </div>
                <form (ngSubmit)="onSubmitGeneralInfo(cif)" #cif="ngForm">
                  <div class="form-section-container">
                    <div class="form-field-wrapper">
                      <input
                        [(ngModel)]="customerName"
                        type="text"
                        placeholder="Your Name"
                        name="name"
                        ngModel
                        autocomplete="off"
                        #name="ngModel"
                        [ngClass]="{
                          'input-error': isSubmittedGeneral && !name.valid
                        }"
                        required
                      />
                      <div
                        style="color: red"
                        *ngIf="isSubmittedGeneral && !name.valid"
                      >
                        Name can not be blank
                      </div>
                    </div>
                    <div class="form-field-wrapper">
                      <input
                        name="email"
                        oninput="this.value = this.value.toLowerCase()"
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,64}$"
                        [(ngModel)]="customerEmail"
                        type="email"
                        placeholder="Your Email"
                        autocomplete="off"
                        #email="ngModel"
                        [ngClass]="{
                          'input-error': isSubmittedGeneral && !email.valid
                        }"
                        required
                        email
                      />
                      <div
                        style="color: red"
                        *ngIf="isSubmittedGeneral && email.value.length == 0"
                      >
                        Email can not be blank
                      </div>
                      <div
                        style="color: red"
                        *ngIf="
                          isSubmittedGeneral &&
                          !email.valid &&
                          email.value.length > 0
                        "
                      >
                        Invalid Email
                      </div>
                    </div>
                  </div>
                  <div class="button-section-container">
                    <button
                      type="submit"
                      id="step-1-button"
                      class="btn evabot-button-main"
                    >
                      Next Step
                    </button>
                  </div>
                </form>
              </div>
              <!-- Step 2 -->
              <div id="step-2" class="step">
                <div id="step-2-inner">
                  <div class="text-container">
                    <p class="text-center">
                      Eva provides a unique link to share with gift recipients.
                      Eva chats with the gift recipient to understand their
                      preferences.
                    </p>
                  </div>
                  <div class="link-copy-section link-section text-center mt-3">
                    <a style="text-decoration: underline; color: #ff493f9c"
                      >https://www.evabot.ai/gift/xjxr9qqj</a
                    >
                  </div>
                  <div class="text-section-container">
                    <p class="click-below-text text-center">
                      Below is a preview of the chat.
                    </p>
                  </div>
                </div>
                <div class="demo-video-container">
                  <img
                    src="../../../../../assets/img/demo/chat-phone-gif.gif"
                    alt="evabot demo"
                    class="demo-gif"
                  />
                </div>
                <div class="button-section-container mt-2">
                  <button
                    (click)="onNextStep(4, 3)"
                    type="button"
                    class="btn evabot-button-main"
                  >
                    Next
                  </button>
                </div>
                <div class="button-section-container mt-2">&nbsp;</div>
              </div>

              <!-- Step 4 -->
              <div id="step-4" class="step">
                <div class="text-container">
                  <p style="font-size: 20px" class="text-center">
                    Eva then ships an amazing gift box tailored to their
                    preferences!
                  </p>
                </div>
                <div class="large-image-container">
                  <img
                    style="max-width: 80% !important"
                    src="../../../../../assets/img/demo/kate.gif"
                    alt="Evabot gift package"
                    class="large-gift-image"
                  />
                </div>
                <div class="button-section-container mt-5">
                  <button
                    (click)="onNextStep(5, 4)"
                    type="button"
                    class="btn evabot-button-main"
                  >
                    Next
                  </button>
                </div>
                <div class="p-5"></div>
                <div class="p-5"></div>
              </div>

              <!-- Step 5 -->
              <div id="step-5" class="step">
                <div class="text-container">
                  <p style="font-size: 20px; color: #000">Almost there!</p>
                  <p style="font-size: 16px">
                    Sign up and get $25 in credit for your first gift.
                  </p>
                  <img
                    src="../../../../../assets/img/demo/step4-finisher.png"
                    alt="Eva bot gift demo finishing image"
                    class="step-img"
                  />
                </div>
                <form (ngSubmit)="onSignUp(sForm)" #sForm="ngForm">
                  <div class="form-section-container">
                    <div class="form-field-wrapper">
                      <input
                        [(ngModel)]="userName"
                        type="text"
                        placeholder="Name"
                        name="name"
                        ngModel
                        autocomplete="off"
                        #SIname="ngModel"
                        [ngClass]="{
                          'input-error': isSubmittedSignUp && !SIname.valid
                        }"
                        required
                      />
                      <div
                        style="color: red"
                        *ngIf="isSubmittedSignUp && !SIname.valid"
                      >
                        Name can not be blank
                      </div>
                    </div>
                    <div class="form-field-wrapper">
                      <input
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,64}$"
                        [(ngModel)]="userEmail"
                        type="email"
                        placeholder="Email"
                        name="email"
                        ngModel
                        oninput="this.value = this.value.toLowerCase()"
                        autocomplete="off"
                        #SIemail="ngModel"
                        [ngClass]="{
                          'input-error': isSubmittedSignUp && !SIemail.valid
                        }"
                        required
                        email
                      />
                      <div
                        style="color: red"
                        *ngIf="isSubmittedSignUp && SIemail.value.length == 0"
                      >
                        Email can not be blank
                      </div>
                      <div
                        style="color: red"
                        *ngIf="
                          isSubmittedSignUp &&
                          !SIemail.valid &&
                          SIemail.value.length > 0
                        "
                      >
                        Invalid Email
                      </div>
                    </div>
                    <div class="form-field-wrapper">
                      <div class="form-group">
                        <div class="input-group with-focus">
                          <input
                            style="width: 88.8%; border-right: 0 !important"
                            [type]="keyType"
                            pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-+=@$#^!%*?()&.<>{}[]|;:''?`~/])[A-Za-z\d-+=@$#^()!%*?&.<>{}[]|;:''?`~/]{8,}$"
                            type="password"
                            placeholder="Password"
                            ngModel
                            type="password"
                            placeholder="Password"
                            name="password"
                            autocomplete="new-password"
                            #password="ngModel"
                            [ngClass]="{
                              'input-error':
                                isSubmittedSignUp && password.invalid
                            }"
                            required
                          />
                          <div
                            class="input-group-append cursor-pointer"
                            (click)="onShowPassword()"
                          >
                            <span
                              [ngClass]="{
                                'input-error':
                                  isSubmittedSignUp && password.invalid
                              }"
                              class="input-group-text text-muted bg-transparent border-left-0 border-right-0 border-top-0"
                            >
                              <i
                                *ngIf="isShowPassword"
                                class="fa fa-eye"
                                aria-hidden="true"
                              ></i>
                              <i
                                *ngIf="!isShowPassword"
                                class="fa fa-eye-slash"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="isSubmittedSignUp && password.invalid">
                        <div>Password must contain the following:</div>
                        <div
                          [ngClass]="{
                            'error-message': !lowercaseRegex.test(
                              password.value
                            ),
                            'success-message': lowercaseRegex.test(
                              password.value
                            )
                          }"
                        >
                          <span
                            *ngIf="lowercaseRegex.test(password.value)"
                            class="fa fa-check"
                          ></span
                          ><span
                            *ngIf="!lowercaseRegex.test(password.value)"
                            class="fa fa-times"
                          ></span
                          >&nbsp;A lowercase letter
                        </div>
                        <div
                          [ngClass]="{
                            'error-message': !uppercaseRegex.test(
                              password.value
                            ),
                            'success-message': uppercaseRegex.test(
                              password.value
                            )
                          }"
                        >
                          <span
                            *ngIf="uppercaseRegex.test(password.value)"
                            class="fa fa-check"
                          ></span
                          ><span
                            *ngIf="!uppercaseRegex.test(password.value)"
                            class="fa fa-times"
                          ></span
                          >&nbsp;A Capital (uppercase) letter
                        </div>
                        <div
                          [ngClass]="{
                            'error-message': !numberRegex.test(password.value),
                            'success-message': numberRegex.test(password.value)
                          }"
                        >
                          <span
                            *ngIf="numberRegex.test(password.value)"
                            class="fa fa-check"
                          ></span
                          ><span
                            *ngIf="!numberRegex.test(password.value)"
                            class="fa fa-times"
                          ></span
                          >&nbsp;A Number
                        </div>
                        <div
                          [ngClass]="{
                            'error-message': !min8Regex.test(password.value),
                            'success-message': min8Regex.test(password.value)
                          }"
                        >
                          <span
                            *ngIf="min8Regex.test(password.value)"
                            class="fa fa-check"
                          ></span
                          ><span
                            *ngIf="!min8Regex.test(password.value)"
                            class="fa fa-times"
                          ></span
                          >&nbsp;Minimum 8 characters
                        </div>
                        <div
                          [ngClass]="{
                            'error-message': !specialcharcterRegex.test(
                              password.value
                            ),
                            'success-message': specialcharcterRegex.test(
                              password.value
                            )
                          }"
                        >
                          <span
                            *ngIf="specialcharcterRegex.test(password.value)"
                            class="fa fa-check"
                          ></span
                          ><span
                            *ngIf="!specialcharcterRegex.test(password.value)"
                            class="fa fa-times"
                          ></span
                          >&nbsp;A special character
                        </div>
                      </div>
                    </div>
                    <div class="form-field-wrapper">
                      <select
                        [ngClass]="{
                          'input-error': isSubmittedSignUp && industry.invalid
                        }"
                        style="
                          border: 0;
                          outline: 0;
                          background: transparent;
                          border-bottom: 1px solid #ff7e70;
                          width: 100%;
                          text-align: center;
                          color: black;
                          opacity: 0.7;
                          font-size: 1.3em;
                          font-family: Montserrat, sans-serif;
                          text-align-last: center;
                        "
                        type="text"
                        ngModel
                        type="text"
                        name="industry"
                        #industry="ngModel"
                        required
                      >
                        <option value="">Select Industry</option>
                        <option>Real Estate</option>
                        <option>Mortgage</option>
                        <option>Title & Escrow</option>
                        <option>Employee Engagement</option>
                        <option>Customer Success</option>
                        <option>Sales</option>
                        <option>Financial Advisor</option>
                        <option>Others</option>
                      </select>
                      <div
                        style="color: red"
                        *ngIf="isSubmittedSignUp && industry.invalid"
                      >
                        Please select Industry
                      </div>
                    </div>
                    <div class="form-field-wrapper">
                      <input
                        type="text"
                        placeholder="Company Name"
                        ngModel
                        type="text"
                        name="company"
                        #company="ngModel"
                      />
                    </div>
                  </div>
                  <div
                    *ngIf="isSignUpInvalid"
                    style="text-align: center; color: red"
                  >
                    Your account already exists.
                  </div>
                  <div class="outer-button-wrapper">
                    <div class="button-section-container">
                      <button
                        type="submit"
                        id="step-5-button"
                        class="btn evabot-button-main"
                      >
                        Sign Up
                      </button>
                    </div>
                    <div class="or-text-section">
                      <p>Or</p>
                    </div>
                    <div class="button-section-container">
                      <a
                        href="https://client.stage.evabot.com/user/googleLogin"
                      >
                        <img
                          class="google-option-image"
                          src="../../../../../assets/img/btn_google_signin_dark_normal_web.png"
                        />
                      </a>
                    </div>

                    <div class="mt-2 text-center">
                      <a
                        id="log-in"
                        style="
                          font-size: 16px;
                          cursor: pointer;
                          color: rgb(255, 73, 63);
                        "
                      >
                        Already have an account?
                        <span class="added-underline-style-login-text"
                          >Sign In</span
                        >
                      </a>
                    </div>
                  </div>
                </form>
              </div>

              <div id="step-5-1" class="step">
                <div class="text-container">
                  <p style="font-size: 20px; color: #000">Sign In</p>
                </div>
                <form (ngSubmit)="onSignIn(f)" #f="ngForm">
                  <div class="form-section-container">
                    <div class="form-field-wrapper">
                      <input
                        oninput="this.value = this.value.toLowerCase()"
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,64}$"
                        style="line-height: 20px !important"
                        name="email"
                        type="email"
                        placeholder="Email"
                        ngModel
                        autocomplete="off"
                        #uemail="ngModel"
                        [ngClass]="{
                          'input-error': isSubmittedSignIn && !uemail.valid
                        }"
                        required
                        email
                      />
                      <div
                        style="color: red"
                        *ngIf="isSubmittedSignIn && uemail.value.length == 0"
                      >
                        Email can not be blank
                      </div>
                      <div
                        style="color: red"
                        *ngIf="
                          isSubmittedSignIn &&
                          !uemail.valid &&
                          uemail.value.length > 0
                        "
                      >
                        Invalid Email
                      </div>
                    </div>
                    <div class="form-field-wrapper">
                      <input
                        ngModel
                        type="password"
                        placeholder="Password"
                        name="password"
                        #upassword="ngModel"
                        [ngClass]="{
                          'input-error': isSubmittedSignIn && upassword.invalid
                        }"
                        required
                        style="line-height: 20px !important"
                        name="password"
                        type="password"
                        required
                      />
                      <div
                        style="color: red"
                        *ngIf="isSubmittedSignIn && !upassword.valid"
                      >
                        Password can not be blank
                      </div>
                    </div>
                  </div>
                  <div
                    *ngIf="isSignInInvalid"
                    style="text-align: center; color: red"
                  >
                    Invalid Credentials
                  </div>
                  <div class="outer-button-wrapper">
                    <div class="button-section-container">
                      <button
                        type="submit"
                        id="step-5-1-button"
                        class="btn evabot-button-main"
                      >
                        Sign In
                      </button>
                    </div>

                    <div class="or-text-section">
                      <p style="margin-top: 12px">Or</p>
                    </div>
                    <div class="button-section-container">
                      <a
                        href="https://client.stage.evabot.com/user/googleLogin"
                      >
                        <img
                          class="google-option-image"
                          src="../../../../../assets/img/btn_google_signin_dark_normal_web.png"
                        />
                      </a>
                    </div>
                    <div class="mt-5 text-center">
                      <a
                        id="sign-up"
                        style="
                          font-size: 16px;
                          cursor: pointer;
                          color: rgb(255, 73, 63);
                        "
                      >
                        Don't have account?
                        <span class="added-underline-style-login-text"
                          >Sign Up</span
                        ></a
                      >
                    </div>
                    <div class="p-4"></div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <!-- End of wrapper for all four steps -->

        <!-- Section includes already have an account link -->

        <!-- End of bottom section -->
      </div>
    </div>
  </div>
</div>
