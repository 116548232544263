import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { AppConstants } from '../../../shared/app.constant';
import { User } from '../../../core/models/user.model';
import { UserService } from '../../../core/services/user.service';
declare var require: any
const Swal = require("sweetalert2");
@Component({
  selector: 'app-redirectv2',
  templateUrl: './redirectv2.component.html',
  styleUrls: ['./redirectv2.component.css']
})
export class Redirectv2Component implements OnInit, OnDestroy {

  private dataSubscription: any;
  private userSubscription: any;
  constructor(private userService: UserService, private activatedRoute: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    localStorage.removeItem("ObEvaCases");
    this.dataSubscription = this.activatedRoute.queryParams.subscribe(
      (params: Params) => {
        this.userSubscription = this.userService.currentUser.subscribe(
          (currentUser: User) => {
            if (currentUser.TokenNumber && (currentUser.RefreshToken == "" || currentUser.RefreshToken == "-1")) {
              let obj = { gc: params["code"], email: currentUser.Email }
              this.userService.getGoogleData(obj).subscribe(
                (data) => {
                  if (data.error) {
                    Swal.fire({
                      title: "<p style='font-size:16px'>Oops! One account with this email already exists. We can not connect both the accounts. If you wish to merge both accounts please let us know via the help chat.<p>",
                      text: "",
                      confirmButtonColor: AppConstants.CONFIRM_BUTTON_COLOR,
                      type: "error"
                    }).then(result => {
                      if (result.value) {
                        if (localStorage.getItem("navigationEmailReminder")) {
                          var navigationObject = JSON.parse(localStorage.getItem("navigationEmailReminder"));
                          localStorage.removeItem("navigationEmailReminder")
                          this.router.navigate(navigationObject.redirectionUrl.split('/'), { queryParams: { orderHashId: navigationObject.orderHashId } });
                        }
                        else
                          this.navigate();
                      }
                    });
                  }
                  else {
                    if (localStorage.getItem("navigationEmailReminder")) {
                      var navigationObject = JSON.parse(localStorage.getItem("navigationEmailReminder"));
                      localStorage.removeItem("navigationEmailReminder")
                      this.router.navigate(navigationObject.redirectionUrl.split('/'), { queryParams: { orderHashId: navigationObject.orderHashId } });
                    }
                    else
                      this.router.navigate(["sendGift", "email"]);
                  }
                }
              );
            }
          }
        )
      }
    )
  }

  private navigate() {
    let navigationRoute = localStorage.getItem("navigationRoute");
    if (navigationRoute != undefined) {
      let route = navigationRoute.split("$$$");
      this.router.navigate(route);
    }
    else {
      this.router.navigate(["/sendGift", "email"]);

    }
  }

  ngOnDestroy() {
    if (this.dataSubscription)
      this.dataSubscription.unsubscribe();
    if (this.userSubscription)
      this.userSubscription.unsubscribe();
  }

}
