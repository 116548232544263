import * as tslib_1 from "tslib";
import { HttpClient } from "@angular/common/http";
import { forkJoin } from "rxjs";
import { AppConfig } from "../../shared/app.config";
export class ApiService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.apiUrl = AppConfig.HOST;
    }
    get(path) {
        return this.httpClient.get(`${this.apiUrl}${path}`);
    }
    post(path, body) {
        return this.httpClient.post(`${this.apiUrl}${path}`, body);
    }
    multipleGet(requestObjects) {
        let request = [];
        requestObjects.forEach(requestUrl => {
            request.push(this.httpClient.get(`${this.apiUrl}${requestUrl}`));
        });
        return forkJoin(request);
    }
    multiplePost(path, requestObjects) {
        let request = [];
        requestObjects.forEach(obj => {
            request.push(this.httpClient.post(`${this.apiUrl}${path}`, obj));
        });
        return forkJoin(request);
    }
    GetData(path) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let result = yield this.httpClient.get(`${this.apiUrl}${path}`).toPromise();
            return result;
        });
    }
    GetDirectData(path) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let result = yield this.httpClient.get(path).toPromise();
            return result;
        });
    }
    directGet(path) {
        return this.httpClient.get(path);
    }
    directPost(path, body = {}) {
        console.log(body);
        return this.httpClient.post(path, body);
    }
}
