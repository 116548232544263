import { Injectable } from "@angular/core";

import { Observable, BehaviorSubject } from "rxjs";
import { map, findIndex } from "rxjs/operators";

import { Card } from "../models/card.model";
import { User } from '../models/user.model';

import { ApiService } from "./api.service";
import { UserService } from './user.service';

@Injectable()
export class CardService {
  private cardSubject = new BehaviorSubject<Card[]>([] as Card[]);
  public cardList = this.cardSubject.asObservable();
  private cards: Card[];
  private defaultCard: Card;
  private currentUser: User;

  constructor(private apiService: ApiService, private userService: UserService) {
    this.userService.currentUser.subscribe(
      (currenUser: User) => {
        this.currentUser = currenUser;
      }
    )


  }

  fetchCardList() {
    return this.apiService
      .post("card/getCardList", {})
      .pipe(
        map((data: any) => {
          const cards = data.cards;
          return cards;
        })
      )
      .subscribe((cards: Card[]) => {
        this.cards = cards;
        this.cardSubject.next(cards);
        this.setDefaultCard();
      });
  }

  setDefaultCard() {
    let cards = this.cards;
    if (cards.length > 0) {
      this.defaultCard =
        cards[cards.findIndex(card => card.IsCardDefault === 1)];
      if (this.defaultCard == undefined)
        this.defaultCard = cards[0];

    }
  }

  getDefaultCard(): Card {
    return this.defaultCard;
  }

  getAllCards(): Observable<Card[]> {
    return this.apiService
      .post("card/getCardListAll", {})
      .pipe(
        map((data: any) => {
          const cards = data.cards;
          return cards;
        })
      )
  }

  addCard(token: string): Observable<any> {
    return this.apiService
      .post("card/addCard", { token: token, name: this.currentUser.Name, email: this.currentUser.Email })
      .pipe(
        map((data: any) => {
          if (data.error) {
            return data;
          }
          else {
            const cards = data.cards;
            const customerId = data.customerId;
            this.cards = cards;
            this.cardSubject.next(cards);
           // this.userService.updateCustomerId(customerId);
            return { totalCards: this.cards.length, cardId: data.cardId };
          }
        })
      )
  }

  markDefault(cardId: string): Observable<any> {
    return this.apiService.post("card/markDefault", { cardId: cardId });
  }

  changeUserCard(obj: any): Observable<any> {
    return this.apiService.post("card/updateUserCard/", obj);
  }

  deleteCard(autoId: number) {
    this.apiService
      .post("card/deleteCard", { autoId: autoId })
      .pipe(
        map((data: any) => {
          const cards = data.cards;
          return cards;
        })
      )
      .subscribe((cards: Card[]) => {
        this.cards = cards;
        this.cardSubject.next(cards);
      });
  }

  updateSource(obj: any): Observable<any> {
    return this.apiService.post("card/updateSource/", obj);
  }


  updateTeamCard(obj: any): Observable<any> {
    return this.apiService.post("card/updateTeamCard/", obj);
  }
}
