import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { AppConstants } from '../../shared/app.constant';
import { Helper } from '../../shared/helper';
import { User } from '../../core/models/user.model';
import { UserStatics } from '../../core/models/user.statics.model';
import { MenuService } from '../../core/services/menu.service';
import { MenuSettingService } from '../../core/services/menu.setting.service';
import { SettingService } from '../../core/services/settings.service';
import { UserService } from '../../core/services/user.service';
declare var require: any
const screenfull = require('screenfull');
// const browser = require('jquery.browser');
declare var $: any;
const Swal = require("sweetalert2");


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {

  public navCollapsed = true; // for horizontal layout
  public menuItems = []; // for horizontal layout
  public isMobile: boolean = Helper.isMobile();
  public userStatics: UserStatics;
  private dataSubscription: any;
  public currentUser: User;
  private isGoogleLoginShow: boolean = false;
  public alertData: any;
  public isHeaderShow: boolean = false;
  public isGuildAlertShow: boolean = true;

  @ViewChild('fsbutton', { static: false }) fsbutton: any;  // the fullscreen button

  constructor(public menu: MenuService, public settings: MenuSettingService, private settingService: SettingService, public userService: UserService, private router: Router) {
    // show only a few items on demo
    this.menuItems = menu.getMenu().slice(0, 4); // for horizontal layout

  }

  ngOnInit() {
    // if (browser.msie) { // Not supported under IE
    //   this.fsbutton.nativeElement.style.display = 'none';
    // }
    this.isHeaderShow = !this.router.url.includes("obv2");
    this.dataSubscription = this.userService.currentUser.subscribe(
      (currentUser: User) => {

        this.currentUser = currentUser;
        if (currentUser.TokenNumber) {
          this.dataSubscription = this.settingService.userStatics.subscribe(
            (userStatics: UserStatics) => {
              this.userStatics = userStatics;
              this.guildAlert(currentUser);
              // this.guildGuidelineAlert(currentUser);
              this.IncreaseShippingPriceNotification(currentUser);
            }
          )
        }

        if (currentUser.TokenNumber && !this.isGoogleLoginShow && currentUser.RefreshToken == "-1") {
          console.log("this.isGoogleLoginShow", this.isGoogleLoginShow)
          this.isGoogleLoginShow = true;
          //this.alertData = { type: "google login alert" };
        }
      }
    )
  }

  toggleUserBlock(event) {
    event.preventDefault();
  }

  toggleOffsidebar() {
    this.settings.toggleLayoutSetting('offsidebarOpen');
  }

  toggleCollapsedSideabar() {
    this.settings.toggleLayoutSetting('isCollapsed');
  }

  isCollapsedText() {
    return this.settings.getLayoutSetting('isCollapsedText');
  }

  toggleFullScreen(event) {

    if (screenfull.enabled) {
      screenfull.toggle();
    }
    // Switch icon indicator
    let el = $(this.fsbutton.nativeElement);
    if (screenfull.isFullscreen) {
      el.children('em').removeClass('fa-expand').addClass('fa-compress');
    }
    else {
      el.children('em').removeClass('fa-compress').addClass('fa-expand');
    }
  }

  onLogOut() {
    this.router.navigate(['/logout']);
  }

  private guildAlert(currentUser: User) {

    let date = new Date();

    if (Helper.IsGuild(currentUser.HomeActivity) && this.isGuildAlertShow
      && (currentUser.HomeActivity.indexOf("[guild_automation_link]") == -1
      || currentUser.HomeActivity.indexOf("[guild_review_link]") == -1
      || currentUser.HomeActivity.indexOf("[guild_profile_update]") == -1
      || currentUser.HomeActivity.indexOf("[guild_payment_method]") == -1)) {
      Swal.fire({
        icon: "info",
        html: '<p style="font-size:14px;font-weight:800; margin-top:10px ">Attention Valued Customer: <br><br>Please complete all required steps on the homepage to ensure prompt processing of your gifts. Failure to do so may result in issues and delays.<br><br>Thank you for your cooperation!</p>',
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton: true,
      });

      this.isGuildAlertShow=false;

    }
  }

  private guildGuidelineAlert(currentUser: User) {

    let date = new Date();
    let formattedDate = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    let getLastGuildDemoPopUpDate = localStorage.getItem("_guildGuidelineAccepted");
    if (Helper.IsGuild(currentUser.HomeActivity) && currentUser.HomeActivity.indexOf("[GuidelinesAccepted]") == -1 && getLastGuildDemoPopUpDate != formattedDate && currentUser.IsSetUp == 1) {
      Swal.fire({
        title: '',
        html: `<p style="font-size:18px;font-weight:800">REMINDER: Closing Gifts can only be sent to customers after the funding of their loan. Providing gifts to
        realtors and other settlement service providers is prohibited by RESPA. You agree to follow RESPA and Guild
        policies when using this platform to send a closing gift. Packages that only contain promotional swag (i.e.
        Guild branded items of nominal value) are permitted to send to customers and business partners as a way to
        promote Guild products or services. All gifts and packages sent are logged for auditing purposes.</p>`,
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: "Accept Terms",
        confirmButtonColor: AppConstants.CONFIRM_BUTTON_COLOR
      }).then((result) => {
        if (result.value) {
          const obj = { isAutomationAccepted: 0 }
          this.userService.acceptGuidlines(obj).subscribe(
            (obj) => {
              this.currentUser.HomeActivity = obj.data.HomeActivity
              this.currentUser.IsSetUp = 1;
              this.userService.updateUserReference(this.currentUser);
            }
          )
        }
      })
      localStorage.setItem("_guildGuidelineAccepted", formattedDate);
    }
  }

  private IncreaseShippingPriceNotification(currentUser: User) {

    //     if (currentUser.HomeActivity.indexOf("[SpecialPricing]") == -1) {
    //       if (!Helper.IsGuild(currentUser.HomeActivity) && currentUser.HomeActivity.indexOf("[NewPricingAccepted]") == -1 && currentUser.IsSetUp == 1 && currentUser.UserId < 32931 && currentUser.TotalBrandingValue == 0 && (currentUser.PlanName == "premium" || currentUser.PlanName == "enterprise")) {
    //         Swal.fire({
    //           title: '',
    //           html: `<p style="font-size:18px;font-weight:800">We are adding (blended) tax of $1 on all the gifts from April 15th, 2021. Rest everything remains same 🙂</p>`,
    //           showCloseButton: false,
    //           showCancelButton: false,
    //           showConfirmButton: true,
    //           confirmButtonText: "Accept",
    //           allowEscapeKey: false,
    //           allowOutsideClick: false,
    //           confirmButtonColor: AppConstants.CONFIRM_BUTTON_COLOR
    //         }).then((result) => {
    //           if (result.value) {
    //             const obj = { tag: "[NewPricingAccepted]" }
    //             this.userService.acceptHomeActivityTag(obj).subscribe(
    //               (obj) => {
    //                 this.currentUser.HomeActivity = obj.data.HomeActivity
    //                 this.userService.updateUserReference(this.currentUser);
    //               }
    //             )
    //           }
    //         });
    //       }

    //       if (!Helper.IsGuild(currentUser.HomeActivity) && currentUser.HomeActivity.indexOf("[NewPricingAccepted]") == -1 && currentUser.IsSetUp == 1 && currentUser.UserId < 32931 && currentUser.TotalBrandingValue == 0 && (currentUser.PlanName == "basic" || currentUser.PlanName.toLowerCase() == "free")) {

    //         Swal.fire({
    //           title: '',
    //           html: `<p style="font-size:18px;font-weight:800">Our shipping and packaging prices are increasing. We are also adding blended tax to all your gifts. <br><br>For gifts worth $50 or less shipping, packaging & handling and taxes will now be $18/gift.<br><br> For gifts worth $75 or more the shipping, packaging & handling and taxes will be $15/gift.<br><br> Changes will take affect from April, 15th 2021.</p>`,
    //           showCloseButton: false,
    //           showCancelButton: false,
    //           showConfirmButton: true,
    //           confirmButtonText: "Accept",
    //           allowEscapeKey: false,
    //           allowOutsideClick: false,
    //           confirmButtonColor: AppConstants.CONFIRM_BUTTON_COLOR
    //         }).then((result) => {
    //           if (result.value) {
    //             const obj = { tag: "[NewPricingAccepted]" }
    //             this.userService.acceptHomeActivityTag(obj).subscribe(
    //               (obj) => {
    //                 this.currentUser.HomeActivity = obj.data.HomeActivity
    //                 this.userService.updateUserReference(this.currentUser);
    //               }
    //             )
    //           }
    //         });
    //       }

    //       if (!Helper.IsGuild(currentUser.HomeActivity) && currentUser.HomeActivity.indexOf("[NewPricingAccepted]") == -1 && currentUser.IsSetUp == 1 && currentUser.UserId < 32931 && currentUser.TotalBrandingValue > 0 && (currentUser.PlanName == "premium" || currentUser.PlanName == "enterprise")) {
    //         Swal.fire({
    //           title: '',
    //           html: `<p style="font-size:18px;font-weight:800">Post Covid our packaging and handling costs have increased substantially. We tried our best to not increase any prices during the past 12 months. But we must now to sustain the quality. We are also adding (blended) tax of $1 on all the gifts from April  15th, 2021. Free shipping still applies 🙂 <br><br>
    // The new packaging, handling and taxes cost will be $6/gift (increase of $3/gift).<br><br>
    // If you purchase your own branded boxes and notes, the cost will reduce to $4/gift.</p>`,
    //           showCloseButton: false,
    //           showCancelButton: false,
    //           showConfirmButton: true,
    //           confirmButtonText: "Accept",
    //           allowEscapeKey: false,
    //           allowOutsideClick: false,
    //           confirmButtonColor: AppConstants.CONFIRM_BUTTON_COLOR
    //         }).then((result) => {
    //           if (result.value) {
    //             const obj = { tag: "[NewPricingAccepted]" }
    //             this.userService.acceptHomeActivityTag(obj).subscribe(
    //               (obj) => {
    //                 this.currentUser.HomeActivity = obj.data.HomeActivity
    //                 this.userService.updateUserReference(this.currentUser);
    //               }
    //             )
    //           }
    //         });
    //       }
    //     }
  }

  ngOnDestroy() {
    if (this.dataSubscription)
      this.dataSubscription.unsubscribe();
  }
}
