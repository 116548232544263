<div class="wrapper">
    <div class="block-center mt-4 wd-xl">
        <!-- START card-->
        <div class="card card-flat">
            <div class="card-header text-center">
                <a href="#">
                    <img style="width: 200px; image-rendering: auto;max-width: 220px !important;" class="block-center rounded eva-logo" src="https://s3.amazonaws.com/eva-bot/img/ad758549-6113-46a8-a146-02680f108288.png" alt="Image">
                </a>
            </div>
            <div *ngIf="!isEmailSent" class="card-body">
                <p class="text-center py-2">PASSWORD RESET</p>
                <p *ngIf="isInvalid" class="text-center" style="font-weight: 600;">If an account with this username exists, you will receive an email with instructions on how to reset your password.</p>
                <form (ngSubmit)="onReset(f)" #f="ngForm">
                    <p class="text-center">Fill with your email to receive instructions on how to reset your password.</p>
                    <div class="form-group">
                        <label class="text-muted" for="resetInputEmail1">Email address</label>
                        <div class="input-group with-focus">
                            <input class="form-control border-right-0" type="email" placeholder="Enter email" name="email" ngModel autocomplete="off" #email="ngModel" [ngClass]="{'input-error':isSubmitted&& !email.valid}" required oninput="this.value = this.value.toLowerCase()"
                                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,64}$">
                            <div class="input-group-append">
                                <span [ngClass]="{'input-error':isSubmitted&& !email.valid}" class="input-group-text text-muted bg-transparent border-left-0">
                  <em class="fa fa-envelope"></em>
                </span>
                            </div>
                        </div>
                    </div>
                    <button class="btn btn-danger btn-block" type="submit">Send Reset Password Email</button>
                </form>
                <p class="pt-3 text-center">Need to Signup?</p>
                <a class="btn btn-block btn-secondary" [routerLink]="['/login']">Sign In</a>
            </div>
            <div *ngIf="isEmailSent" class="card-body">
                <p *ngIf="!IsSSO" class="text-center" style="font-weight:600;">If an account with this username exists, you will receive an email with instructions on how to reset your password.</p>
                <p *ngIf="IsSSO" class="text-center" style="font-weight:600;">You can not reset your password. Please sign in with <a class="skip-link" [routerLink]="['/sso']">SSO</a> or contact your organization's IT team.

                </p>
                <a class="btn btn-block btn-danger" [routerLink]="['/login']">Sign into your account</a>
            </div>
        </div>
        <!-- END card-->
        <div class="p-3 text-center">
            <span>&copy; 2024 - EvaBot Inc. All Rights Reserved.</span>
        </div>
    </div>
</div>