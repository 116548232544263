<div class="signup-section">
    <div class="signup-info-section" *ngIf="!isMobile">
        <div class="signup-info">
            <div>
                <img style="width: 100%;" src="https://s3.amazonaws.com/eva-bot/img/47079c51-1e2b-4b1f-82f3-40317e6952f8.png	">
            </div>
            <div class="element-gap-42"></div>
            <div style="margin-left: 2%;" class="font-40 font-bold-400 black">Hi {{userName}},</div>
            <div class="element-gap-12"></div>
            <div style="margin-left: 2%;" class="font-40 font-bold-400 black">Welcome to Eva!</div>

            <!-- <div style="position: absolute; bottom: 5%;">
                <div class="font-14 font-bold-400">Not registered yet?</div>
                <div><a [routerLink]="['/signup']" style="color: #FFC200 !important; cursor: pointer; "
                        class="font-14 font-bold-400 cta">Create a Eva account</a>
                </div>
            </div> -->
            <div style="position: absolute;bottom: -9%;right: -6%;">
                <img src="../../../../assets/img/signin-giftbox.svg">
            </div>
        </div>
    </div>

    <div class="signup-form-section">
        <div class="signup-form" *ngIf="!isLinkExpired">
              <div class="font-24 font-bold-400 black">Set a password for your<br>Eva account</div>
              <div class="element-gap-42"></div>
            <form class="mb-3" (ngSubmit)="onSignUp(f)" #f="ngForm">
                <div class="form-row">
                    <div class="form-group col-md-12">
                        <label for="user-password">Password</label>
                        <div class="input-group">
                            <input id="user-password" [type]="keyType"
                                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-+=@$#^!%*?()&.<>{}[]|;:''?`~/])[A-Za-z\d-+=@$#^()!%*?&.<>{}[]|;:''?`~/]$"
                                class="form-control" ngModel name="password" #password="ngModel"
                                [ngClass]="{'input-error': isSubmitted && password.invalid}" autocomplete="off"
                                required />
                            <!-- <div
                          class="input-group-addon"
                          style="
                            line-height: 2.5;
                            border: 1px solid #ced4da;
                            border-left: 0;
                            width: 30px;
                            background: inherit;
                            cursor: pointer;
                          "
                          (click)="onShowPassword()" >
                          <i *ngIf="isShowPassword" class="fa fa-eye" aria-hidden="true" ></i>
                          <i *ngIf="!isShowPassword" class="fa fa-eye-slash"aria-hidden="true"></i>
                        </div> -->
                        </div>
                    </div>
                </div>

                <div *ngIf="isSubmitted && password.invalid">
                    <div style="color:#000">Password must contain the following:</div>
                    <div [ngClass]="{
                        'error-message': !lowercaseRegex.test(
                          password.value
                        ),
                        'success-message': lowercaseRegex.test(
                          password.value
                        )
                      }">
                        <span *ngIf="lowercaseRegex.test(password.value)" class="fa fa-check"></span><span
                            *ngIf="!lowercaseRegex.test(password.value)" class="fa fa-times"></span>&nbsp;A lowercase
                        letter
                    </div>
                    <div [ngClass]="{
                        'error-message': !uppercaseRegex.test(
                          password.value
                        ),
                        'success-message': uppercaseRegex.test(
                          password.value
                        )
                      }">
                        <span *ngIf="uppercaseRegex.test(password.value)" class="fa fa-check"></span><span
                            *ngIf="!uppercaseRegex.test(password.value)" class="fa fa-times"></span>&nbsp;A Capital
                        (uppercase) letter
                    </div>
                    <div [ngClass]="{
                        'error-message': !numberRegex.test(password.value),
                        'success-message': numberRegex.test(password.value)
                      }">
                        <span *ngIf="numberRegex.test(password.value)" class="fa fa-check"></span><span
                            *ngIf="!numberRegex.test(password.value)" class="fa fa-times"></span>&nbsp;A Number
                    </div>
                    <div [ngClass]="{
                        'error-message': !min8Regex.test(password.value),
                        'success-message': min8Regex.test(password.value)
                      }">
                        <span *ngIf="min8Regex.test(password.value)" class="fa fa-check"></span><span
                            *ngIf="!min8Regex.test(password.value)" class="fa fa-times"></span>&nbsp;Minimum 8
                        characters
                    </div>
                    <div [ngClass]="{
                        'error-message': !specialcharcterRegex.test(
                          password.value
                        ),
                        'success-message': specialcharcterRegex.test(
                          password.value
                        )
                      }">
                        <span *ngIf="specialcharcterRegex.test(password.value)" class="fa fa-check"></span><span
                            *ngIf="!specialcharcterRegex.test(password.value)" class="fa fa-times"></span>&nbsp;A
                        special character
                    </div>
                </div>
                <div class="element-gap-24"></div>
                <div class="form-group">
                    <label for="user-password">Confirm Password</label>
                    <div class="input-group with-focus">
                        <input class="form-control" ngModel type="password"
                            name="cpassword" #cpassword="ngModel"
                            [ngClass]="{'input-error':isSubmitted && (password.value!=cpassword.value)}" required>

                    </div>
                    <span *ngIf="isSubmitted && (password.value!=cpassword.value)" class="error-message">passwords do
                        not
                        match</span>

                </div>

                <div class="element-gap-16"></div>
                <p *ngIf="isInvalid" class="text-left red" style="color: red ;">{{errorMessage}}</p>
                <button class="button-primary button-medium" type="submit">Set password&nbsp;&nbsp;<img
                        src="../../../../assets/img/icons/right-arrow.svg"></button>
            </form>
           
        </div>

        <div class="signup-form" *ngIf="isLinkExpired">
            <div class="font-20 font-bold-400 black">Your Link to reset password is either expired or invalid</div>
            <div class="element-gap-42"></div>
        </div>
        <div class="p-3 text-center" style="position: absolute;bottom:0">
          <span>&copy; 2024 - EvaBot Inc. All Rights Reserved.</span>
        </div>
     
    </div>

</div>
