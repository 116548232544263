/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./linkedin-callback.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../core/services/social.service";
import * as i3 from "../../../core/services/api.service";
import * as i4 from "./linkedin-callback.component";
import * as i5 from "@angular/router";
import * as i6 from "../../../core/services/user.service";
var styles_LinkedinCallbackComponent = [i0.styles];
var RenderType_LinkedinCallbackComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LinkedinCallbackComponent, data: {} });
export { RenderType_LinkedinCallbackComponent as RenderType_LinkedinCallbackComponent };
export function View_LinkedinCallbackComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Redirecting...\n"]))], null, null); }
export function View_LinkedinCallbackComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-linkedin-callback", [], null, null, null, View_LinkedinCallbackComponent_0, RenderType_LinkedinCallbackComponent)), i1.ɵprd(512, null, i2.UserSocialAccount, i2.UserSocialAccount, [i3.ApiService]), i1.ɵdid(2, 245760, null, 0, i4.LinkedinCallbackComponent, [i5.ActivatedRoute, i6.UserService, i2.UserSocialAccount, i5.Router], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var LinkedinCallbackComponentNgFactory = i1.ɵccf("app-linkedin-callback", i4.LinkedinCallbackComponent, View_LinkedinCallbackComponent_Host_0, {}, {}, []);
export { LinkedinCallbackComponentNgFactory as LinkedinCallbackComponentNgFactory };
