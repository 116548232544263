import { Component, OnInit, OnDestroy, Inject, PLATFORM_ID } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

import { UserService } from '../../../core/services/user.service';
declare var window: any;

@Component({
  selector: 'app-demo-redirect',
  templateUrl: './demo-redirect.component.html',
  styleUrls: ['./demo-redirect.component.css']
})
export class DemoRedirectComponent implements OnInit, OnDestroy {
  private dataSubscription: any;
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private userService: UserService, @Inject(PLATFORM_ID) private platformId: Object) { }
  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem("isDemo", "1");
      localStorage.setItem("ob", "ob");
    }

    // if (isPlatformBrowser(this.platformId)) {
    //   localStorage.removeItem("sessionId");
    //   this.dataSubscription = this.activatedRoute.queryParams.subscribe(
    //     (params: Params) => {
    //       localStorage.setItem("sessionId", params["hashId"]);
    //       this.userService.populate();
    //     })
    // }
  }

  ngOnDestroy() {
    if (this.dataSubscription)
      this.dataSubscription.unsubscribe()
  }

}
