<div class="signup-section">
  <div class="signup-info-section" *ngIf="!isMobile">
      <div class="signup-info">
          <div style="font-size: 80px;line-height: 1.2;" class="font-bold-800 black">Welcome! <br>Let's get<br>started.</div>
          <div class="element-gap-42"></div>
          <div>
            <img src="../../../../assets/img/logo/joc.png">
        </div>

          <!-- <div style="position: absolute; bottom: 5%;">
              <div class="font-14 font-bold-400">Not registered yet?</div>
              <div><a [routerLink]="['/signup']" style="color: #FFC200 !important; cursor: pointer; "
                      class="font-14 font-bold-400 cta">Create a Eva account</a>
              </div>
          </div> -->
          <div style="position: absolute;bottom: -9%;right: -6%;">
              <img src="../../../../assets/img/signin-giftbox.svg">
          </div>
      </div>
  </div>

  <div class="signup-form-section">
      <div class="signup-form">
          <div *ngIf="isMobile">
              <div>
                  <img src="../../../../assets/img/logo/eva-new-logo.png">
              </div>
              <div class="element-gap-16"></div>
              <div class="font-24 font-bold-400 black">Welcome to Eva!</div>
              <div class="element-gap-24"></div>
          </div>
          <div class="font-24 font-bold-500 black">Login with</div>
          <div class="element-gap-16"></div>
          <div>
              <a  href="https://core.evabot.com/user/googleLogin" class="btn-google"><img src="../../../../assets/img/signup/google-logo.svg">&nbsp;&nbsp;Google</a>
          </div>
          <div class="element-gap-24"></div>
          <div class="font-14 font-bold-400 disabled-text">Or use your email to continue</div>
          <div class="element-gap-24"></div>
          <form class="mb-3" (ngSubmit)="onSignUp(f)" #f="ngForm">
              <div class="form-row">
                  <div class="col-md-12">
                      <label class="form-label font-14" for="user-email">Email address</label>
                      <div class="element-gap-4"></div>
                      <input id="user-email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,64}$"
                          placeholder="e.g. name@company.com" [(ngModel)]="userEmail" class="form-control"
                          type="email" name="email" ngModel oninput="this.value = this.value.toLowerCase()"
                          autocomplete="off" #email="ngModel" [ngClass]="{'input-error':isSubmitted&& !email.valid}"
                          required email>
                      <div *ngIf="isSubmitted&& !email.valid" class="form-input-error">Enter a valid work email</div>
                  </div>

              </div>
              <div class="element-gap-24"></div>
              <div class="form-row">
                  <div class="form-group col-md-12">
                      <label class="form-label font-14" for="user-password">Password</label>
                      <div class="element-gap-4"></div>
                      <div class="input-group">
                          <input id="user-password" type="password"
                              class="form-control" ngModel name="password" #password="ngModel"
                              [ngClass]="{'input-error':isSubmitted && password.invalid}" autocomplete="off" required>
                      </div>
                      <div *ngIf="isSubmitted&& !password.valid" class="form-input-error">Enter valid password</div>
                  </div>
              </div>
              <div class="element-gap-8"></div>
              <div>
                  <a [routerLink]="['/forgotPassword']" id="forgot-password" class="font-14 font-bold-400"
                      style="color: #000000 !important;">Forgot password?</a>
              </div>
              <div class="element-gap-16"></div>
              <p *ngIf="isInvalid" class="text-left red" style="color: red ;">{{errorMessage}}</p>
              <button class="button-primary button-medium" type="submit">Login to Eva&nbsp;&nbsp;<img
                      src="../../../../assets/img/icons/right-arrow.svg"></button>
          </form>

      </div>
      <div class="p-3 text-center" style="position: absolute;bottom:0">
        <span>&copy; 2024 - EvaBot Inc. All Rights Reserved.</span>
      </div>
  </div>

</div>