import { Injectable } from "@angular/core";

import { Observable, forkJoin } from "rxjs";
import { map } from "rxjs/operators";

import { ApiService } from "./api.service";

@Injectable()
export class ExploreGiftService {
  constructor(private apiService: ApiService) { }

  getPreferencesGiftPlatform(object: any): Observable<any> {
    return this.apiService.post(
      "exploreGift/getPreferencesGiftPlatform",
      object
    );
  }

  getPreferences(object: any): Observable<any> {
    return this.apiService.post("exploreGift/getPreferences", object);
  }

  getPreferencesByTags(object: any): Observable<any> {
    return this.apiService.post("exploreGift/getPreferencesByTags", object);
  }

  getPreferencesByTagsExploreGift(object: any): Observable<any> {
    return this.apiService.post(
      "exploreGift/getPreferencesByTagsExploreGift",
      object
    );
  }

  addBoxToCart(object: any): Observable<any> {
    return this.apiService.post("exploreGift/addBoxToCart", object).pipe(
      map((result:any) => {
        const cartData = result.data[0];
        return cartData;
      })
    );
  }

  addBoxToCartLW(object: any): Observable<any> {
    return this.apiService.post("exploreGift/addBoxToCartLW", object).pipe(
      map((result:any) => {
        const cartData = result.data[0];
        return cartData;
      })
    );
  }

  addBoxToFavourite(object: any): Observable<any> {
    return this.apiService.post("favourite/addBoxToFavourite", object);
  }

  getCartBoxes(): Observable<any> {
    return this.apiService.post("exploreGift/getCartBoxes", {});
  }

  getFavouriteBoxes(): Observable<any> {
    return this.apiService.post("favourite/getFavouriteList", {});
  }

  getTopCities(): Observable<any> {
    return this.apiService.post("exploreGift/getTopCities", {});
  }

  updateCartAddress(object: any): Observable<any> {
    return this.apiService.post("cart/updateCartAddress", object);
  }

  deleteCartItem(autoId: any): Observable<any> {
    return this.apiService.post("cart/deleteCartItem", { autoId: autoId });
  }

  deleteBox(autoIds: any): Observable<any[]> {
    let requestParams = [];
    autoIds.forEach((autoId) => {
      requestParams.push({ autoId: autoId });
    });
    return this.apiService.multiplePost("cart/deleteCartItem", requestParams);
  }

  deleteFavouriteBox(obj: any): Observable<any[]> {
    return this.apiService.post("favourite/deleteFavouriteBox", obj);
  }

  createGiftBox(boxes: any): Observable<any> {
    return this.apiService.multiplePost("cart/createGiftBox/", boxes);
  }

  makePayment(object: any) {
    return this.apiService.post("cart/makePayment/", object);
  }

  updateBrandingRefId(autoId: number): Observable<any> {
    return this.apiService.post("cart/updateBrandingRefId", { autoId: autoId });
  }

  updateHandwrittenNote(
    autoIds: number[],
    handwrittenNote: string
  ): Observable<any> {
    let requestObj = [];
    autoIds.forEach((autoId) => {
      requestObj.push({ autoId: autoId, handwrittenNote: handwrittenNote });
    });
    return this.apiService.multiplePost(
      "cart/updateHandwrittenNote",
      requestObj
    );
  }

  updateClientPreference(object: any): Observable<any> {
    return this.apiService.post("cart/updateCartClientPreference/", object);
  }
}
