import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';

import { DemoRedirectComponent } from './demo-redirect/demo-redirect.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { RecoverPasswordComponent } from './recover-password/recover-password.component';
import { RedirectGiftPlatformComponent } from './redirect-gift-platform/redirect-gift-platform.component';
import { RedirectOutlookComponent } from './redirect-outlook/redirect-outlook.component';
import { RedirectComponent } from './redirect/redirect.component';
import { Redirectv2Component } from './redirectv2/redirectv2.component';
import { RegisterMortgageComponent } from './register-mortgage/register-mortgage.component';
import { RegisterComponent } from './register/register.component';
import { SigninComponent } from './signin/signin.component';
import { SignoutComponent } from './signout/signout.component';
import { SsoComponent } from './sso/sso.component';
import { DemoGifComponent } from './demos/demo-gif/demo-gif.component';
import { DemoHandwrittenComponent } from './demos/demo-handwritten/demo-handwritten.component';
import { DemoComponent } from './demos/demo/demo.component';
import { GuildRegisterComponent } from './signup-client/guild-register/guild-register.component';
import { LinkedinCallbackComponent } from './linkedin-callback/linkedin-callback.component';
import { TwitterCallbackComponent } from './twitter-callback/twitter-callback.component';
import { RegisterCTAComponent } from './register-cta/register-cta.component';
import { SigninCTAComponent } from './signin-cta/signin-cta.component';
import { SigninHolidayComponent } from './signin-holiday/signin-holiday.component';
import { SignupHolidayComponent } from './signup-holiday/signup-holiday.component';
import { SetUpPasswordComponent } from './set-up-password/set-up-password.component';
import { EvergreenRegisterComponent } from './signup-client/evergreen-register/evergreen-register.component';
import { SideRegisterComponent } from './signup-client/side-register/side-register.component';



@NgModule({
    imports: [SharedModule],
    declarations: [SigninComponent, SigninCTAComponent, SignoutComponent, RegisterComponent,RegisterCTAComponent, RedirectComponent, ForgotPasswordComponent, RecoverPasswordComponent, Redirectv2Component, DemoRedirectComponent, DemoComponent, DemoGifComponent, DemoHandwrittenComponent, RegisterMortgageComponent, RedirectGiftPlatformComponent, GuildRegisterComponent, SsoComponent, LinkedinCallbackComponent, TwitterCallbackComponent, SigninHolidayComponent, SignupHolidayComponent, SetUpPasswordComponent, EvergreenRegisterComponent, SideRegisterComponent],
    exports: [SigninComponent,SigninCTAComponent, SignoutComponent, RegisterComponent, RegisterCTAComponent,  RedirectComponent, ForgotPasswordComponent, RecoverPasswordComponent, Redirectv2Component,DemoRedirectComponent,SetUpPasswordComponent]
})
export class PagesModule { }