import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-branding-handwritten-slider',
  templateUrl: './branding-handwritten-slider.component.html',
  styleUrls: ['./branding-handwritten-slider.component.css']
})
export class BrandingHandwrittenSliderComponent implements OnInit {
  public slides: any;
  public slideConfig: any;

  constructor() { }

  @Input("data") set data(data: any) {
    this.slideConfig = { slidesToShow: data, slidesToScroll: data };
  }

  @Input("slides") set images(images: any) {
    this.slides = images;
  }

  ngOnInit() {
  }

}
