<div class="wrapper">
  <div class="register-content">
    <div class="register-panel">
      <div class="register-text">
        <div class="register-text-inner">
          <div>
            <img
              width="90"
              src="../../../../assets/img/logo/eva-new-logo.png"
            />
          </div>
          <div class="p-3"></div>
          <div
            style="
              letter-spacing: -0.4px;
              color: #302f2f;
              font-size: 24px;
              font-family: 'Poppins Light';
            "
          >
            Say bye to the 'One Size Fits All' approach
          </div>
          <div
            style="
              color: #302f2f;
              font-size: 40px;
              font-family: 'MuseoSlab-300';
            "
          >
            Say Hi to <span style="color: #dd015b">3x more reviews!</span>
          </div>
          <div class="p-3"></div>
          <div>
            <img
              style="width: 100%"
              src="../../../../assets/img/signup/register.gif"
            />
          </div>
          <div class="p-3"></div>
          <div style="font-size: 16px">
            Curious?
            <span style="color: #dd015b"
              >Sign up for free & get $40 off your first gift.</span
            >
            <br />
            7 days free trial. Cancel Anytime.
          </div>
          <div class="p-5"></div>
          <div>
            <span>&copy; 2024 - EvaBot Inc. All Rights Reserved.</span>
          </div>
        </div>
      </div>
      <div class="register-form">
        <div class="register-text-inner">
          <div class="start-text">Sign in to continue</div>
          <div class="p-3"></div>
          <p *ngIf="isInvalid && !IsSSO" class="text-center text-danger">
            Invalid Credentials
          </p>
          <p *ngIf="isInvalid && IsSSO" class="text-center text-danger">
            You can not login using this form. Please sign in with
            <a class="skip-link" [routerLink]="['/sso']">SSO</a> or contact your
            organization's IT team.
          </p>
          <form class="mb-3" (ngSubmit)="onSignIn(f)" #f="ngForm">
            <div class="form-row">
              <div class="form-group col-md-12">
                <label for="user-email">Email</label>
                <input
                  oninput="this.value = this.value.toLowerCase()"
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,64}$"
                  class="form-control"
                  type="email"
                  placeholder="Enter email"
                  name="email"
                  ngModel
                  autocomplete="off"
                  #email="ngModel"
                  [ngClass]="{ 'input-error': isSubmitted && !email.valid }"
                  required
                  email
                />
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-md-12">
                <label for="user-password">Password</label>
                <div class="input-group">
                  <input
                    id="user-password"
                    [type]="keyType"
                    class="form-control border-right-0"
                    ngModel
                    placeholder="Password"
                    name="password"
                    #password="ngModel"
                    [ngClass]="{
                      'input-error': isSubmitted && password.invalid
                    }"
                    autocomplete="off"
                    required
                  />
                  <div
                    class="input-group-addon"
                    style="
                      line-height: 2.5;
                      border: 1px solid #ced4da;
                      border-left: 0;
                      width: 30px;
                      background: inherit;
                      cursor: pointer;
                    "
                    (click)="onShowPassword()"
                  >
                    <i
                      *ngIf="isShowPassword"
                      class="fa fa-eye"
                      aria-hidden="true"
                    ></i>
                    <i
                      *ngIf="!isShowPassword"
                      class="fa fa-eye-slash"
                      aria-hidden="true"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="p-1"></div>
            <p class="pt-1 text-left">
              <a class="link-color" [routerLink]="['/forgotPassword']"
                >Forgot password?</a
              >
            </p>
            <button
              style="font-size: 14px !important; padding: 8px !important"
              class="btn btn-block btn-danger btn-c-account mt-3"
              type="submit"
            >
              Sign In
            </button>
          </form>

          <div class="p-1"></div>
          <p class="line-btw-text"><span>or</span></p>
          <div class="p-1"></div>
          <a
            href="https://client.stage.evabot.com/user/googleLogin"
            style="
              font-size: 14px !important;
              background: #ffffff 0% 0% no-repeat padding-box;
              border: 1px solid #e2e9f2;
              opacity: 1;
              padding: 8px !important;
            "
            class="btn btn-block mt-3 link-color btn-google"
            type="submit"
          >
            <img
              width="15"
              src="../../../../assets/img/signup/google.png"
            />&nbsp;&nbsp;Sign up with Google</a
          >
          <div class="p-2"></div>
          <p class="pt-2 text-center">
            New to Eva?&nbsp;<a
              class="link-color"
              [routerLink]="['/signup-cta']"
              >Sign up here</a
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <p class="text-center m-3">
                        <a href="https://client.stage.evabot.com/user/googleLogin">
                            <img class="google-option-image" src="../../../../assets/img/btn_google_signin_dark_normal_web.png">
                        </a>
                    </p> -->

<!-- <p *ngIf="isInvalid" class="text-center text-danger">{{signUpError}}</p> -->
