const Home = {
    text: 'Home',
    link: '/home',
    icon: 'fas fa-home',
    data: "home",
};

const SendGift = {
    text: 'Send Gift(s)',
    link: '/sendGift/email',
    icon: 'fa fa-gift',
    data: "sendgift",

    submenu: [
        {
            text: 'Create Gift Link',
            link: '/sendGift/createLink',
            data: "createlink",

        },
        {
            text: 'Via Email',
            link: '/sendGift/email',
            data: "email",

        },
        {
            text: 'Via Text',
            link: '/sendGift/text',
            data: "text",

        },
        {
            text: 'Via Universal Link',
            link: '/sendGift/universalLink',
            data: "text",

        },
        // {
        //     text: 'Via iOS app',
        //     link: '/sendGift/ios',
        //     data: "ios",

        // }
    ]
};

const GiftStatus = {
    text: 'Order Status',
    link: '/giftStatus',
    icon: 'fas fa-info-circle',
    data: "giftstatus",

};

const GiftStatusHoliday = {
    text: 'Order Status',
    link: '/giftStatus',
    icon: 'fas fa-info-circle',
    data: "giftstatus",

};

// const exploreGifts = {
//     text: "Explore & Buy Gifts",
//     link: '/exploreGifts',
//     icon: 'fa fa-binoculars',
//     data: "exploregift",

// };

const branding = {
    text: 'Branding',
    link: '/branding',
    icon: 'fa fa-palette',
    data: "branding-not",

};

// const Referral = {
//     text: 'Referral Club',
//     link: '/referral',
//     icon: 'fa fa-users',
//     data: "referral",
//     submenu: [
//         {
//             text: 'Referral Link',
//             link: '/referral',
//             data: "referral link",
//         },
//         {
//             text: 'Rewards',
//             link: '/rewards',
//             data: "rewards",
//         }]

// };

// const directMailer = {
//     text: 'Direct Mailers',
//     link: '/directMailer',
//     icon: 'fa fa-envelope',
//     data: "directmailer",

// };

const AdvancedFeatures = {
    text: 'Advanced Features',
    icon: 'fa fa-rocket',
    data: "advanced",

    submenu: [
        {
            text: 'Gift Scheduler',
            link: '/campaign/list',
            data: "campaign",

        },
        {
            text: 'Automated Gifts',
            link: '/automatedGift',
            data: "automated gift",

        },
        {
            text: 'Team Management',
            link: '/team/list',
            data: "team",

        },
        {
            text: 'Customer Data',
            link: '/customerData',
            data: "customer",

        },
        {
            text: 'Integrations',
            link: '/integration',
            data: "zapier",

        }
    ]
};

const AdvancedFeaturesCivic = {
    text: 'Advanced Features',
    icon: 'fa fa-rocket',
    data: "advanced",

    submenu: [
        {
            text: 'Gift Scheduler',
            link: '/campaign/list',
            data: "campaign",
        },
        {
            text: 'Automated Gifts',
            link: '/automatedGift',
            data: "automated gift",
        },
        // {
        //     text: 'Surprise Gift(s)',
        //     link: '/surpriseGift',
        //     data: "civicList",
        // },
        {
            text: 'Team Management',
            link: '/team/user',
            data: "team",

        },
        // {
        //     text: 'Customer Data',
        //     link: '/customerData',
        //     data: "customer",

        // },
        {
            text: 'Integrations',
            link: '/integration',
            data: "zapier",

        }
    ]
};


const Settings = {
    text: 'Settings',
    icon: 'fa fa-cog',
    link: "/giftSettings",
    data: "setting",

    submenu: [
        {
            text: 'My Plan',
            link: '/myPlan',
            data: "myplan",

        },
        {
            text: 'Gift Settings',
            link: '/giftSettings',
            data: "giftsetting",

        },
        {
            text: "Profile Settings",
            link: '/profile',
            data: "profile",

        },
        // {
        //     text: 'My Wishlist',
        //     link: '/wishlist',
        //     data: "wishlist",

        // },
        // {
        //     text: 'Addresses',
        //     link: '/addresses',
        //     data: "addressess",

        // },
        {
            text: 'Payment Methods',
            link: '/card',
            data: "card",

        },
        {
            text: 'Invoices',
            link: '/invoice',
            data: "invoice",

        },
        {
            text: 'Custom Templates',
            link: '/emailTemplate',
            data: "emailtemplate",

        }
    ]
};


const SettingsBDAP = {
    text: 'Settings',
    icon: 'fa fa-cog',
    link: "/giftSettings",
    data: "setting",

    submenu: [
        {
            text: 'My Plan',
            link: '/myPlan',
            data: "myplan",

        },
        {
            text: 'Gift Settings',
            link: '/giftSettings',
            data: "giftsetting",

        },
        {
            text: "Profile Settings",
            link: '/profile',
            data: "profile",

        },
        // {
        //     text: 'My Wishlist',
        //     link: '/wishlist',
        //     data: "wishlist",

        // },
        // {
        //     text: 'Addresses',
        //     link: '/addresses',
        //     data: "addressess",

        // },
        {
            text: 'Payment Methods',
            link: '/card',
            data: "card",

        },
        {
            text: 'Invoices',
            link: '/invoice',
            data: "invoice",

        },
        {
            text: 'Custom Templates',
            link: '/emailTemplate',
            data: "emailtemplate",

        },
       
        {
            text: 'BDAP',
            link: '/bdap',
            data: "bdap",
        }
    ]
};

const SettingsHoliday = {
    text: 'Settings',
    icon: 'fa fa-cog',
    link: "/giftSettings",
    data: "setting",

    submenu: [
        {
            text: 'My Plan',
            link: '/myPlan',
            data: "myplan",

        },
        {
            text: 'Gift Settings',
            link: '/giftSettings',
            data: "giftsetting",

        },
        {
            text: "Profile Settings",
            link: '/profile',
            data: "profile",

        },
       
        {
            text: 'Customer Data',
            link: '/customerData',
            data: "customer",

        },
        {
            text: 'Payment Methods',
            link: '/card',
            data: "card",

        },
        {
            text: 'Invoices',
            link: '/invoice',
            data: "invoice",

        },
        {
            text: 'Custom Templates',
            link: '/emailTemplate',
            data: "emailtemplate",

        },
        {
            text: 'Integrations',
            link: '/integration',
            data: "zapier",

        },
        {
            text: 'BDAP',
            link: '/bdap',
            data: "bdap",
        }
    ]
};

const SettingsHolidayNoPayment = {
    text: 'Settings',
    icon: 'fa fa-cog',
    link: "/giftSettings",
    data: "setting",

    submenu: [
        {
            text: 'My Plan',
            link: '/myPlan',
            data: "myplan",

        },
        {
            text: 'Gift Settings',
            link: '/giftSettings',
            data: "giftsetting",

        },
        {
            text: "Profile Settings",
            link: '/profile',
            data: "profile",

        },
       
        {
            text: 'Customer Data',
            link: '/customerData',
            data: "customer",

        },
       
        {
            text: 'Invoices',
            link: '/invoice',
            data: "invoice",

        },
        {
            text: 'Custom Templates',
            link: '/emailTemplate',
            data: "emailtemplate",

        },
        {
            text: 'Integrations',
            link: '/integration',
            data: "zapier",

        },
        {
            text: 'BDAP',
            link: '/bdap',
            data: "bdap",
        }
    ]
};


const FAQ = {
    text: 'Help Center',
    link: '/faq',
    icon: 'fa fa-question-circle',
    data: "faq",
    class: "fix-menu-option"

};


const LogOut = {
    text: 'Log Out',
    link: "/logout",
    event: 'onLogout()',
    icon: 'fas fa-sign-out-alt',
    data: "logout",
    class: "fix-menu-option"

};

// export const menuCivic = [
//     Home, SendGift, GiftStatus, AdvancedFeaturesCivic, Settings, FAQ, LogOut
// ];




const SendGiftHolidayUTL = {
    text: 'Send gift(s)',
    link: '/sendGift/ugl',
    icon: 'fa fa-gift',
    data: "sendgift",

    submenu: [
        {
            text: 'Via Link',
            link: '/sendGift/createLink',
            data: "createlink",

        },
        {
            text: 'Via Universal Link',
            link: '/sendGift/ugl',
            data: "sendgift",

        },
        {
            text: 'Campaign',
            link: '/campaign/list',
            data: "campaign",

        },
        {
            text: 'Automated Gifts',
            link: '/automatedGift',
            data: "automated gift",

        },
    ]
};


const SendGiftHoliday = {
    text: 'Send gift',
    link: '/sendGift/ugl',
    icon: 'fa fa-gift',
    data: "sendgift",

    submenu: [
        {
            text: 'Via Link',
            link: '/sendGift/createLink',
            data: "createlink",

        },
        {
            text: 'Via Universal Link',
            link: '/sendGift/ugl',
            data: "sendgift",

        },
        {
            text: 'Campaign',
            link: '/campaign/list',
            data: "campaign",

        },
        {
            text: 'Automated Gifts',
            link: '/automatedGift',
            data: "automated gift",

        },
    ]
};




// const premiumFeatures = {
//     text: "Premium features",
//     link: '/premiumFeatures',
//     icon: 'fa fa-rocket',
//     data: "premiumFeatures",

// };

// const sendGiftViaLink = {
//     text: 'Create gift link',
//     link: '/sendGift/createLink',
//     icon: 'fa fa-link',
//     data: "createlink",
// }

const teamManagement = {
    text: 'Team Management',
    link: '/team/list',
    icon: 'fa fa-users',
    data: "team",
}


export const menu = [
    Home, SendGift, GiftStatus, branding, AdvancedFeatures, Settings, LogOut
];

export const menuBDAP = [
    Home, SendGift, GiftStatus,branding, AdvancedFeatures, SettingsBDAP, LogOut
];


export const menuHolidayGiftingPlanUTL = [
    Home, SendGiftHolidayUTL, GiftStatusHoliday, teamManagement, branding, SettingsHoliday, LogOut
];

export const menuHolidayGiftingPlan = [
    Home, SendGiftHolidayUTL, GiftStatusHoliday, teamManagement, branding, SettingsHoliday, LogOut
];

export const menuHolidayGiftingPlanNOPayment = [
    Home, SendGiftHolidayUTL, GiftStatusHoliday, teamManagement, branding, SettingsHolidayNoPayment, LogOut
];





