import { Injectable } from "@angular/core";

import { map } from "rxjs/operators";
import { Observable, BehaviorSubject } from "rxjs";

import { Price } from "../models/price.model";

import { ApiService } from "./api.service";

@Injectable()
export class PriceService {
  private priceSubject = new BehaviorSubject<Price[]>([] as Price[]);
  public priceList = this.priceSubject.asObservable();
  private prices: Price[] = [];
  private _defaultPriceId: number;
  private _firstGiftPriceId: number;

  constructor(private apiService: ApiService) {
  }

  fetchPriceList() {
    this.apiService
      .post("price/getPriceList", {})
      .pipe(
        map((data:any) => {
          const prices: Price[] = data.prices;
          this.prices = prices;
          this._defaultPriceId =
            prices[prices.findIndex(price => price.IsDefault === 1)].PriceId;
          this._firstGiftPriceId =
            prices[prices.findIndex(price => price.ChargedAmount == 30)].PriceId;
          return prices;
        })
      )
      .subscribe(prices => {
        this.priceSubject.next(prices);
      });
  }

  get defaultPriceId(): number {
    return this._defaultPriceId;
  }

  get firstGiftPriceId(): number {
    return this._firstGiftPriceId;
  }

  getPriceByPriceId(priceId: number): number {
    return this.prices[
      this.prices.findIndex(price => price.PriceId === priceId)
    ].Price;
  }

  getPriceIdByPriceValue(value: number): number {
    return this.prices[
      this.prices.findIndex(price => price.ChargedAmount === value)
    ].PriceId;
  }


  getPrice(priceId: number): Price {
    return this.prices[
      this.prices.findIndex(price => price.PriceId === priceId)
    ];
  }

  updateOrderPrice(orderNumber: number, priceId: number): Observable<any> {
    return this.apiService.post(
      "order/updatePrice", { urlId: orderNumber, priceId: priceId }
    );
  }

  updateDefaultPrice(priceId: number): Observable<Price[]> {
    return this.apiService.post("price/updateDefaultPrice", { priceId: priceId }).pipe(
      map((data:any) => {
        const prices: Price[] = data.prices;
        this.prices = prices;
        this._defaultPriceId =
          prices[prices.findIndex(price => price.IsDefault === 1)].PriceId;
        this.priceSubject.next(prices);
        return prices;
      })
    );
  }
}
