<div class="wrapper">
    <div *ngIf="isLinkExpired; else recoverPasswordTemplate" style="text-align: center; font-size: 18px; margin-top: 5%; ">Your Link to reset password is either expired or invalid, please
        <a [routerLink]="['/forgotPassword']">request a new one</a>.</div>

    <ng-template #recoverPasswordTemplate>
        <div class="block-center mt-4 wd-xl">
            <!-- START card-->

            <div class="card card-flat">
                <div class="card-header text-center eva-logo-bg">
                    <a href="#">
                        <img style="width: 200px; image-rendering: auto;max-width: 200px !important;" class="block-center rounded eva-logo" src="../../../../assets/img/logo/joc.png" alt="Image">
                    </a>
                </div>
                <div class="card-body">
                    <p class="text-center py-2">RESET PASSWORD</p>
                    <p *ngIf="isReset" class="text-center text-success">Password reset successfully</p>
                    <form class="mb-3" (ngSubmit)="onChangePassword(f)" #f="ngForm">
                        <div class="form-group">
                            <div class="input-group with-focus">
                                <div class="input-group-prepend">
                                    <span [ngClass]="{'input-error':isSubmitted && password.invalid}" class="input-group-text text-muted bg-transparent border-right-0">
                    <em class="fa fa-lock"></em>
                  </span>
                                </div>
                                <input [type]="keyType" pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-+=@$#^!%*?()&.<>{}[]|;:''?`~/])[A-Za-z\d-+=@$#^()!%*?&.<>{}[]|;:''?`~/]$" class="form-control border-left-0" ngModel type="password" placeholder="New Password" name="password" autocomplete="new-password"
                                    #password="ngModel" [ngClass]="{'input-error':isSubmitted && password.invalid}" required>
                                <div class="input-group-append cursor-pointer" (click)="onShowPassword()">
                                    <span [ngClass]="{'input-error':isSubmitted && password.invalid}" class="input-group-text text-muted bg-transparent border-left-0">
                    <i *ngIf="isShowPassword" class="fa fa-eye" aria-hidden="true"></i>
                    <i *ngIf="!isShowPassword" class="fa fa-eye-slash" aria-hidden="true"></i>
                  </span>
                                </div>
                            </div>
                            <div *ngIf="isSubmitted && password.invalid">
                                <div>Password must contain the following:</div>
                                <div [ngClass]="{'error-message': !(lowercaseRegex.test(password.value)), 'success-message':lowercaseRegex.test(password.value)}">
                                    <span *ngIf="lowercaseRegex.test(password.value)" class="fa fa-check"></span><span *ngIf="!(lowercaseRegex.test(password.value))" class="fa fa-times"></span>&nbsp;A lowercase letter
                                </div>
                                <div [ngClass]="{'error-message': !(uppercaseRegex.test(password.value)), 'success-message':uppercaseRegex.test(password.value)}">
                                    <span *ngIf="uppercaseRegex.test(password.value)" class="fa fa-check"></span><span *ngIf="!(uppercaseRegex.test(password.value))" class="fa fa-times"></span>&nbsp;A Capital (uppercase) letter</div>
                                <div [ngClass]="{'error-message': !(numberRegex.test(password.value)), 'success-message':numberRegex.test(password.value)}">
                                    <span *ngIf="numberRegex.test(password.value)" class="fa fa-check"></span><span *ngIf="!(numberRegex.test(password.value))" class="fa fa-times"></span>&nbsp;A Number
                                </div>
                                <div [ngClass]="{'error-message': !(specialcharcterRegex.test(password.value)), 'success-message':specialcharcterRegex.test(password.value)}">
                                    <span *ngIf="specialcharcterRegex.test(password.value)" class="fa fa-check"></span><span *ngIf="!(specialcharcterRegex.test(password.value))" class="fa fa-times"></span>&nbsp;A special character
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="input-group with-focus">
                                <div class="input-group-prepend">
                                    <span [ngClass]="{'input-error':isSubmitted && (password.value!=cpassword.value)}" class="input-group-text text-muted bg-transparent border-right-0">
                    <em class="fa fa-lock"></em>
                  </span>
                                </div>
                                <input class="form-control border-left-0" ngModel type="password" placeholder="Confirm Password" name="cpassword" #cpassword="ngModel" [ngClass]="{'input-error':isSubmitted && (password.value!=cpassword.value)}" required>

                            </div>
                            <span *ngIf="isSubmitted && (password.value!=cpassword.value)" class="error-message">passwords do not
                match</span>

                        </div>
                        <div class="clearfix">
                        </div>
                        <button class="btn btn-block btn-danger mt-3" type="submit">ChangePassword</button>
                    </form>
                    <p class="pt-3 text-center">Need to Signup?</p>
                    <a class="btn btn-block btn-secondary" [routerLink]="['/login']">Sign in to your account</a>
                </div>
            </div>
            <!-- END card-->
            <div class="p-3 text-center">
                <span>&copy; 2024 - EvaBot Inc. All Rights Reserved.</span>
            </div>
        </div>
    </ng-template>
</div>