import { Injectable, PLATFORM_ID, Inject, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { isPlatformBrowser } from "@angular/common";

import { Observable, BehaviorSubject, ReplaySubject } from "rxjs";
import { map } from "rxjs/operators";

import { Subscription } from "../models/subscription";
import { User, UserType } from "../models/user.model";
import { AppConstants } from "../../shared/app.constant";
import { Helper } from "../../shared/helper";

import { ApiService } from "./api.service";

declare var window: any;
declare var require: any
const Swal = require("sweetalert2");
// declare var stonlyTrack: any;


@Injectable()
export class UserService {
  private currentUserSubject = new BehaviorSubject<User>({} as User);
  public currentUser = this.currentUserSubject.asObservable().pipe();
  private user: User;
  private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
  public isAuthenticated = this.isAuthenticatedSubject.asObservable();
  private paymentStatusSubject = new ReplaySubject<number>(1);
  public paymentStatus = this.paymentStatusSubject.asObservable();
  private isUnPaidSubject = new ReplaySubject<boolean>(1);
  public isPremiumUser = this.isUnPaidSubject.asObservable();

  constructor(
    private apiService: ApiService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  populate() {
    if (isPlatformBrowser(this.platformId) && localStorage.getItem("sessionId"))
      this.apiService.post("user/getCurrentUser/", {}).subscribe(
        (data) => {
          const user: User = data.user[0][0];
          user.Settings = data.user[1][0];
          localStorage.setItem("sessionId", user.TokenNumber);
          localStorage.setItem("uAccessToken", data.token.accessToken)
          this.setAuth(user);
        },
        (error) => {
          this.purgeAuth();
        }
      );
    else {
      this.paymentStatusSubject.next(0);
      if (isPlatformBrowser(this.platformId)) {
        let pathname = window.location.pathname + "";
        let location = window.location + "";
        if (location.indexOf("?") != -1) {
          let value = location.split("?")[1];
          localStorage.setItem("queryParams", value);
        }
        if (
          pathname.indexOf("login") == -1 &&
          pathname.toLowerCase().indexOf("verifyemail") == -1 &&
          pathname.toLowerCase().indexOf("redirect") == -1 &&
          pathname.toLowerCase().indexOf("eva-demo") == -1
        ) {
          localStorage.setItem(
            "navigationUrl",
            "/" + pathname.substring(1, pathname.length).split("/").join("||")
          );
          if (pathname.toLowerCase().indexOf("limitedtimeoffer") != -1) {
            localStorage.setItem("isd", "1");
            this.router.navigate(["/signup"], {
              queryParams: { ic: "limitedtimeoffer" },
            });
          }
        }
      }
      this.purgeAuth();
    }
  }

  setAuth(user: User) {
    this.user = user;
    this.user.subscription = new Subscription(user.PlanName, user.PlanType, user.PlanFeatures);

    this.currentUserSubject.next(this.user);

    try {
      this.apiService.post("user/getDevRevToken", { UserId: user.UserId + "", Name: user.Name + "", Email: user.Email + "" }).subscribe(
        (data) => {
          window.plugSDK.init({
            app_id: 'don:core:dvrv-us-1:devo/62PvGO4n:plug_setting/1',
            session_token: data.sessionToken
          });
        })
    }
    catch (error) { }

    //   email: user.Email,
    //   name: user.Name,
    // });
    //stonlyTrack('identify', user.Email);

    this.isAuthenticatedSubject.next(true);
    this.paymentStatusSubject.next(user.PaymentStatus);
    if (Helper.IsGuildUser(user.Email)) {
      this.router.navigate(Helper.guildRoute(user.HomeActivity, user.IsSetUp));
    }
    else if (Helper.IsSVBUser(user.Email)) {
      this.router.navigate(Helper.svbRoute(user.Email, user.IsSetUp));
    }
    // else if (
    //   this.user.HomeActivity.indexOf("remoteuser") != -1 &&
    //   this.user.PlanName == "free"
    // )
    //   this.router.navigate(["/employeegifts", "plan"]);
    // else if (this.user.IsSetUp == 0 && localStorage.getItem("obv2") && this.user.CustomerId != "") {
    //   let stepNo = this.user.HomeActivity.includes("[ugl_created]") ? ['obv2', 'step3'] : ['obv2', 'step2'];
    //   this.router.navigate(stepNo);
    // }
    // else if (this.user.IsSetUp == 0 && localStorage.getItem("obv2")) {
    //   let stepNo = this.user.HomeActivity.includes("[ugl_created]") ? ['obv2', 'step3'] : ['obv2', localStorage.getItem("isSendAllHolidayGifts") ? 'step2' : 'firstgift'];
    //   this.router.navigate(stepNo);
    // }
    // else if (
    //   this.user.IsSetUp == 0 &&
    //   !localStorage.getItem("dmPlan") &&
    //   !localStorage.getItem("ic") &&
    //   !localStorage.getItem("redirect")
    // ) {
    //   let obPrex = localStorage.getItem("ob");
    //   if (obPrex == null) obPrex = "/ob";
    //   this.router.navigate([obPrex, "step1"]);
    // }
  }

  registerUser(object: any): Observable<any> {
    return this.apiService.post("user/registerUser", object).pipe(
      map((data: any) => {
        if (!data.user.Message) {
          const token = data.user.TokenNumber;
          if (isPlatformBrowser(this.platformId)) {
            localStorage.setItem("sessionId", token);
            localStorage.setItem("uAccessToken", data.token.accessToken);
            // gtag("event", "conversion", {
            //   send_to: "AW-822217819/i9uPCIjygqIBENuYiIgD",
            //   transaction_id: "",
            // });
            // gtag("event", "conversion", {
            //   send_to: "AW-822217819/I1KoCJak9bYBENuYiIgD",
            // });
          }
        }
        return data.user;
      })
    );
  }

  attemptAuth(credentials: object) {
    localStorage.removeItem("sessionId");
    localStorage.removeItem("uAccessToken");
    return this.apiService.post("user/login", credentials).pipe(
      map((data: any) => {
        if (!data.error) {
          localStorage.setItem("uAccessToken", data.token.accessToken);
          const user: User = data.user[0][0];
          user.Settings = data.user[1][0];
          if (isPlatformBrowser(this.platformId))
            localStorage.setItem("sessionId", user.TokenNumber);
          localStorage.setItem("uAccessToken", data.token.accessToken);
          this.setAuth(user);
          return user;
        }
        else {
          return data;
        }
      })
    );
  }

  purgeAuth() {
    if (isPlatformBrowser(this.platformId))
      localStorage.removeItem("sessionId");
    this.currentUserSubject.next({} as User);
    this.isAuthenticatedSubject.next(false);
  }

  getCurrentUser(): User {
    return this.currentUserSubject.value;
  }

  updateOnBoardingStatus(isPanelUpdate: boolean) {
    this.apiService
      .post("user/updateOnBoardingStatus", {})
      .pipe(
        map((data) => {
          if (isPanelUpdate) {
            this.user.IsSetUp = 1;
            this.currentUserSubject.next(this.user);
          }
        })
      )
      .subscribe();
  }

  updateRenewBrandingStatus(value: number): Observable<any> {
    return this.apiService.post("user/updateRenewBrandingStatus", { value: value }).pipe(
      map((data) => {
        this.user.Settings.IsAutoRenewBranding = value;
        this.currentUserSubject.next(this.user);
      })
    );
  }

  updateRenewMailerStatus(value: number): Observable<any> {
    return this.apiService.post("user/updateRenewMailerStatus", { value: value }).pipe(
      map((data) => {
        this.user.Settings.IsDirectMailAutoRenew = value;
        this.currentUserSubject.next(this.user);
      })
    );
  }

  updateUserDetails(user: object): Observable<any> {
    return this.apiService.post("user/updateUserDetails", user).pipe(
      map((data: any) => {
        const user: any = data.user;
        this.user.Name = user.Name;
        this.user.CompanyName = user.CompanyName;
        this.user.Image = user.Image;
        this.user.Website = user.Website;
        this.user.AboutUs = user.AboutUs;
        this.user.UserSignature = user.UserSignature;
        this.user.IsPersonalDashboard = user.IsPersonalDashboard;
        this.currentUserSubject.next(this.user);
        return user;
      })
    );
  }

  changePassword(body: object): Observable<any> {
    return this.apiService.post("user/changePassword", body).pipe(
      map((response: any) => {
        const status = response.data;
        return status;
      })
    );
  }

  recoverPassword(body: object): Observable<any> {
    return this.apiService.post("user/recoverPassword", body).pipe(
      map((response: any) => {
        const status = response.data;
        return status;
      })
    );
  }

  validateForgotPasswordPage(body: object): Observable<any> {
    return this.apiService.post("user/validateForgotPasswordPage", body).pipe(
      map((response) => {
        const status = response;
        return status;
      })
    );
  }

  updateEmailPreferences(preferences: any): Observable<any> {
    return this.apiService
      .post("user/updateEmailPreferences", preferences)
      .pipe(
        map((data: any) => {
          const emailPreferences: any = data.preferences;
          this.user.IsMarketingEmail = emailPreferences.IsMarketingEmail;
          this.user.IsFeatureUpdate = emailPreferences.IsFeatureUpdate;
          this.user.IsGiftingAlert = emailPreferences.IsGiftingAlert;
          this.user.IsUnsubscribeALLEmail =
            emailPreferences.IsUnsubscribeALLEmail;
          this.currentUserSubject.next(this.user);
          return emailPreferences;
        })
      );
  }

  getChildUserList(): Observable<User[]> {
    return this.apiService.post("user/getChildUserList", {}).pipe(
      map((data: any) => {
        const users = data.users;
        return users;
      })
    );
  }

  sendMobileOTP(mobileNumber: string): Observable<any> {
    return this.apiService.post("user/sendMobileOTP/", { mobileNumber: mobileNumber }).pipe(
      map((result: any) => {
        const data = result.data;
        return data;
      })
    );
  }

  verifyOTP(mobileNumber: string, OTP: string): Observable<any> {
    return this.apiService
      .post("user/verifyOTP", { mobileNumber: mobileNumber, otp: OTP })
      .pipe(
        map((data: any) => {
          if (!data.error) {
            this.user.MobileNumber = mobileNumber;
            this.currentUserSubject.next(this.user);
          }
          return data;
        })
      );
  }

  sendForgotPasswordEmailer(email: string): Observable<any> {
    return this.apiService.post("user/sendForgotPasswordEmail", {
      email: email,
    });
  }

  getUserByHashId(id: string): Observable<any> {
    return this.apiService.post("user/getUserByHashId/", { hashId: id }).pipe(
      map((data: any) => {
        const user: User = data.user[0][0];
        return user;
      })
    );
  }

  updateHandwrittenSettings(isHandwritten: number) {
    this.user.Settings.IsHandwritten = isHandwritten;
    this.currentUserSubject.next(this.user);
  }

  getGoogleData(obj: any): Observable<any> {
    return this.apiService.post("user/getGoogleData", obj).pipe(
      map((result: any) => {
        if (!result.error) {
          this.user.RefreshToken = result.data.RefreshToken;
          this.user.RefreshTokenType = 1;
          this.user.UserSignature = result.data.UserSignature;
          this.currentUserSubject.next(this.user);
        }
        return result;
      })
    );
  }

  createGoogleAccount(obj: any): Observable<any> {
    return this.apiService.post("user/createGoogleAccount", obj).pipe(
      map((data: any) => {
        let user: User;
        if (data.error && data.error == 1) {
          Swal.fire({
            icon: 'error',
            title: '',
            text: 'Kindly reach out to Amerifirst Marketing Team (marketing@amerifirst.com) to create an account with Evabot.',
            confirmButtonColor: AppConstants.CONFIRM_BUTTON_COLOR
          });
          setTimeout(() => {
            this.router.navigate(["/login"]);
          }, 100);
        }
        else if (data.error && data.error == "account_not_exist") {
          this.router.navigate(["/login"], { queryParams: { error: "1" } });
        }
        else if (data.user[15]) {
          user = data.user[13][0];
          user.Settings = data.user[14][0];
          // gtag("event", "conversion", {
          //   send_to: "AW-822217819/i9uPCIjygqIBENuYiIgD",
          //   transaction_id: "",
          // });
          // gtag("event", "conversion", {
          //   send_to: "AW-822217819/I1KoCJak9bYBENuYiIgD",
          // });
        } else {
          user = data.user[0][0];
          user.Settings = data.user[1][0];
        }

        if (isPlatformBrowser(this.platformId)) {
          localStorage.setItem("sessionId", user.TokenNumber);
          localStorage.setItem("uAccessToken", data.token.accessToken);
        }
        if (user.Name != "") this.setAuth(user);
        return user;
      })
    );
  }

  updateUserReference(user: User) {
    this.user = user;
    this.currentUserSubject.next(this.user);
  }

  updateCustomerId(cusomterId: string) {
    this.user.CustomerId = cusomterId;
    this.currentUserSubject.next(this.user);
  }

  updatePaymentStatus() {
    this.paymentStatusSubject.next(0);
  }

  saveUserDetails(obj: any): Observable<any> {
    return this.apiService.post("user/saveUserDetails", obj).pipe(
      map((data: any) => {
        const user: User = data.user[0][0];
        user.Settings = data.user[1][0];
        this.setAuth(user);
        return user;
      })
    );
  }

  changeUserEmail(obj: any): Observable<any> {
    return this.apiService.post("user/updateUserEmail", obj);
  }

  IsSuperAdmin(): boolean {
    return this.currentUserSubject.value.UserType == UserType.SUPERADMIN;
  }

  IsAdvancedAdmin(): boolean {
    return this.currentUserSubject.value.UserType == UserType.ADMIN && this.currentUserSubject.value.subscription.IsAdvancedTeamManagement;
  }

  IsSimpleAdmin(): boolean {
    return this.currentUserSubject.value.UserType == UserType.ADMIN && !this.currentUserSubject.value.subscription.IsAdvancedTeamManagement;
  }

  IsSimpleTeamMember(): boolean {
    return this.currentUserSubject.value.UserType == UserType.CHILDUSER && !this.currentUserSubject.value.subscription.IsAdvancedTeamManagement;;
  }

  IsAdvancedTeamMember(): boolean {
    return this.currentUserSubject.value.UserType == UserType.CHILDUSER && this.currentUserSubject.value.subscription.IsAdvancedTeamManagement;
  }

  verifyUserEmail(obj: any): Observable<any> {
    return this.apiService.post("user/verifyUserEmail", obj).pipe(
      map((data) => {
        this.purgeAuth();
        return data;
      })
    );
  }

  disconnectGoogle(): Observable<any> {
    return this.apiService.post("user/disconnectGoogle", {}).pipe(
      map((data: any) => {
        this.user.RefreshToken = "";
        this.user.CalenderEmail = this.user.Email;
        this.user.UserSignature = data.data.UserSignature;
        this.currentUserSubject.next(this.user);
        return data;
      })
    );
  }

  updateGiftCredits(): Observable<any> {
    return this.apiService.post("user/updateGiftCredits", {});
  }

  getRewardsDetails(): Observable<any> {
    return this.apiService.post("user/getRewardDetails", {});
  }

  addChildUser(obj: any): Observable<User[]> {
    return this.apiService.post("user/addChildUser", obj);
  }

  acceptGuidlines(obj: any): Observable<any> {
    return this.apiService.post("user/acceptGuidelines", obj);
  }

  acceptHomeActivityTag(obj: any): Observable<any> {
    return this.apiService.post("user/acceptHomeActivityTag", obj);
  }

  updateCurrentUser() {
    this.apiService.post("user/getCurrentUser/", {}).subscribe(
      (data) => {
        const user: User = data.user[0][0];
        user.Settings = data.user[1][0];
        user.subscription = new Subscription(user.PlanName, user.PlanType, user.PlanFeatures);
        this.user = user;
        this.currentUserSubject.next(user);
      },
    );
  }

  updateOutlookProfile(obj: any): Observable<any> {
    return this.apiService.post("user/updateOutlookProfile", obj);
  }

  verifySSOToken(obj: any): Observable<any> {
    return this.apiService.post("user/verifySSOToken", obj);
  }

  validateDomain(obj: any): Observable<any> {
    return this.apiService.post("user/validateDomain", obj);
  }

  changeNamePrefrencesForClentEmails(obj: any): Observable<any> {
    return this.apiService.post("user/changeNamePrefrencesForClientEmails", obj);
  }

  addBDAPRequest(obj: any): Observable<any> {
    return this.apiService.post("user/addBDAPRequest", obj);
  }

  getBDAPDetails(): Observable<any> {
    return this.apiService.post("user/getBDAPDetails", {});
  }

  sendAmerifirstSignUpAlert(obj: any): Observable<any> {
    return this.apiService.post("user/sendAmerifirstSignUpAlert", obj);

  }

  updateOnBoardingUseCases(obj: any): Observable<any> {
    return this.apiService.post("user/updateOnBoardingUseCases", obj);

  }

  updatehomeActivity(obj: any): Observable<any> {
    return this.apiService.post("user/updatehomeActivity", obj).pipe(
      map((data) => {
        localStorage.removeItem("hpjgoogle");
        this.user.HomeActivity = this.user.HomeActivity + "," + obj.activity
        this.updateUserReference(this.user);
      })
    );
  }

  removeHomeActivity(obj: any): Observable<any> {
    return this.apiService.post("user/updatehomeActivity", obj).pipe(
      map((data) => {
        this.user.HomeActivity = this.user.HomeActivity.replace(obj.activity, "");
        this.updateUserReference(this.user);
      })
    );
  }

  updateWebhook(obj: any): Observable<any> {
    return this.apiService.post("user/updateWebhook", obj);

  }
}
