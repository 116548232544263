<sc-modal id="modal-4" class="default" #modal4>
  <div style="display:block !important" class="modal">
    <div class="eva-header">Congratulations! You are upgraded to Eva 2.0</div>
    <div class="text-center p-3"><img style="width:80px" src="../../../../assets/img/confetti.png"></div>
    <div>
      <ul>
        <li>Fully mobile optimized</li>
        <li>Faster. Better.</li>
        <li>New Feature --> access to clients' preferences and addresses.</li>
        <li>New Feature --> Explore & buy gifts based on 20,000+ data points</li>
        <li>Better on-boarding</li>
      </ul>
    </div>
    <div class="u-info">
      Take a moment to go through all the menu options to get familiar with the new dashboard. You can check out the
      <b>new FAQs section</b> too. If you have any other questions, feel free to chat with us.
    </div>
    <div class="text-center mt-4"><button class="btn btn-danger btn-next" (click)="onClose()">Got it</button></div>
    <div class="text-center text-info font-italic p-3">This notification will come for the next 3 times you login</div>
  </div>

  <div class="modal-background"></div>
</sc-modal>