<!-- <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
  <div ngxSlickItem *ngFor="let slide of slides" class="slide-container">
    <img class="postcard" src="{{ slide }}" alt="">
  </div>
</ngx-slick-carousel> -->

<ngx-slick-carousel class="carousel"
  #slickModal="slick-carousel" [config]="slideConfig">
  <div ngxSlickItem *ngFor="let slide of slides" class="slide">
    <img src="{{ slide }}" alt="" style="width: 100%">
  </div>
</ngx-slick-carousel>
