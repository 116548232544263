import { Settings } from "./settings.model";
import { Subscription } from "./subscription";

export interface User {
  UserId?: number;
  ParentId: number;
  Name: string;
  FirstName: string;
  Email: string;
  TokenNumber: string;
  MobileNumber: string;
  CompanyName: string;
  Image: string;
  IsMarketingEmail: boolean;
  IsFeatureUpdate: boolean;
  IsGiftingAlert: boolean;
  IsUnsubscribeALLEmail: boolean;
  InviteCode: string;
  IsSetUp: number,
  Days: number,
  RefreshToken: string;
  RefreshTokenType: number;
  CalenderEmail: string;
  TotalLogin: number;
  RegisteredOn: string;
  UserType: string;
  PlanName: string;
  PlanType: string;
  PlanAmount: number;
  PlanTrialDays: number;
  PlanCardId: string;
  PlanFeatures: string;
  GiftsLimit: number;
  IsTrialValid: number;
  PlanNextChargedDate: string;
  ParentName: string;
  ParentEmail: string;
  SubscriptionType: string;
  PaymentStatus: number;
  CustomerId: string;
  BrandingStatus: string;
  TotalBrandingValue: number;
  Settings: Settings;
  HomeActivity: string;
  ReferredBy: string;
  Website: string;
  AboutUs: string;
  SubscriptionId: String;
  IsPersonalDashboard: number;
  ProfileValue: string;
  UserSignature: string;
  subscription: Subscription;
  SubscribedOn: string;
  Industry: string;
  ReferralFrequency1: number;
  ReferralFrequency2: number;
  WebhookUrl: string;
  TeamName: string;
  AccountType: string,
  CardType: number,
  TeamAdminEmail:string

}


export enum UserType {
  ADMIN = "admin",
  SUPERADMIN = "superadmin",
  CHILDUSER = "user"
}