import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';

import { UserService } from '../../../core/services/user.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  public isSubmitted: boolean = false;
  public isEmailSent: boolean = false;
  public isInvalid: boolean = false;
  public IsSSO:boolean = false;
  constructor(private userService: UserService) { }

  ngOnInit() {
  }

  onReset(f: NgForm) {
    this.isSubmitted = true;
    if (f.valid) {
      this.userService.sendForgotPasswordEmailer(f.value.email).subscribe(
        (data) => {
          if (data.error) {
            this.isInvalid = true;
          }
          else {
            if (data.message == "1")
            {
              this.isEmailSent = true;
              this.IsSSO=true;
            }
            else
            {
              this.isEmailSent = true;
              this.IsSSO=false;
            }
              
          }
        }
      );
    }
  }

}
