import { Component, OnInit, OnDestroy, Inject, PLATFORM_ID } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { NgForm } from '@angular/forms';
import { isPlatformBrowser } from '@angular/common';

import { Md5 } from 'ts-md5';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AppConstants } from '../../../shared/app.constant';
import { Helper } from '../../../shared/helper';
import { User } from '../../../core/models/user.model';
import { TeamService } from '../../../core/services/team.service';
import { UserService } from '../../../core/services/user.service';


declare var require: any
const Swal = require("sweetalert2");

@Component({
  selector: 'app-register-cta',
  templateUrl: './register-cta.component.html',
  styleUrls: ['../../on-boarding/on-boarding.css'],
  providers: [TeamService]
})

export class RegisterCTAComponent implements OnInit, OnDestroy {
  public isSubmitted: boolean = false;
  public isInvalid: boolean = false;
  private ngUnsubscribe = new Subject();
  public inviteCode: string;
  private orderHash: string;
  public userName: string;
  public userEmail: string;
  public cEvent: string;
  public signUpError: string = "User already exists";
  public lowercaseRegex: RegExp = /[a-z]/;
  public uppercaseRegex: RegExp = /[A-Z]/;
  public numberRegex: RegExp = /\d/;
  public specialcharcterRegex: RegExp = /\W/;
  public isShowPassword: boolean = false;
  public keyType: string = "password";
  private isTeamUser: boolean = false;
  public userFirstName: string = "";
  public userLastName: string = "";

  constructor(private userService: UserService, private router: Router, private activatedRoute: ActivatedRoute, private teamService: TeamService, @Inject(PLATFORM_ID) private platformId: Object) {

  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(
      (params: Params) => {

        this.inviteCode = "eva40";
        this.orderHash = params["hs"];
        this.userName = params["na"];
        this.userEmail = params["em"];
        this.cEvent = params["p"];

        if (params["tid"]) {
          localStorage.setItem("teamHash", params["tid"]);
        }

        if (params["client"] && params["client"] == "svb") {
          localStorage.setItem("svbPlan", JSON.stringify({ plan: params["plan"], price: params["price"], planType:params["planType"] }))
        }

        if (params["cu"]) {
          this.userService.getUserByHashId(params["cu"]).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
            (user: User) => {
              this.userEmail = user.Email;
              this.isTeamUser = true;
            }
          )
        }

        if (params["type"] && params["type"] + "".toLowerCase() == "dm") {
          localStorage.setItem("dmPlan", "dm");
        }

        if (params["redirect"]) {
          localStorage.setItem("redirect", params["redirect"]);
        }

        if (params["redirect2"]) {
          localStorage.setItem("redirect2", params["redirect2"]);
        }

        localStorage.setItem("ob", "/ob");

        if (isPlatformBrowser(this.platformId)) {
          localStorage.setItem("ic", params["ic"]);
          localStorage.setItem("hs", params["hs"]);
          localStorage.setItem("cEvent", params["p"]);
        }
      });

    Helper.setScreenHeight();
  }



  onSignUp(signUpForm: NgForm) {
    this.isSubmitted = true;
    this.isInvalid = false;
    if (signUpForm.valid) {
      let rEmail: string = signUpForm.value.email;

      if (!this.isTeamUser && (rEmail.indexOf("@amerifirst.com") != -1 || rEmail.indexOf("@ameritrusthomemortgage.com") != -1 || rEmail.indexOf("@ameritrusthomeloans.com") != -1 || rEmail.indexOf("@ameriuno.com") != -1)) {
        this.userService.sendAmerifirstSignUpAlert({ form: signUpForm.value }).subscribe();
        Swal.fire({
          icon: 'error',
          title: '',
          text: 'Kindly reach out to Amerifirst Marketing Team (marketing@amerifirst.com) to create an account with Evabot.',
          confirmButtonColor: AppConstants.CONFIRM_BUTTON_COLOR
        })
        return;
      }

      let obj = {
        name: signUpForm.value.firstname + " " + signUpForm.value.lastname,
        email: signUpForm.value.email,
        password: Md5.hashStr(signUpForm.value.password),
        company: signUpForm.value.company,
        industry: signUpForm.value.industry,
        inviteCode: this.inviteCode,
        orderHash: this.orderHash
      }


      if (localStorage.getItem("teamHash")) {
        const TeamObj = { teamHash: localStorage.getItem("teamHash"), emailId: signUpForm.value.email }
        this.teamService.inviteUserByTeamCode(TeamObj).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
          (data) => {
            if (data.Message == 1 || data.Message == 2)
              this.onRegisterUser(obj);
            else if (data.Message == 0) {
              this.signUpError = "You are already added to some another team. Please contact us at care@evabot.ai."
              this.isSubmitted = false;
              this.isInvalid = true;
            }
            else if (data.Message == 3) {
              this.signUpError = "You are already added to " + data.TeamName + " Team."
              this.isSubmitted = false;
              this.isInvalid = true;
            }
            else {
              this.signUpError = "You are already on another plan and hence cannot be added to a team automatically."
              this.isSubmitted = false;
              this.isInvalid = true;
            }
          }
        )
      }
      else {
        this.onRegisterUser(obj);
      }

    }
  }

  onRegisterUser(obj: any) {
    this.userService.registerUser(obj).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data) => {
        if (data.Message) {
          this.isSubmitted = false;
          this.isInvalid = true;
        }
        else {
          if (isPlatformBrowser(this.platformId)) {
            localStorage.removeItem("hs");
          }
          localStorage.removeItem("ObEvaCases");
          this.userService.populate();
          this.userService.currentUser.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
            (user: User) => {
              if (user.TokenNumber) {

                if (Helper.IsGuildUser(user.Email)) {
                  this.router.navigate(["/guild", "plan"]);
                }

                if (Helper.IsSVBUser(user.Email)) {

                  this.router.navigate(["/svb", "plan"]);
                }

                else if (localStorage.getItem("dmPlan")) {
                  this.userService.updateOnBoardingStatus(false);
                  this.router.navigate(["/directMailerPlan"]);
                }
                else if (localStorage.getItem("redirect")) {

                  let redirectionUrl = localStorage.getItem("redirect").split("/");
                  if (redirectionUrl.indexOf("employeegifts") == -1)
                    this.userService.updateOnBoardingStatus(false);
                  redirectionUrl[0] = "/" + redirectionUrl[0];
                  this.router.navigate(redirectionUrl);
                }
                else if (localStorage.getItem("isd")) {
                  localStorage.removeItem("isd");
                  let route = localStorage.getItem("navigationUrl").split("||");
                  let params = localStorage.getItem("queryParams");
                  let queryParams = JSON.parse('{"' + decodeURI(params).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}'
                  )
                  this.router.navigate(route, { queryParams: queryParams });
                }
                else {
                  let obPrefix = localStorage.getItem("ob");
                  this.router.navigate([obPrefix, "step1"]);
                }
              }
            }
          )
        }
      },
    );
  }

  onShowPassword() {
    this.isShowPassword ? this.keyType = "password" : this.keyType = "text";
    this.isShowPassword = !this.isShowPassword;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
    localStorage.removeItem("teamHash");
    localStorage.removeItem("redirect");
  }
}
