/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./layout.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./header/header.component.ngfactory";
import * as i3 from "./header/header.component";
import * as i4 from "../core/services/menu.service";
import * as i5 from "../core/services/menu.setting.service";
import * as i6 from "../core/services/settings.service";
import * as i7 from "../core/services/user.service";
import * as i8 from "@angular/router";
import * as i9 from "./menu/menu.component.ngfactory";
import * as i10 from "./menu/menu.component";
import * as i11 from "@angular/common";
import * as i12 from "../core/services/api.service";
import * as i13 from "../core/services/stonley.service";
import * as i14 from "./layout.component";
import * as i15 from "../core/services/price.service";
import * as i16 from "../core/services/card.service";
import * as i17 from "../core/services/address.service";
import * as i18 from "../core/services/email.template.service";
var styles_LayoutComponent = [i0.styles];
var RenderType_LayoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LayoutComponent, data: {} });
export { RenderType_LayoutComponent as RenderType_LayoutComponent };
function View_LayoutComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(1, 245760, null, 0, i3.HeaderComponent, [i4.MenuService, i5.MenuSettingService, i6.SettingService, i7.UserService, i8.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_LayoutComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(1, 245760, null, 0, i3.HeaderComponent, [i4.MenuService, i5.MenuSettingService, i6.SettingService, i7.UserService, i8.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_LayoutComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-menu", [], null, null, null, i9.View_MenuComponent_0, i9.RenderType_MenuComponent)), i1.ɵdid(1, 245760, null, 0, i10.MenuComponent, [i4.MenuService, i7.UserService, i5.MenuSettingService, i1.Injector], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_LayoutComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LayoutComponent_1)), i1.ɵdid(2, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LayoutComponent_2)), i1.ɵdid(4, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LayoutComponent_3)), i1.ɵdid(6, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(9, 212992, null, 0, i8.RouterOutlet, [i8.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((!_co.isAuthenticated && (_co.currentPath.indexOf("giftPlatform") == (0 - 1))) && (_co.currentPath.indexOf("giftPlatform") == (0 - 1))); _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_co.isAuthenticated && (_co.currentPath.indexOf("giftPlatform") == (0 - 1))) && (_co.currentPath.indexOf("giftPlatform") == (0 - 1))); _ck(_v, 4, 0, currVal_1); var currVal_2 = (((_co.isAuthenticated && (_co.isSetUp == 1)) && (_co.currentPath.indexOf("giftPlatform") == (0 - 1))) && (_co.currentPath.indexOf("giftPlatform") == (0 - 1))); _ck(_v, 6, 0, currVal_2); _ck(_v, 9, 0); }, null); }
export function View_LayoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-layout", [], null, null, null, View_LayoutComponent_0, RenderType_LayoutComponent)), i1.ɵprd(512, null, i6.SettingService, i6.SettingService, [i12.ApiService, i13.StonleyService]), i1.ɵdid(2, 245760, null, 0, i14.LayoutComponent, [i7.UserService, i15.PriceService, i16.CardService, i17.AddressService, i18.EmailTemplateService, i6.SettingService, i8.Router, i4.MenuService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var LayoutComponentNgFactory = i1.ɵccf("app-layout", i14.LayoutComponent, View_LayoutComponent_Host_0, {}, {}, []);
export { LayoutComponentNgFactory as LayoutComponentNgFactory };
