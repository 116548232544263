import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgForm } from '@angular/forms';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';

import { Helper } from '../../../shared/helper';
import { User } from '../../../core/models/user.model';
import { TeamService } from '../../../core/services/team.service';
import { UserService } from '../../../core/services/user.service';
import { AppConstants } from '../../../shared/app.constant';
import { PriceService } from '../../../core/services/price.service';
import { Price } from '../../../core/models/price.model';


@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.css'],
  providers: [TeamService]
})
export class RedirectComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  public isSubmitted: boolean = false;
  public userEmail: string;
  public isLoading: boolean = true;
  public isSaveDetails: boolean = false;
  public currentUser: User;
  public data: any
  private isDemo: number;
  public inviteCode: string;

  constructor(private activatedRoute: ActivatedRoute, private userService: UserService, private router: Router, private teamService: TeamService, private toastr: ToastrService, private priceService: PriceService) {
    this.activatedRoute.queryParams.subscribe(
      (params: Params) => {
        let obj = { gc: params["code"], isDemo: params["isDemo"] ? 1 : 0, inviteCode: localStorage.getItem("ic"), orderHash: localStorage.getItem("hs"), isCreate: localStorage.getItem("hpjgoogle") ? 1 : 0 }
        this.inviteCode = localStorage.getItem("ic");
        this.userService.createGoogleAccount(obj).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
          (currentUser: User) => {
            localStorage.removeItem("ObEvaCases");
            this.currentUser = currentUser;
            //localStorage.removeItem("ic");
            localStorage.removeItem("hs");
            this.userEmail = currentUser.Email;

            let cEvent = localStorage.getItem("cEvent");
            if (cEvent) {
              var ts = new Date().getTime() / 1000;
              var dataItems = [];
              dataItems.push({
                "identity": this.currentUser.Email,
                "ts": ts,
                "type": "event",
                "evtName": cEvent,
              });
              localStorage.removeItem("cEvent");
            }

            this.isDemo = params["isDemo"] ? 1 : 0;

            localStorage.setItem("ob", "ob");


            if (localStorage.getItem("teamHash")) {
              const teamobj = { teamHash: localStorage.getItem("teamHash"), emailId: this.currentUser.Email }
              this.teamService.inviteUserByTeamCode(teamobj).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
                (data) => {
                  let signUpError = "";
                  if (data.Message == 0) {
                    signUpError = "You are already added to some another team. Please contact us at care@evabot.ai."
                  }
                  else if (data.Message == 3) {
                    signUpError = "You are already added to " + data.TeamName + " Team."
                  }
                  else if (data.Message == 4) {
                    signUpError = "You are already on another plan and hence cannot be added to a team automatically."
                  }

                  if (signUpError != "") {
                    this.toastr.error(signUpError);
                  }
                }
              )
            }


            if (localStorage.getItem("hpjgoogle")) {
              this.userService.updatehomeActivity({ value: 1, activity: AppConstants.UGL_HOLIDAY_TAG }).subscribe(
                (data) => {
                  this.priceService.fetchPriceList();
                }
              );

              this.priceService.priceList.subscribe(
                (priceList: Price[]) => {
                  if (priceList.length > 0) {
                    var holidayPlanObj = localStorage.getItem("holidayPlanObj");
                    if (holidayPlanObj) {
                      let obj = JSON.parse(holidayPlanObj);
                      let selectedPriceId = priceList.find(p => p.PriceId == obj.pId || p.ChargedAmount == obj.pId).PriceId;
                      this.userService.updateOnBoardingUseCases({ who: obj.gift, what: selectedPriceId }).subscribe(
                        (data) => {

                          localStorage.removeItem("holidayPlanObj")
                        }
                      );
                    }
                  }
                }
              )

              this.currentUser.HomeActivity = AppConstants.UGL_HOLIDAY_TAG;
            }

            if (currentUser.Name != "") {
              var route = [];

              if (Helper.IsGuildUser(currentUser.Email)) {
                this.router.navigate(["/guild", "plan"]);
              }

              else if (Helper.IsSVBUser(currentUser.Email)) {
                this.router.navigate(["/svb", "plan"]);
              }

              else if (currentUser.HomeActivity.indexOf("remoteuser") != -1 && currentUser.PlanName == "free") {
                route = ['/employeegifts', 'plan'];
              }
              else if (currentUser.IsSetUp == 0 && currentUser.HomeActivity.includes(AppConstants.UGL_HOLIDAY_TAG) && currentUser.HomeActivity.includes(AppConstants.UGL_HOLIDAY_CREATED)) {
                route = ["obv2", "step3"];
              }

              else if (this.currentUser.IsSetUp == 0 && this.currentUser.HomeActivity.includes(AppConstants.UGL_HOLIDAY_TAG) && !localStorage.getItem("isSendAllHolidayGifts")) {
                this.router.navigate(['obv2', "firstgift"]);
              }

              else if (this.currentUser.IsSetUp == 0 && this.currentUser.HomeActivity.includes(AppConstants.UGL_HOLIDAY_TAG) && (this.currentUser.CustomerId != '' || localStorage.getItem("isSendAllHolidayGifts"))) {
                this.router.navigate(['obv2', "step2"]);
              }

              else if (currentUser.IsSetUp == 0) {
                route = [localStorage.getItem("ob"), "step1"];
              }
              else {
                if (localStorage.getItem("navigationUrl"))
                  route = localStorage.getItem("navigationUrl").split("||");
                else
                  route = ["/"]
              }

              localStorage.removeItem("navigationUrl");
              if (localStorage.getItem("queryParams")) {
                let params = localStorage.getItem("queryParams");
                localStorage.removeItem("queryParams");
                let queryParams = JSON.parse('{"' + decodeURI(params).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');
                this.router.navigate(route, { queryParams: queryParams });
              }
              else {
                this.router.navigate(route);
              }

            }
            else {
              this.isSaveDetails = true;
              this.isLoading = false;
            }
          }
        )
      }
    )
  }

  ngOnInit() {

  }

  onUpdateDetails(f: NgForm) {
    this.isSubmitted = true;
    console.log(f.valid)
    if (f.valid) {
      const obj = { name: f.value.fullname, company: f.value.company, industry: f.value.industry }
      this.userService.saveUserDetails(obj).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
        (data) => {
          
          if (localStorage.getItem("dmPlan")) {
            this.userService.updateOnBoardingStatus(false);
            this.router.navigate(["/buyDirectMailers"]);
          }
          else if (localStorage.getItem("redirect")) {
            if (this.currentUser.HomeActivity.indexOf("remoteuser") == -1)
              this.userService.updateOnBoardingStatus(false);
            let redirectionUrl = localStorage.getItem("redirect").split("/");
            redirectionUrl[0] = "/" + redirectionUrl[0];
            this.router.navigate(redirectionUrl);
          }
          else if (localStorage.getItem("isd")) {
            localStorage.removeItem("isd");
            let route = localStorage.getItem("navigationUrl").split("||");
            let params = localStorage.getItem("queryParams");
            let queryParams = JSON.parse('{"' + decodeURI(params).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}'
            )
            this.router.navigate(route, { queryParams: queryParams });
          }
          else if (this.currentUser.IsSetUp == 0 && this.currentUser.HomeActivity.includes(AppConstants.UGL_HOLIDAY_TAG) && this.currentUser.HomeActivity.includes(AppConstants.UGL_HOLIDAY_CREATED)) {
            this.router.navigate(['obv2', "step3"]);
          }
          else if (this.currentUser.IsSetUp == 0 && this.currentUser.HomeActivity.includes(AppConstants.UGL_HOLIDAY_TAG) && !localStorage.getItem("isSendAllHolidayGifts")) {
            this.router.navigate(['obv2', "firstgift"]);
          }

          else if (this.currentUser.IsSetUp == 0 && this.currentUser.HomeActivity.includes(AppConstants.UGL_HOLIDAY_TAG) && (this.currentUser.CustomerId != '' || localStorage.getItem("isSendAllHolidayGifts"))) {
            this.router.navigate(['obv2', "step2"]);
          }

          else {
            if (this.currentUser.IsSetUp == 0) {
              this.router.navigate([localStorage.getItem("ob"), "step1"]);
            }
            else {
              this.router.navigate(["/"]);
            }
          }
        }
      )
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
    localStorage.removeItem("teamHash");
    localStorage.removeItem("redirect");
  }

}
