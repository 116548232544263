import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Md5 } from 'ts-md5';
import { UserService } from '../../../core/services/user.service';


@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.css']
})
export class RecoverPasswordComponent implements OnInit {
  public isSubmitted: boolean = false;
  private hashId: string = "";
  public isReset: boolean = false;
  public isLinkExpired: boolean = false;
  public lowercaseRegex: RegExp = /[a-z]/;
  public uppercaseRegex: RegExp = /[A-Z]/;
  public numberRegex: RegExp = /\d/;
  public specialcharcterRegex: RegExp = /\W/;
  private isShowPassword: boolean = false;
  public keyType: string = "password";
  constructor(private activatedRoute: ActivatedRoute, private userService: UserService) {
    activatedRoute.queryParams.subscribe(
      (params: Params) => {
        this.hashId = params["hashId"];
        this.userService.validateForgotPasswordPage({ hashId: this.hashId }).subscribe(
          (data) => {
            this.isLinkExpired = data.isActive == 0;
          }
        )
      }
    )
  }

  ngOnInit() {
  }

  onChangePassword(f: NgForm) {
    this.isSubmitted = true;
    if (f.valid && f.value.password == f.value.cpassword) {
      let obj = { password: Md5.hashStr(f.value.password), hashId: this.hashId };
      this.userService.recoverPassword(obj).subscribe(
        (data) => {
          this.isReset = true;
        }
      )
    }
  }

  onShowPassword() {
    this.isShowPassword ? this.keyType = "password" : this.keyType = "text";
    this.isShowPassword = !this.isShowPassword;
  }


}
