import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from './api.service';

@Injectable()
export class SubscriptionService {
    constructor(private apiService: ApiService) {

    }
    getPlans() {
        return this.apiService.post("subscription/getPlans", {}).pipe(map(
            (plans: any) => {
                const planData = plans.data;
                return planData;
            }
        ))
    }

    getPlanPrices(obj: any) {
        return this.apiService.post("subscription/getPlanPrices", obj).pipe(map(
            (planPrices: any) => {
                const planPricesData = planPrices.data;
                return planPricesData;
            }
        ))
    }

    buyBasicPlan(obj: any): Observable<any> {
        return this.apiService.post("subscription/buyBasicPlan", obj);
    }

    renewBasicPlan(): Observable<any> {
        return this.apiService.post("subscription/renewBasicPlan", {});
    }

    buyPremiumPlan(obj: any): Observable<any> {
        return this.apiService.post("subscription/buyPremiumPlan", obj);
    }


    sendEnterpriseQuery(obj: any): Observable<any> {
        return this.apiService.post("subscription/sendEnterpriseQuery", obj);
    }

    updateBasicPlanConfiguration(obj: any): Observable<any> {
        return this.apiService.post("subscription/updateBasicPlanConfiguration", obj);
    }
   
    upgradePremiumPlan(obj: any): Observable<any> {
        return this.apiService.post("subscription/upgradePremiumPlan", obj);
    }


    downgradeToBasicPlan(obj: any): Observable<any> {
        return this.apiService.post("subscription/downgradeToBasicPlan", obj);
    }

    deleteAccount(obj: any): Observable<any> {
        return this.apiService.post("subscription/deleteAccount", obj);
    }

    changeCard(obj: any): Observable<any> {
        return this.apiService.post("subscription/changeCard", obj);
    }

    updateCard(obj: any): Observable<any> {
        return this.apiService.post("subscription/updateCard", obj);
    }

    restartSubscription(): Observable<any> {
        return this.apiService.post("subscription/restartSubscription", {});
    }

    pauseSubscription(obj: any): Observable<any> {
        return this.apiService.post("subscription/pauseSubscription", obj);
    }

    resumePausedSubscription(obj: any): Observable<any> {
        return this.apiService.post("subscription/resumePausedSubscription", obj);
    }

    buyDirectMailerPlan(obj: any): Observable<any> {
        return this.apiService.post("subscription/buyDirectMailerPlan", obj);
    }

    buyEnterprisePlan(obj: any): Observable<any> {
        return this.apiService.post("subscription/buyEnterprisePlan", obj);
    }

    upgradeToEnterprisePlan(obj: any): Observable<any> {
        return this.apiService.post("subscription/upgradeToEnterprisePlan", obj);
    }

    buy99Enterprise(obj: any): Observable<any> {
        return this.apiService.post("subscription/buy99Enterprise", obj);
    }

    buy849Enterprise(obj: any): Observable<any> {
        return this.apiService.post("subscription/buy849Enterprise", obj);
    }

    buy449Enterprise(obj: any): Observable<any> {
        return this.apiService.post("subscription/buy449Enterprise", obj);
    }

    buy499premium10b10p10dm(obj: any): Observable<any> {
        return this.apiService.post("subscription/buy499premium10b10p10dm", obj);
    }

    buyHoliday399Plan(obj: any): Observable<any> {
        return this.apiService.post("subscription/buyHoliday399Plan", obj);
    }

    buyHoliday149Plan(obj: any): Observable<any> {
        return this.apiService.post("subscription/buyHoliday149Plan", obj);
    }

    buyPremiumPlanOffer(obj: any): Observable<any> {
        return this.apiService.post("subscription/buyPremiumPlanOffer", obj);
    }

    buyEnterprisePlanOffer(obj: any): Observable<any> {
        return this.apiService.post("subscription/buyEnterprisePlanOffer", obj);
    }

    buyPlanV2(obj: any): Observable<any> {
        return this.apiService.post("subscription/buyPlanV2", obj);
    }

    turnOffAutoRechargeBasicPlan() {
        return this.apiService.post("subscription/turnOffAutoRechargeBasicPlan", {});
    }

    updateGuildZeroPlan(obj) {
        return this.apiService.post("subscription/updateGuildZeroPlanV2", obj);
    }



}
