import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../core/services/user.service';
import { Router } from '@angular/router';
import { MenuService } from '../../../core/services/menu.service';
import { User } from '../../../core/models/user.model';

@Component({
  selector: 'app-signout',
  templateUrl: './signout.component.html',
  styleUrls: ['./signout.component.css']
})
export class SignoutComponent implements OnInit {

  private currentUser:User
  constructor(private userService: UserService, private router: Router, private menuService: MenuService) { }

  ngOnInit() {
    this.userService.purgeAuth();
    this.menuService.menuItems = [];
    this.router.navigate(["/login"]);

  }

}
