import { Component, OnInit, OnDestroy, Inject, PLATFORM_ID, ViewChild, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { isPlatformBrowser, LocationStrategy } from '@angular/common';

import { Md5 } from 'ts-md5';

import { Subscription } from 'rxjs';

import { AppConstants } from '../../../shared/app.constant';
import { Helper } from '../../../shared/helper';
import { User } from '../../../core/models/user.model';
import { TeamService } from '../../../core/services/team.service';
import { UserService } from '../../../core/services/user.service';
import { PriceService } from '../../../core/services/price.service';
import { Price } from '../../../core/models/price.model';
import { title } from 'process';



declare var require: any
const Swal = require("sweetalert2");

@Component({
  selector: 'app-signup-holiday',
  templateUrl: './signup-holiday.component.html',
  styleUrls: ['./signup-holiday.component.css'],
  providers: [TeamService]
})

export class SignupHolidayComponent implements OnInit, OnDestroy {
  public isSubmitted: boolean = false;
  public isInvalid: boolean = false;
  public inviteCode: string;
  private orderHash: string;
  public userName: string;
  public userEmail: string;
  public cEvent: string;
  public signUpError: string = "User already exists";
  public lowercaseRegex: RegExp = /[a-z]/;
  public uppercaseRegex: RegExp = /[A-Z]/;
  public numberRegex: RegExp = /\d/;
  public specialcharcterRegex: RegExp = /\W/;
  public isShowPassword: boolean = false;
  public keyType: string = "password";
  private isTeamUser: boolean = false;
  public userFirstName: string = "";
  public passwordStrengthText: string = "Enter password";
  public pass: string = "";
  public isMobile = Helper.isMobile();
  public noOfGifts: string = "";
  public giftingBudget: string = "";
  public evaUsage: string = "";
  private subscriptionList = new Subscription();
  public EvaUsageFrequency:string="Repetitive";

  @ViewChild("pop4", { static: false }) passwordStrenthPopUp;


  constructor(private userService: UserService, private router: Router, private priceService: PriceService, @Inject(PLATFORM_ID) private platformId: Object, private location: LocationStrategy) {
    // if (!localStorage.getItem("isCustomizationDone")) {
    //   this.router.navigate(['obv2', 'step1']);
    // }
    //localStorage.removeItem("isCustomizationDone");
    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });

  
  }

  ngOnInit() {
    localStorage.setItem("obv2", "/obv2");
    localStorage.setItem("hpjgoogle", "1");
 

  }


  setPopover() {
    this.passwordStrenthPopUp.show();
  }



  onSignUp(signUpForm: NgForm) {
    this.isSubmitted = true;
    this.isInvalid = false;
    // if (signUpForm.controls.password.status == "INVALID")
    //   this.passwordStrenthPopUp.show();

    if (signUpForm.valid) {
      let rEmail: string = signUpForm.value.email;

      if (!this.isTeamUser && (rEmail.indexOf("@amerifirst.com") != -1 || rEmail.indexOf("@ameritrusthomemortgage.com") != -1 || rEmail.indexOf("@ameritrusthomeloans.com") != -1 || rEmail.indexOf("@ameriuno.com") != -1)) {
        this.userService.sendAmerifirstSignUpAlert({ form: signUpForm.value }).subscribe();
        Swal.fire({
          icon: 'error',
          title: '',
          text: 'Kindly reach out to Amerifirst Marketing Team (marketing@amerifirst.com) to create an account with Evabot.',
          confirmButtonColor: AppConstants.CONFIRM_BUTTON_COLOR
        })
        return;
      }

      let tempPassword = this.generatePassword();
      let obj = {
        name: signUpForm.value.firstname,
        email: signUpForm.value.email,
        password: Md5.hashStr(tempPassword),
        company: signUpForm.value.company,
        industry: signUpForm.value.industry,
        inviteCode: this.inviteCode,
        noOfGifts: signUpForm.value.noOfGifts,
        orderHash: this.orderHash,
        hashStr: tempPassword
      }
      this.onRegisterUser(obj);
    }
  }

  onRegisterUser(obj: any) {

    this.subscriptionList.add(this.userService.registerUser(obj).subscribe(
      (data) => {
        if (data.Message) {
          this.isSubmitted = false;
          this.isInvalid = true;
        }
        else {
          if (isPlatformBrowser(this.platformId)) {
            localStorage.removeItem("hs");
          }
          localStorage.removeItem("ObEvaCases");
          this.userService.populate();

          this.userService.updatehomeActivity({ activity: AppConstants.UGL_HOLIDAY_TAG, value: 1 }).subscribe();
          this.userService.updateOnBoardingUseCases({ who: this.EvaUsageFrequency, what: this.giftingBudget, how:this.evaUsage }).subscribe(
            (data) => {
              if (!Helper.IsGuildUser(obj.email)) {
                // if (localStorage.getItem("isSendAllHolidayGifts"))
                //   this.router.navigate([obPrefix, "step2"]);
                // else

                Swal.fire({
                  title: '<br><p><img src="../../../../assets/img/success-tick.svg"></p><br><p style="font-size:28px;color:#24B300">Your account is created and the<br>password is shared with you over email</p>',
                  confirmButtonColor:AppConstants.CONFIRM_BUTTON_COLOR
                }
              )
              setTimeout(() => {
                $("#swal2-title").css("display", "block");
              });

                this.router.navigate(["obv2", "firstgift"]);
              }
            });
        }
      },
    ));
  }

  onShowPassword() {
    this.isShowPassword ? this.keyType = "password" : this.keyType = "text";
    this.isShowPassword = !this.isShowPassword;
  }

  setPasswordStrengthText(text: string) {
    this.passwordStrengthText = text;
  }

  @HostListener('document:click', ['$event'])
  clickout(event: Event) {
    let target = event.target;
    if ($(target).hasClass("pass-strength")) {
      this.passwordStrenthPopUp.show();
    }
    else {
      this.passwordStrenthPopUp.hide();
    }
  }


  redirect() {
    this.router.navigate(['signup']);
  }


  generatePassword() {
    var chars = "0123456789abcdefghijklmnopqrstuvwxyz!@#$()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var passwordLength = 12;
    var password = "";
    for (var i = 0; i <= passwordLength; i++) {
      var randomNumber = Math.floor(Math.random() * chars.length);
      password += chars.substring(randomNumber, randomNumber + 1);
    }
    return password;
  }




  ngOnDestroy() {
    this.subscriptionList.unsubscribe();
    localStorage.removeItem("teamHash");
    localStorage.removeItem("redirect");
  }
}
