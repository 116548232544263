import { Component, OnInit, OnDestroy, Inject, PLATFORM_ID } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { NgForm } from '@angular/forms';
import { isPlatformBrowser } from '@angular/common';

import { Md5 } from 'ts-md5';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Helper } from '../../../shared/helper';
import { User } from '../../../core/models/user.model';
import { UserService } from '../../../core/services/user.service';

@Component({
  selector: 'app-sso',
  templateUrl: './sso.component.html',
  styleUrls: ['./sso.component.css']
})

export class SsoComponent implements OnInit, OnDestroy {
  public isSubmitted: boolean = false;
  public isInvalid: boolean = false;
  private ngUnsubscribe = new Subject();
  public inviteCode: string;
  private orderHash: string;
  public userName: string;
  public userEmail: string;
  public cEvent: string;
  public signUpError: string = "User already exists";
  public lowercaseRegex: RegExp = /[a-z]/;
  public uppercaseRegex: RegExp = /[A-Z]/;
  public numberRegex: RegExp = /\d/;
  public min8Regex: RegExp = /.{8,}/;
  public specialcharcterRegex: RegExp = /\W/;
  public isShowPassword: boolean = false;
  public keyType: string = "password";
  public userDomain:string="";

  constructor(private userService: UserService, private router: Router, private activatedRoute: ActivatedRoute, @Inject(PLATFORM_ID) private platformId: Object) { }

  ngOnInit() {

  }

  onValidateDomain(f: NgForm) {
    this.isSubmitted = true;
    this.isInvalid = false;
    const obj = { domain: f.value.domain }
    this.userService.validateDomain(obj).subscribe(
      (data) => {
        if(data.token!=""){
           window.location.href="https://sso.evabot.ai/login";
        }
        else
        {
          this.isInvalid = true;
          this.signUpError = "This domain is not registered for SSO Sign-in";
        }
      }
    )
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
    localStorage.removeItem("teamHash");
    localStorage.removeItem("redirect");
  }
}

