import { FeatureConstant } from "../../shared/feature.constant";

export class Subscription {
    private PlanName: string;
    private PlanType: string;
    private PlanFeatures: string;

    constructor(planName: string, planType: string, planFeatures: string) {
        this.PlanName = planName;
        this.PlanType = planType;
        this.PlanFeatures = planFeatures;
    }

    get IsSendGift() {
        return this.PlanFeatures.indexOf(FeatureConstant.SendGift) != -1;
    }

    get IsBranding() {
        return this.PlanFeatures.indexOf(FeatureConstant.Branding) != -1;
    }

    get IsDirectMailers() {
        return this.PlanFeatures.indexOf(FeatureConstant.DirectMailer) != -1;
    }

    get IsMerchandise() {
        return this.PlanFeatures.indexOf(FeatureConstant.Merchandise) != -1;
    }

    get IsHandwritten() {
        return this.PlanFeatures.indexOf(FeatureConstant.Handwritten) != -1;
    }

    get IsCampaign() {
        return this.PlanFeatures.indexOf(FeatureConstant.Campaign) != -1;
    }

    get IsAutomatedBirthday() {
        return this.PlanFeatures.indexOf(FeatureConstant.AutomatedBirthday) != -1;
    }

    get IsAutomatedHA() {
        return this.PlanFeatures.indexOf(FeatureConstant.AutomatedHomeAnniversary) != -1;
    }

    get IsAutomatedEmployee() {
        return this.PlanFeatures.indexOf(FeatureConstant.AutomatedEmployee) != -1;
    }

    get IsAutomatedClientRenewal() {
        return this.PlanFeatures.indexOf(FeatureConstant.AutomatedClientRenewal) != -1;
    }

    get IsTeamManagement() {
        return this.PlanFeatures.indexOf(FeatureConstant.TeamManagement) != -1 && this.PlanFeatures.indexOf(FeatureConstant.AdvancedTeamManagement)==-1;
    }

    get IsAdvancedTeamManagement() {
        return this.PlanFeatures.indexOf(FeatureConstant.AdvancedTeamManagement) != -1;
    }

    get IsCustomerData() {
        return this.PlanFeatures.indexOf(FeatureConstant.CustomerData) != -1;
    }

    get IsIntegrations() {
        return this.PlanFeatures.indexOf(FeatureConstant.Integration) != -1;
    }

    get IsReferrals() {
        return this.PlanFeatures.indexOf(FeatureConstant.Referrals) != -1;
    }

    get IsGiftSelection() {
        return this.PlanFeatures.indexOf(FeatureConstant.GiftSelection) != -1;
    }

    get IsTestimonial() {
        return this.PlanFeatures.indexOf(FeatureConstant.Testimonial) != -1;
    }

    get IsCustomTemplates() {
        return this.PlanFeatures.indexOf(FeatureConstant.CustomTemplate) != -1;
    }

    get IsAutoReview() {
        return this.PlanFeatures.indexOf(FeatureConstant.AutoReview) != -1;
    }

    get IsUtl() {
        return this.PlanFeatures.indexOf(FeatureConstant.Utl) != -1;
    }

    get IsValidationList() {
        return this.PlanFeatures.indexOf(FeatureConstant.validationList) != -1;
    }

    get IsClientDataAddress() {
        return this.PlanFeatures.indexOf(FeatureConstant.addressCustomerData) != -1;
    }

}

