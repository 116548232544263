/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./twitter-callback.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../core/services/social.service";
import * as i3 from "../../../core/services/api.service";
import * as i4 from "./twitter-callback.component";
import * as i5 from "@angular/router";
import * as i6 from "../../../core/services/user.service";
var styles_TwitterCallbackComponent = [i0.styles];
var RenderType_TwitterCallbackComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TwitterCallbackComponent, data: {} });
export { RenderType_TwitterCallbackComponent as RenderType_TwitterCallbackComponent };
export function View_TwitterCallbackComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Redirecting...\n"]))], null, null); }
export function View_TwitterCallbackComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-twitter-callback", [], null, null, null, View_TwitterCallbackComponent_0, RenderType_TwitterCallbackComponent)), i1.ɵprd(512, null, i2.UserSocialAccount, i2.UserSocialAccount, [i3.ApiService]), i1.ɵdid(2, 245760, null, 0, i4.TwitterCallbackComponent, [i5.ActivatedRoute, i6.UserService, i2.UserSocialAccount, i5.Router], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var TwitterCallbackComponentNgFactory = i1.ɵccf("app-twitter-callback", i4.TwitterCallbackComponent, View_TwitterCallbackComponent_Host_0, {}, {}, []);
export { TwitterCallbackComponentNgFactory as TwitterCallbackComponentNgFactory };
