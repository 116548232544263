<div class="outer-wrapper" style="background: white">
  <div class="fluid-container">
    <div class="border-wrapper">
      <div class="inner-wrapper">
        <div class="card-top-section">
          <div class="row">
            <div class="col-4 card-top-left">
              <div class="dot-full">
                <span class="inner-dot" id="step-count">1</span>
              </div>
              <span class="of-text-container">
                <span class="of-text">of</span>
              </span>
              <div class="dot-outer">
                <span class="inner-dot">4</span>
              </div>
            </div>
            <div class="col-4 card-top-center">
              <img
                src="../../../../../assets/img/evabot-logo-mobile.png"
                alt="Evabot logo"
                class="evabot-logo-img"
              />
            </div>
            <div class="col-4 card-top-right">
              <p class="skip-demo-text">
                <a
                  style="font-size: 18px"
                  id="skip-demo"
                  style="cursor: pointer"
                  (click)="onSkipDemo()"
                  >Skip Demo</a
                >
              </p>
            </div>
          </div>
        </div>
        <div id="chat-header" class="card-top-section" style="display: none">
          <div class="row" id="demo-header">
            <div class="col-4 card-top-left">
              <div class="dot-full">
                <span class="inner-dot" id="step-count">3</span>
              </div>
              <span class="of-text-container">
                <span class="of-text"> of </span>
              </span>
              <div class="dot-outer">
                <span class="inner-dot">5</span>
              </div>
            </div>
            <div class="col-4 card-top-center">&nbsp;</div>
            <div class="col-4 card-top-right">
              <p class="skip-demo-text">
                <a id="continue-demo" style="cursor: pointer">Skip Chat</a>
              </p>
            </div>
          </div>
        </div>
        <div class="steps-wrapper">
          <div class="row">
            <div class="col-12">
              <div id="step-1" class="step">
                <div class="text-container">
                  <h3 class="main-tag-line mt-3">
                    Sending handwritten notes with Eva is easy
                  </h3>
                  <div style="width: 100%; text-align: center">
                    <app-branding-handwritten-slider
                      [data]="totalSlides"
                      [slides]="images"
                    >
                    </app-branding-handwritten-slider>
                  </div>
                  <p
                    style="
                      font-size: 18px;
                      text-align: center;
                      color: #404040 !important;
                    "
                    class="mt-3"
                  >
                    Beautifully branded with custom notes and a personal feel.
                  </p>
                  <div class="mt-4" style="width: 100%; text-align: center">
                    <img
                      style="width: 98%"
                      src="../../../../../assets/img/demo/notes-cr.jpg"
                    />
                  </div>
                  <div class="button-section-container mt-3">
                    <button
                      (click)="onStartDemo()"
                      type="button"
                      class="btn evabot-button-main"
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
              <div id="step-2" class="step">
                <div id="step-2-inner">
                  <div class="text-container">
                    <h3 class="main-tag-line mt-3">
                      Submit your logo and brand colors
                    </h3>
                  </div>
                </div>
                <div style="text-align: center" class="demo-video-container">
                  <img
                    src="../../../../../assets/img/demo/Eva-HW-gif.gif"
                    alt="evabot demo"
                    class="demo-gif"
                  />
                </div>
                <p
                  style="
                    font-size: 18px;
                    text-align: center;
                    color: #404040 !important;
                    opacity: 0.7;
                  "
                  class="mt-3"
                >
                  And our team will email mockups for approval
                </p>
                <div style="width: 100%; text-align: center">
                  <img
                    style="width: 150px"
                    src="../../../../assets/img/branding/card-1.png"
                  />
                  <img
                    style="width: 150px"
                    src="../../../../assets/img/branding/card-5.png"
                  />
                </div>
                <div class="button-section-container mt-2">
                  <button
                    (click)="onNextStep(4, 3)"
                    type="button"
                    class="btn evabot-button-main"
                  >
                    Next
                  </button>
                </div>
                <div class="text-center m-2">
                  <a
                    class="cursor-pointer skip-link"
                    (click)="onNextStep(1, 1)"
                    style="text-decoration: underline"
                    ><span class="fa fa-arrow-left"></span>&nbsp;back</a
                  >
                </div>
                <div class="button-section-container mt-2">&nbsp;</div>
              </div>
              <div id="step-4" class="step">
                <div class="text-container">
                  <h3
                    class="main-tag-line mt-3"
                    style="font-size: 1.6em; opacity: 0.8; font-weight: 500"
                  >
                    Easily import your CSV of clients and write your
                    personalized notes
                  </h3>
                </div>
                <div class="large-image-container">
                  <img
                    style="
                      max-width: 80% !important;
                      border: 1px solid lightgrey;
                      box-shadow: 0px 0px 3px 1px lightgrey;
                    "
                    src="../../../../../assets/img/branding/demo-mailer.jpg"
                    alt="Evabot gift package"
                    class="large-gift-image"
                  />
                </div>
                <p
                  style="
                    font-size: 20px;
                    text-align: center;
                    color: #404040;
                    opacity: 0.7;
                  "
                  class="mt-5"
                >
                  Our state-of-the-art Roboarm will write each of your notes
                  with a real pen.
                </p>
                <div class="button-section-container mt-5">
                  <button
                    (click)="onNextStep(5, 4)"
                    type="button"
                    class="btn evabot-button-main"
                  >
                    Next
                  </button>
                </div>
                <div class="text-center m-2">
                  <a
                    class="cursor-pointer skip-link"
                    (click)="onNextStep(2, 2)"
                    style="text-decoration: underline"
                    ><span class="fa fa-arrow-left"></span>&nbsp;back</a
                  >
                </div>
                <div class="p-5"></div>
                <div class="p-5"></div>
              </div>
              <div id="step-5" class="step">
                <div class="text-container">
                  <p style="font-size: 20px; color: #000">
                    Ready to get Started?
                  </p>
                  <p style="font-size: 16px">
                    Sign up below to send your first batch
                  </p>
                  <img
                    src="../../../../../assets/img/demo/step4-finisher.png"
                    alt="Eva bot gift demo finishing image"
                    class="step-img"
                  />
                </div>
                <form (ngSubmit)="onSignUp(sForm)" #sForm="ngForm">
                  <div class="form-section-container">
                    <div class="form-field-wrapper">
                      <input
                        [(ngModel)]="userName"
                        type="text"
                        placeholder="Name"
                        name="name"
                        ngModel
                        autocomplete="off"
                        #SIname="ngModel"
                        [ngClass]="{
                          'input-error': isSubmittedSignUp && !SIname.valid
                        }"
                        required
                      />
                      <div
                        style="color: red"
                        *ngIf="isSubmittedSignUp && !SIname.valid"
                      >
                        Name can not be blank
                      </div>
                    </div>
                    <div class="form-field-wrapper">
                      <input
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,64}$"
                        [(ngModel)]="userEmail"
                        type="email"
                        placeholder="Email"
                        name="email"
                        ngModel
                        oninput="this.value = this.value.toLowerCase()"
                        autocomplete="off"
                        #SIemail="ngModel"
                        [ngClass]="{
                          'input-error': isSubmittedSignUp && !SIemail.valid
                        }"
                        required
                        email
                      />
                      <div
                        style="color: red"
                        *ngIf="isSubmittedSignUp && SIemail.value.length == 0"
                      >
                        Email can not be blank
                      </div>
                      <div
                        style="color: red"
                        *ngIf="
                          isSubmittedSignUp &&
                          !SIemail.valid &&
                          SIemail.value.length > 0
                        "
                      >
                        Invalid Email
                      </div>
                    </div>
                    <div class="form-field-wrapper">
                      <input
                        pattern=".{6,}"
                        type="password"
                        placeholder="Password"
                        ngModel
                        type="password"
                        placeholder="Password"
                        name="password"
                        autocomplete="new-password"
                        #password="ngModel"
                        [ngClass]="{
                          'input-error': isSubmittedSignUp && password.invalid
                        }"
                        required
                      />
                      <div
                        style="color: red"
                        *ngIf="isSubmittedSignUp && password.value.length == 0"
                      >
                        Password can not be blank
                      </div>
                      <div
                        style="color: red"
                        *ngIf="
                          isSubmittedSignUp &&
                          !password.valid &&
                          password.value.length > 0
                        "
                      >
                        Password should be atleast 6 characters long
                      </div>
                    </div>
                    <div class="form-field-wrapper">
                      <input
                        type="text"
                        placeholder="Company Name"
                        ngModel
                        type="text"
                        name="company"
                        #company="ngModel"
                      />
                    </div>
                  </div>
                  <div
                    *ngIf="isSignUpInvalid"
                    style="text-align: center; color: red"
                  >
                    Your account already exists.
                  </div>
                  <div class="outer-button-wrapper">
                    <div class="button-section-container">
                      <button
                        type="submit"
                        id="step-5-button"
                        class="btn evabot-button-main"
                      >
                        Sign Up and Send
                      </button>
                    </div>
                    <div class="or-text-section">
                      <p>Or</p>
                    </div>
                    <div class="button-section-container">
                      <a
                        href="https://client.stage.evabot.com/user/googleLogin"
                      >
                        <img
                          style="width: 180px; border-radius: 5px"
                          src="../../../../assets/img/google-login-normal.png"
                        />
                      </a>
                    </div>

                    <div class="mt-2 text-center">
                      <a
                        id="log-in"
                        style="
                          font-size: 16px;
                          cursor: pointer;
                          color: rgb(255, 73, 63);
                        "
                      >
                        Already have an account?
                        <span class="added-underline-style-login-text"
                          >Sign In</span
                        >
                      </a>
                    </div>
                  </div>
                </form>
              </div>

              <div id="step-5-1" class="step">
                <div class="text-container">
                  <p style="font-size: 20px; color: #000">Sign In</p>
                </div>
                <form (ngSubmit)="onSignIn(f)" #f="ngForm">
                  <div class="form-section-container">
                    <div class="form-field-wrapper">
                      <input
                        oninput="this.value = this.value.toLowerCase()"
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,64}$"
                        style="line-height: 20px !important"
                        name="email"
                        type="email"
                        placeholder="Email"
                        ngModel
                        autocomplete="off"
                        #uemail="ngModel"
                        [ngClass]="{
                          'input-error': isSubmittedSignIn && !uemail.valid
                        }"
                        required
                        email
                      />
                      <div
                        style="color: red"
                        *ngIf="isSubmittedSignIn && uemail.value.length == 0"
                      >
                        Email can not be blank
                      </div>
                      <div
                        style="color: red"
                        *ngIf="
                          isSubmittedSignIn &&
                          !uemail.valid &&
                          uemail.value.length > 0
                        "
                      >
                        Invalid Email
                      </div>
                    </div>
                    <div class="form-field-wrapper">
                      <input
                        ngModel
                        type="password"
                        placeholder="Password"
                        name="password"
                        #upassword="ngModel"
                        [ngClass]="{
                          'input-error': isSubmittedSignIn && upassword.invalid
                        }"
                        required
                        style="line-height: 20px !important"
                        name="password"
                        type="password"
                        required
                      />
                      <div
                        style="color: red"
                        *ngIf="isSubmittedSignIn && !upassword.valid"
                      >
                        Password can not be blank
                      </div>
                    </div>
                  </div>
                  <div
                    *ngIf="isSignInInvalid"
                    style="text-align: center; color: red"
                  >
                    Invalid Credentials
                  </div>
                  <div class="outer-button-wrapper">
                    <div class="button-section-container">
                      <button
                        type="submit"
                        id="step-5-1-button"
                        class="btn evabot-button-main"
                      >
                        Sign In
                      </button>
                    </div>

                    <div class="or-text-section">
                      <p style="margin-top: 12px">Or</p>
                    </div>
                    <div class="button-section-container">
                      <a
                        href="https://client.stage.evabot.com/user/googleLogin"
                      >
                        <img
                          style="width: 180px; border-radius: 5px"
                          src="../../../../assets/img/google-login-normal.png"
                        />
                      </a>
                    </div>
                    <div class="mt-5 text-center">
                      <a
                        id="sign-up"
                        style="
                          font-size: 16px;
                          cursor: pointer;
                          color: rgb(255, 73, 63);
                        "
                      >
                        Don't have account?
                        <span class="added-underline-style-login-text"
                          >Sign Up</span
                        ></a
                      >
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
